import React, { useEffect, useState } from "react";
import axios from 'axios';
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography, Grid } from '@mui/material';
import { BsArrowLeft } from "react-icons/bs";
import Form from "react-bootstrap/Form";
import { useNavigate } from "react-router-dom";
import { BASE_Url } from "../../URL.js/URL";
import DownloadComponent from "../CSVDownload/DownloadComponent";
const DailyStoreReports = ({formDate,toDate}) => {
  const [allData, setAllData] = useState([]);
  const navigate = useNavigate();
  // const [selectedOption, setSelectedOption] = useState(new Date().toISOString().split('T')[0]); 

  // const handleSelectChange = (event) => {
  //   setSelectedOption(event.target.value);
  // };
  const {storeId, saasId}= JSON.parse(localStorage.getItem('STORE_DATA'));
  useEffect(() => {
    axios.get(`${BASE_Url}/user-master/overall_performance/${saasId}/${storeId}/${formDate}/${toDate}`)
      .then((res) => {
        setAllData(res.data.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, [formDate,toDate]);

  const header=[
    { label: "Date", key: "date" },
    { label: "Store Id", key: "store_id" },
    { label: "Most Ordered Item", key: "most_ordered" },
    { label: "Total Sales", key: "net_profit" },
]

  return (
    <>
        <div  style={{ height: "100vh", overflowY: "auto"}}>
          <DownloadComponent headers={header} data={allData} fileName="DailySales_Report"/>
        <div className="flex flex-wrap"
  style={{ marginBottom: "40%" }}
    >
      <Box className="h-100 w-100 rounded-lg">
        <TableContainer component={Paper}>
          <Table className="text-nowrap" aria-label="simple table">
            <TableHead className="bg-dark-purple">
              <TableRow>
                <TableCell className=" text-white">Date</TableCell>
                <TableCell className=" text-white">Store Id</TableCell>
                <TableCell className=" text-white">Most Ordered Item</TableCell>
               <TableCell className=" text-white">Total Sales</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {allData?.map((ele) => (
                <TableRow key={ele.date}>
                  <TableCell>{ele.date}</TableCell>
                  <TableCell>{ele.store_id}</TableCell>
                  <TableCell>{ele.most_ordered}</TableCell>
                  <TableCell>{ele.net_profit}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      </div>   </div>
    </>
  );
}

export default DailyStoreReports;
