// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `  .wrapper{
      overflow-x: auto;
      display: flex;
      
} 
.wrapper::-webkit-scrollbar{
    width:0;
}

.css-i9fmh8-MuiBackdrop-root-MuiModal-backdrop{
    background:none!important ;
}
   
`, "",{"version":3,"sources":["webpack://./src/Components/Menu/Menu.css"],"names":[],"mappings":"EAAE;MACI,gBAAgB;MAChB,aAAa;;AAEnB;AACA;IACI,OAAO;AACX;;AAEA;IACI,0BAA0B;AAC9B","sourcesContent":["  .wrapper{\r\n      overflow-x: auto;\r\n      display: flex;\r\n      \r\n} \r\n.wrapper::-webkit-scrollbar{\r\n    width:0;\r\n}\r\n\r\n.css-i9fmh8-MuiBackdrop-root-MuiModal-backdrop{\r\n    background:none!important ;\r\n}\r\n   \r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
