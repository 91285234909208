import React, { useEffect, useState } from "react";
import axios from "axios";
import Addform from "../Popup/Addform";
import Addfooditem from "../Popup/Addfooditem";
import { AiOutlineClose, AiOutlinePlus } from "react-icons/ai";
import { Col, Row } from "react-bootstrap";
import { data } from "autoprefixer";
import { BASE_Url } from "../../URL.js/URL";
import "./Menu.css";
import Swal from "sweetalert2";
import Box from "@mui/material/Box";
import { Image } from 'antd'
import { RxCross2 } from "react-icons/rx";
import { MdDelete, MdEdit, MdPlaylistAdd } from "react-icons/md";
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import DataTable from "react-data-table-component";
import Itemupdate from "./Itemupdate";
import Switch from '@mui/material/Switch';
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import "swiper/css";
import 'swiper/css/scrollbar';
import { Button, Divider, Grid, IconButton, InputBase, Paper } from "@mui/material";
import Modal from 'react-bootstrap/Modal';
import { Container } from "reactstrap";
import DeleteCategory from "./DeleteCategory";
import SearchIcon from "@mui/icons-material/Search";
function Menu() {
  const label = { inputProps: { 'aria-label': 'Switch demo' } };
  const { storeId, saasId } = JSON.parse(localStorage.getItem("STORE_DATA"));

  const [selectedCategory, setSelectedCategory] = useState("");
  const [itemData, setItemData] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [subData, setSubData] = useState("");
  const [buttonPopup, setButtonPopup] = useState(false);
  const [category, setCategory] = useState("");
  const [popup, setPopup] = useState(false);
  const [addUpdateItemModalIsOpen, setAddUpdateItemModalIsOpen] =
    useState(false);
  const [RowData, setRowData] = useState()
  const [categoryId, setCategoryId] = useState("")
  const [image, setImage] = useState("")
  const addCategoryToMenu = (newCategory) => {
    // Add the new category to the itemData state
    setItemData([...itemData, newCategory]);
  };
  const addFoodItemToMenu = (newFoodItem) => {
    // Add the new food item to the selectedCategory state
    setSelectedCategory([...selectedCategory, newFoodItem]);
  };
  const getCatogery = () => {
    const { storeId, saasId } = JSON.parse(localStorage.getItem("STORE_DATA"));
    const apiUrl = `${BASE_Url}/item/category-list/${saasId}`;
    axios
      .get(apiUrl)
      .then((response) => {
        if (response.status === 200) {
          console.log("new Api", response);
          setItemData(response.data.data);
        } 
        if (response.data.data.length > 0) {
          const firstCategoryId = response.data.data[0].category_id;
          setCategoryId(firstCategoryId);
          setActiveCategoryId(firstCategoryId);
        }else {
          console.log("Failed to fetch data from the API.");
        }
      })
      .catch((error) => {
        console.error("An error occurred:", error);
      });
  };






  useEffect(() => {
    getCatogery();
  }, [category]);

  const getMenu = () => {
    //if cateortid not availble then return
    if (!categoryId) return;
    axios
      .get(`${BASE_Url}/item/view-menu-admin/${categoryId}/${saasId}`)
      .then((res) => {
        console.log("RES MENU", res);
        setSelectedCategory(res.data.data.item_list);
        
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (categoryId) {
      console.log("id found ", categoryId);
      getMenu();
    }
  }, [categoryId, addUpdateItemModalIsOpen]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const { storeId, saasId } = JSON.parse(localStorage.getItem("STORE_DATA"));
    axios
      .post(`${BASE_Url}/item/add-category`, {
        category_name: category,
        saas_Id: saasId,
        status: "enabled",
        store_id: storeId,
      })
      .then((res) => {
        if (res.status === 200) {
          console.log("res CATEGORY", res);
          if(image) {
            addCategoryImage(res.data.data.category_id);
          }
          setCategory("");
          setOpenModal(false);
          Swal.fire(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  
   //add Category Image by Category id to the API
   const addCategoryImage = async (categoryId) => {
    console.log("Image:", image);
    try {
      const formData = new FormData();
      formData.append("file", image);
      const response = await axios.post(
        `${BASE_Url}/item/save-category-image/${categoryId}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      console.log("API response:", response.data);
    } catch (error) {
      console.error("Error adding category image:", error);
    }
  };

  const [status, setStatus] = useState('');

  const toggleStatus = (item) => {
    // const {  saasId } = JSON.parse(localStorage.getItem("STORE_DATA"));
    const newStatus = item.status === 'active' ? 'inactive' : 'active';
    // Replace the API endpoint with your actual URL
    axios
      .put(`${BASE_Url}/item/update-item-status/${item.item_id}/${newStatus}`)
      .then((response) => {
        setStatus(newStatus);
        getMenu();
      })
      .catch((error) => {
        console.error(error);
        // Handle API request errors here
      });
  };

  //Delete item from menu by item id
  const deletitembyid = (itemId) => {
    axios
      .delete(`${BASE_Url}/search/delete-item/${itemId}/`)
      .then((res) => {
        if (res.status === 200) {
          console.log("res DELETE", res);
          Swal.fire(res.data.message);
          getMenu();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };



  // <----------------DataTable Colume------------------->
  const columns = [
    {
      name: "Item Name",
      center: true,
      selector: (row) => row.item_name,
      
      
      cell: (row) => {
        return (
          <>
            <div style={{ fontWeight: "bolder" }}>{row.item_name}</div>
          </>
        );
      },
    },
    {
      name: "Image",
      center: true,
      selector: (row) => row.item_id,
      cell: (row) => {
        return (
          <>
            <Image src={`${BASE_Url}/item/get-image/${row.item_id}`}
              style={{ width: '100px', height: '80px' }} alt="" />
            {/* <div style={{ fontWeight: "bolder" }}>{row.item_name}</div> */}
          </>
        );
      },
    },
    {
      name: "Category",
      center: true,
      selector: (row) => row.category,
      style: {
        fontWeight: "bold",
      },
    },
    // {
    //     name: 'Discount',
    //     center: true,
    //     selector: row => row.discount,
    // },
    {
      name: "Price",
      center: true,
      selector: (row) => row.item_price,
      style: {
        fontWeight: "bold",
      },
    },
      {
      name: "Tax",
      center: true,
      style: {
        fontWeight: "bold",
      },
      selector: (row) => row.tax_rate,
    },

    {
      name: "Action Button",
 width: '200px',
      center: true,
      selector: (row) => {
        return (
          <>
            <div className="d-flex">
         
              <Switch {...label} checked={row.status === "active"} onChange={() => toggleStatus(row)} />
              <div className="p-2">
                <MdEdit
                  size={22}
                  // color="var(--primary1)"
                  className="cursor-pointer fw-bold text-green-700"
                  onClick={() => {
                    // setAddUpdateItemModalIsOpen(!addUpdateItemModalIsOpen);
                    setAddUpdateItemModalIsOpen(true);
                    setRowData(row)
                    console.log("this is row", row)
                  }}
                />
              </div>
              <div className="p-2">
                <DeleteForeverOutlinedIcon
                  size={22}
                  color="var(--primary1)"
                  className="cursor-pointer fw-bold text-red-600"
                  onClick={() => {
                    deletitembyid(row.item_id);
                  }}
                />
              </div>
            </div>


          </>
        );
      },
    },
  ];
  const customStyles = {
    headCells: {
      style: {
        fontWeight: "bold",
      },
    },
  };
  
  const [screenWidth, setScreenWidth] = useState(0);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };
  
    setScreenWidth(window.innerWidth); // Initialize screenWidth
  
    window.addEventListener("resize", handleResize);
  
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);


  const [activeCategoryId, setActiveCategoryId] = useState(null);
  const DeleteCategorybyid= async (categoryId) => {
    await axios.delete(`${BASE_Url}/search/delete-category/${categoryId}`)
     .then((res) => {
        console.log("res DELETE CATEGORY", res);
        Swal.fire(res.data.message);
        getCatogery();
      })
     .catch((err) => {
        console.log(err);
      });
  };

  const [search, setSearch] = useState("");
  useEffect(() => {
    if (search) {
      const apiUrl = `${BASE_Url}/search/get-result/${storeId}/${saasId}/${search}`;
      axios
        .get(apiUrl)
        .then((response) => {
          if (response.status === 200) {
            console.log("new Api", response);
            const data = response.data.data;
            console.log(" search", data);
            // Assuming you want to display the data for the fourth item (index 3)
            // setItemData(data.data.item_list[3]);
            setSelectedCategory(data);
            // setSearch(data);
          } else {
            console.log("Failed to fetch data from the API.");
          }
        })
        .catch((error) => {
          console.error("An error occurred:", error);
        });
    } else {
      getMenu();
    }
  }, [search]);

  return (
    <div 
      style={{
        maxWidth: "1100px",
        flexWrap: "wrap",
        height: "100%",
        overflowY: "auto",
        boxShadow: '0 2px 12px rgba(36, 36, 39, 0.12)',
        borderRadius: '0.75rem',
        overflow: 'hidden',
        fontFamily: "DM sans-serif",
        fontSize: "1rem",
        border: "0 2px 12px rgba(36, 36, 39, .12)", padding: "20px"
      }}
    >
      <Itemupdate 
        categories={itemData}
        setAddUpdateItemModalIsOpen={setAddUpdateItemModalIsOpen}
        addUpdateItemModalIsOpen={addUpdateItemModalIsOpen}
        row={RowData}
      />
      <div className="flex fw-bold item-center justify-between mx-5"
         >
        <div style={{fontFamily:"inter",}} className="semibold text-xl flex items-center">Menu</div>
        <Col className="mt-2" xs={12} sm={6}>
            <Paper
              component="form"
              sx={{
                height: "40px",
                p: "2px 4px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                // width: 600,
                background: "#f2f1f1",
                // border: "2px solid white",
                // borderRadius: "10px",
              }}
              // onSubmit={(e) => {
              //   getSearchEmployee(e);
              // }}
            >
              <IconButton
                // type="submit"
                sx={{ p: "10px" }}
                aria-label="search"
              >
                <SearchIcon />
              </IconButton>
              <InputBase
                sx={{ ml: 1, flex: 1 }}
                onChange={(e) => {
                  setSearch(e.target.value);
                }}
                placeholder="Search Items"
                inputProps={{ "aria-label": "search Items" }}
              />

              <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
            </Paper>
          </Col>
        <div className="flex ">
          <DeleteCategory getCatogery={getCatogery} DeleteCategorybyid={DeleteCategorybyid} categories={itemData}/>
        <Button  style={{background:"#0E2954"}}  className=" text-white semibold rounded md:mt-0"
            onClick={() => setOpenModal(true)}
          >
            Add Category
          </Button>
          <Addform
            trigger={buttonPopup}
            setTrigger={setButtonPopup}
            addCategoryToMenu={addCategoryToMenu}
          />
        </div>
      </div>

      <div
      className="mt-2 h-24"
      style={{
        overflowX: "auto",
        boxShadow: "0 2px 12px rgba(36, 36, 39, 0.12)",
        borderRadius: "0.75rem",
        overflow: "hidden",
        fontFamily: "DM sans-serif",
        fontSize: "1rem",
        border: "0 2px 12px rgba(36, 36, 39, .12)",
        padding: "10px",
        maxWidth:"1100px"

      }}
    >
      <div className="flex overflow-x-auto space-x-4 px-2 font-bold">
      {itemData.map((data) => (
        <div
          key={data.category_id}
          className={`min-w-[150px] text-center min-h-[10px] h-14 py-2 rounded-md transition-colors duration-300 cursor-pointer ${
            activeCategoryId === data.category_id ? 'bg-[#0e2954] text-white' : 'bg-gray-100 text-black'
          }`}
          onClick={() => {
            setActiveCategoryId(data.category_id);
            setCategoryId(data.category_id);
          }}
        >
          {data.category_name}
        </div>
      ))}
    </div>
    </div>

      <div className=" mt-2 rounded  "
        style={{
          overflowX: "auto",
          boxShadow: "0 2px 12px rgba(36, 36, 39, 0.12)",
          borderRadius: "0.75rem",
          overflow: "hidden",
          fontFamily: "DM sans-serif",
          fontSize: "1rem",
          border: "0 2px 12px rgba(36, 36, 39, .12)",
          padding: "10px",
          maxWidth:"1100px"
        }}>
   


        <div className="font-bold  fw-bold" onClick={() => { console.log("this data", selectedCategory) }}>Food Item</div>
        <div className="overflow-y-scroll" style={{height:"45vh"}}>
          {selectedCategory?.length > 0 && (
            <div>
              {/* <ul className="flex flex-wrap" style={{ zIndex: "-1" }}> */}
              {selectedCategory?.length > 0
                ? <DataTable
                  columns={columns}
                  responsive={true}
                  // fixedHeader={true}
                  // fixedHeaderScrollHeight="300px"
                  data={selectedCategory}
                  // progressPending={loading}
                  // pagination
                  // paginationServer
                  customStyles={customStyles}
                  paginationTotalRows={selectedCategory}
                // onChangeRowsPerPage={10}
                // onChangePage={handlePageChange}
                />
                : ""}
              {/* </ul> */}

            </div>
          )}
        
        </div>
        <Button  style={{background:"#0E2954"}}  className="mt-2 text-white semibold rounded md:mt-0"
            onClick={() => setPopup(true)}
          >
            Add Food
          </Button>
          <Addfooditem
            getMenu={getMenu}
            trigger={popup}
            setTrigger={setPopup}
            addFoodItemToMenu={addFoodItemToMenu}
          ></Addfooditem>
      </div>
      <Modal
       show={openModal}
       onHide={() =>   setOpenModal(false)}
       // fullscreen={true}
       aria-labelledby="contained-modal-title-vcenter"
       centered

>
<Modal.Header className="container" closeButton>
                  <Modal.Title>Add Category</Modal.Title>
                </Modal.Header>
                <Container>
          <div
            style={{
              width: "100%",
              margin: "auto",
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              marginBottom: "20px",
              cursor: "pointer",
              position: "relative",
            }}
            onClick={() => { }}
          >
         

            <form
              onSubmit={handleSubmit}
              style={{
                width: "100%",
                // display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div className="mb-3">
                <label for="exampleInputEmail1" className="form-label">
                  Category Name
                </label>
                <input
                  required
                  type="text"
                  value={category}
                  onChange={(e) => setCategory(e.target.value)}
                  className="form-control"
                  id="exampleInputEmail1"
                  placeholder="Enter Category Name"
                  aria-describedby="emailHelp"
                />
              </div>
              <Grid item xs={6}>
          <Button
            variant="contained"
            component="label"
            fullWidth
            style={{ marginTop: '1rem' }}
          >
            Upload Category Image
            <input
              type="file"
              style={{ display: "none" }}
              onChange={(e) => {
                console.log("evfdcvfvfvc", e.target.files[0].name);
                setImage(e.target.files[0]);
              }}
            />
          </Button>
        </Grid>
              <hr/>
<div className="d-flex justify-end">
<Button onClick={()=>setOpenModal(false)}  className="text-black font-semibold">
                Cancel
              </Button>
              <Button style={{background:"#0E2954"}} type="submit" className="text-white font-semibold">
                Done
              </Button>
          </div>

            </form>
          </div>
        
        </Container>
      </Modal>
    </div>
  );
}

export default Menu;
