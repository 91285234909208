import { Box, Modal } from '@mui/material'
import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { AiOutlineClose } from 'react-icons/ai'
import { BASE_Url } from '../../URL.js/URL';

const OrderBillmodal = ({open,setOpen}) => {
    const [TotalAmount, setTotalAmount] = useState(0)
    const style = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: 480,
        height: 575,
        bgcolor: "background.paper",
        border: "2px solid #000",
        boxShadow: 24,
        p: 4,
      };
    
      const MyTotalbill= () =>{
        const {order_id} = JSON.parse(localStorage.getItem("my-order")) 
        axios
        .get(
          `${BASE_Url}/order/total-bill-amount/${order_id}`
        )
        .then((res) => {
          if (res.status === 200) {
            console.log("recommended DATA", res.data.data.total_bill_amount);
            setTotalAmount(res.data.data.total_bill_amount)
          }
        })
        .catch((err) => console.log(err));
      }
         
      useEffect(() => {
        MyTotalbill()
      }, [])
      


  return (
    <Modal
            open={open}
            // onClose={}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                  marginBottom: "20px",
                  cursor: "pointer",
                }}
                onClick={() => {
                    setOpen((state) => !state);
                  // navigate("/");
                }}
              >
                <AiOutlineClose size={20} />
              </div>
            
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <h2>Total Bill:{TotalAmount}</h2>
                <button className="bg-dark-subtle mt-10 p-2" onClick={()=>setOpen(false)}>
                 Close
                </button>
                {/* {
                  <div style={{ height: 200, width: 200 }}>
                    {qr && (
                      <img
                        style={{ height: "100%", width: "100%" }}
                        src={`${BASE_Url}/transaction/pdf-qr/${qr}`}
                        alt="qr code"
                      />
                    )}
                  </div>
                } */}
              </div>
            </Box>
          </Modal>
  )
}

export default OrderBillmodal