import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

const Card = ({ children }) => (
  <div style={{
    backgroundColor: 'white',
    borderRadius: '8px',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
    padding: '24px',
    width: '100%',
    maxWidth: '400px',
    textAlign: 'center'
  }}>
    {children}
  </div>
);

const Button = ({ children, onClick}) => (
  <button onClick={onClick} style={{
    backgroundColor: '#4A90E2',
    color: 'white',
    border: 'none',
    borderRadius: '4px',
    padding: '10px 20px',
    fontSize: '16px',
    fontWeight: 'bold',
    cursor: 'pointer',
    transition: 'background-color 0.3s'
  }}>
    {children}
  </button>
);

const NoOrderFound = () => {
    const navigate = useNavigate();
    //function to navigate to previous page
    const {saas_id, store_id, table_id} = useParams();
    const handleClick = () => {
      console.log("Going back to previous page... 3 seconds later");
        navigate(`/${saas_id}/${store_id}/${table_id}`);
    }

  return (
    <div style={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      minHeight: '100vh',
      backgroundColor: '#f5f5f5',
      padding: '16px'
    }}>
      <Card>
        <div style={{ marginBottom: '24px' }}>
          <svg
            width="96"
            height="96"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            style={{ margin: '0 auto', color: '#718096' }}
          >
            <path d="M16 4h2a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h2"></path>
            <rect x="8" y="2" width="8" height="4" rx="1" ry="1"></rect>
            <path d="M12 11h4"></path>
            <path d="M12 16h4"></path>
            <path d="M8 11h.01"></path>
            <path d="M8 16h.01"></path>
          </svg>
        </div>
        <h2 style={{
          fontSize: '24px',
          fontWeight: 'bold',
          marginBottom: '16px'
        }}>
          No Orders Found
        </h2>
        <p style={{
          color: '#718096',
          marginBottom: '24px'
        }}>
          It looks like you haven't placed any orders yet. Why not explore our delicious menu and treat yourself to something tasty?
        </p>
        <Button onClick={handleClick} >Browse Menu</Button>
      </Card>
    </div>
  );
};

export default NoOrderFound;