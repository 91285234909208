import React, { useRef } from 'react'
import { Box, Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import { BASE_Url } from '../../URL.js/URL';
import axios from 'axios';
import DownloadComponent from '../CSVDownload/DownloadComponent';
import { useReactToPrint } from 'react-to-print';
import DownloadPDFButton from '../CSVDownload/DownloadPDFButton';
const BahikhataReport = ({startdate, todate}) => {
   
    const [allData, setAllData] = React.useState([])
    const { storeId, saasId } = JSON.parse(localStorage.getItem('STORE_DATA'));
    React.useEffect(() => {
        axios.get(`${BASE_Url}/bahikhata/view-bahikhata-details/${saasId}/${storeId}/${startdate}/${todate}`)
       .then((res) => {
         setAllData(res.data.data);
       })
       .catch((error) => {
         console.error("Error fetching data:", error);
       });
       }, [startdate, todate])



   //new update code
   const header= [
    {
      label: "Payment Date",
      key: "paymentDate",
    },
    {
      label: "Party Name",
      key: "partyName",
    },

    {
      label: "Payment Type",
      key: "paymentType",
    },
    {
      label: "Payment Mode",
      key: "paymentMode",
    },
    {
      label: "Amount",
      key: "amount",
    },
    {
      label: "Note",
      key: "paymentNotes",
    },
  ]

const tableRef = useRef(null); // Ref for the table container

const handleTablePrint = useReactToPrint({
 content: () => tableRef.current,
 documentTitle: 'Sales_Report',
 onBeforePrint: () => console.log("Printing Sales Table..."),
});

  return (
    <div>

<DownloadComponent headers={header} fileName="ExpeseReport" data={allData} />
      <Button
              variant="contained"
              className="mx-2"
              style={{background:"rgb(14, 41, 84)",color:"white"}}
              onClick={handleTablePrint}
            >
              PRINT 
            </Button>
               {/* Reusable PDF Download Button */}
               <DownloadPDFButton
              data={allData}
              headers={header}
              fileName="ExpeseReport.pdf"
            />
        <Box ref={tableRef}  className="max-h-[calc(100vh-234px)] overflow-y-auto w-100 rounded-lg">

        <TableContainer component={Paper}>
              <Table className="text-nowrap" aria-label="simple table">
                <TableHead className="bg-dark-purple">
                  <TableRow>
                    <TableCell className=" text-white">Payment Date</TableCell>
                    <TableCell className=" text-white">Party Name</TableCell>
                    <TableCell className=" text-white">Payment Type</TableCell>
                    <TableCell className=" text-white">Payment Mode</TableCell>
                    <TableCell className=" text-white">Amount</TableCell>
                    <TableCell className=" text-white">Note</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {allData?.map((ele,index) => (
                    <TableRow key={index}>
                      <TableCell>{ele.paymentDate}</TableCell>
                      <TableCell>{ele.partyName}</TableCell>
                      <TableCell>{ele.paymentType}</TableCell>
                      <TableCell>{ele.paymentMode}</TableCell>
                      <TableCell>{ele.amount}</TableCell>
                      <TableCell>{ele.paymentNotes}</TableCell>
                     
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
                  </Box>
    </div>
  )
}

export default BahikhataReport