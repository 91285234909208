import React, { useState } from "react";
import Counter from "./Counter";
import { useSelector } from "react-redux";

const List = ({ ArrayItem, handleQtyChange }) => {
  const order_details = useSelector((state) => {
    return state.tableData.createCustomer.order_details;
  });
  console.log(order_details);
  return (
    <div className="h-">
      <ul className="grid grid-cols-12 m-2 border-b-2 border-x-2 ">
        <li className="col-span-4 m-2 font-bold  ">Items</li>
        <li className="col-span-4 m-2  font-bold ">Qty</li>
        <li className="col-span-4 m-2  font-bold justify-self-stretch">
          Price
        </li>
      </ul>
      <div className="flex flex-col justify-evenly w-full">
        {order_details?.map((e, index) => {
          return (
            <div className=" grid grid-cols-12 border-b-2 w-full">
              <div className="font-sans my-4 col-span-4 p-2 ">
                {e.item_name}
              </div>
              <div className="col-span-4 mt-4 ">
                <Counter
                  qty={e.item_qty}
                  index={index}
                  handleQtyChange={handleQtyChange}
                />
              </div>

              <div className="font-sans col-span-4 my-4 pr-2 ">
                {e.item_price}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default List;
