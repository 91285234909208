// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.catgoryselected{
    margin: 1px;
    border-radius: 4px;
    background-color: #00B207 !important;
}
`, "",{"version":3,"sources":["webpack://./src/Components/Opt/CategorySwiper.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,kBAAkB;IAClB,oCAAoC;AACxC","sourcesContent":[".catgoryselected{\r\n    margin: 1px;\r\n    border-radius: 4px;\r\n    background-color: #00B207 !important;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
