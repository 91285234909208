import React, { useEffect, useState } from 'react';
import { Card, CardContent, CardHeader, Typography, Grid } from '@mui/material';
import { FiArrowUp, FiArrowDown, FiBox, FiDollarSign } from 'react-icons/fi';
import { MdCurrencyRupee } from "react-icons/md";

import axios from 'axios';
import { BASE_Url } from '../../URL.js/URL';

// Importing and registering required Chart.js components
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, ArcElement, Tooltip as ChartTooltip, Legend } from 'chart.js';
import { Doughnut, Line } from 'react-chartjs-2';

// Register the components with Chart.js
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, ArcElement, ChartTooltip, Legend);

const Dashboard = () => {
    const [dashboardData, setDashboardData] = useState({
        todaysSales: 0,
        salesInAMonth: 0,
        taxInAMonth: 0,
        discountInAMonth: 0,
        onlineSalesInAMonth: 0,
        dineInSalesInAMonth: 0,
        takeOutSalesInAMonth: 0,
      });
 const bankAccount = JSON.parse(localStorage.getItem('STORE_DATA'))?.bankAccount;
  useEffect(() => {
    const fetchData = async () => {
      const { storeId, saasId } = JSON.parse(localStorage.getItem("STORE_DATA"));

      try {
        const response = await axios.get(
          `${BASE_Url}/dashboard/get-dashboard-in1/${saasId}/${storeId}`
        );
        if (response.data.status) {
          setDashboardData(response.data.data);
        }
      } catch (error) {
        console.error('Error fetching dashboard data:', error);
      }
    };
    fetchData();
  }, []);


  const [doughnutData, setDoughnutData] = useState({
    labels: ['No Item '],
    datasets: [
      {
        data: [20],
        backgroundColor: ['#c3c3c3', '#36A2EB', '#FFCE56', '#FF9F40', '#FF6384'],
      },
    ],
  });

  const fetchChartData = async () => {
    try {
      const { storeId, saasId } = JSON.parse(localStorage.getItem("STORE_DATA"));
      const response = await axios.get(`${BASE_Url}/dashboard/get-dashboard-item-data/${saasId}/${storeId}`); // Replace with your actual API endpoint
      const chartData = response.data.data; // Access the 'data' field from the response
      
      // Update doughnutData with the fetched API data
      console.log(chartData.amount)
      if(chartData.amount.length > 0 ) // Check if chartData is not empty
      {
        setDoughnutData({
          labels: chartData.productName, // Use productName as labels
          datasets: [
            {
              data: chartData.amount, // Use amount as data
              backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56', '#FF9F40', '#FF6384'], // Add as many colors as needed
            },
          ],
        });
      }
    } catch (error) {
      console.error('Error fetching chart data:', error);
    }
  };
  
  // Call fetchChartData when the component mounts
  useEffect(() => {
    fetchChartData();
  }, []);
  
  const stats = [
    { title: 'Today Sales', value: dashboardData.todaysSales, icon: FiBox, changeType: 'increase' },
    { title: 'Total Sales', value: dashboardData.salesInAMonth, icon: FiBox, changeType: 'increase' },
    { title: 'Total Tax', value: `₹${dashboardData.taxInAMonth}`, icon: bankAccount == "CAD"?FiDollarSign:MdCurrencyRupee, changeType: 'decrease' },
    { title: 'Total Discount', value: `₹${dashboardData.discountInAMonth}`, icon: bankAccount == "CAD"?FiDollarSign:MdCurrencyRupee, changeType: 'decrease' },
    { title: 'Online Sales', value: dashboardData.onlineSalesInAMonth, icon: FiBox, changeType: 'increase' },

    { title: 'Dine-In Sales', value: dashboardData.dineInSalesInAMonth, icon: FiBox, changeType: 'increase' },
    { title: 'PickUp Sales', value: dashboardData.takeOutSalesInAMonth, icon: FiBox, changeType: 'increase' },
    { title: 'Digital Sales', value: dashboardData.onlineSalesInAMonth, icon: FiBox, changeType: 'increase' },

  ];
  // const doughnutData = {
  //   labels: ['Plain Tea', 'Butter Scotch Cake', 'Choco Lava Cake', 'Dark Forest Cake', 'Masala Tea'],
  //   datasets: [
  //     {
  //       data: [10, 20, 30, 25, 15],
  //       backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56', '#FF9F40', '#FF6384'],
  //     },
  //   ],
  // };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { month: 'short', day: 'numeric' }; // Customize to "MMM DD"
    return date.toLocaleDateString('en-US', options);
  };

  const [lineData, setLineData] = useState({
    labels: [],
    datasets: [
      {
        label: 'Sales',
        data: [],
        fill: false,
        borderColor: '#FF6384',
      },
    ],
  });

    // Fetching the API data and updating state
    const fetchSalesData = async () => {
      try {
        const { storeId, saasId } = JSON.parse(localStorage.getItem("STORE_DATA"));
        const response = await axios.get(`${BASE_Url}/dashboard/get-graph-data/${saasId}/${storeId}`); // Replace with your actual API URL
        const salesData = response.data.data; // Access the 'data' field from the API response
        
  // Convert dates to "MMM DD" format
  const formattedDates = salesData.dates.map(date => formatDate(date));

        // Handle null values by replacing them with 0
        const salesAmounts = salesData.salesAmounts.map(amount => amount !== null ? amount : 0);
  
        // Update lineData state with the fetched data
        setLineData({
          labels: formattedDates, // Use dates as labels
          datasets: [
            {
              label: 'Sales',
              data: salesAmounts, // Use cleaned salesAmounts (with nulls replaced)
              fill: false,
              borderColor: '#FF6384',
            },
          ],
        });
      } catch (error) {
        console.error('Error fetching sales data:', error);
      }
    };
  
    // Call fetchSalesData when the component mounts
    useEffect(() => {
      fetchSalesData();
    }, []);
  return (
    <div className="p-4 space-y-4">
      <Grid container spacing={2}>
        {stats.map((stat, index) => (
          <Grid item xs={12} sm={6} md={3} key={index}>
            <Card>
              <CardContent className="flex items-center px-2">
                <div className={`rounded-full p-2 ${stat.changeType === 'increase' ? 'bg-green-100' : 'bg-red-100'}`}>
                  <stat.icon className={`w-6 h-6 ${stat.changeType === 'increase' ? 'text-green-600' : 'text-red-600'}`} />
                </div>
                <div className="ml-4">
                  <Typography className='text-nowrap' variant="subtitle2" color="textSecondary">
                    {stat.title}
                  </Typography>
                  <Typography className='text-nowrap' variant="h6" fontWeight="bold">
                    {stat.value}
                  </Typography>
                </div>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>

      {/* Scrollable Chart Section */}
      <div className="max-h-[400px] overflow-y-auto mb-10">
        <Grid container spacing={2}>
          {/* Income Chart */}
          <Grid item xs={12} sm={6} md={4}>
            <Card>
              <CardHeader title="Product performance" />
              <CardContent>
                {/* <h3>March 2023</h3> */}
                <div style={{ width: '100%', height: '300px' }}>
                  <Doughnut data={doughnutData} options={{ maintainAspectRatio: false }} />
                </div>
              </CardContent>
            </Card>
          </Grid>

          {/* Sales Overview Chart */}
          <Grid item xs={12} sm={6} md={8}>
            <Card>
              <CardHeader title="Sales Overview" />
              <CardContent>
                {/* <h3>Sales</h3> */}
                <div style={{ width: '100%', height: '300px' }}>
                  <Line data={lineData} options={{ maintainAspectRatio: false }} />
                </div>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default Dashboard;
