export let BASE_Url = "";
export let NPS_Url = "";
export const isDev = false;
if (isDev === true) {
  BASE_Url = "http://103.139.59.233:8092/prod/api/v1";
  NPS_Url = "http://103.139.59.233:8088/prod/api/v1"
} else if (isDev === false) {
  NPS_Url = "https://annapurnaprdapi.photonsoftwares.com/prod/api/v1"
  BASE_Url = "https://annapurnaprdapi.photonsoftwares.com/prod/api/v1";
}

export const LOYALTY_URL =  "http://103.148.165.246:8094/test/v1";

