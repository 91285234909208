import React, { useState } from 'react'
import { 
    Table, 
    TableBody, 
    TableCell, 
    TableContainer, 
    TableHead, 
    TableRow, 
    Paper, 
    Button, 
    TextField
  } from '@mui/material';
  import EditIcon from '@mui/icons-material/Edit';  
  import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
  import { BASE_Url } from '../../URL.js/URL';
import axios from 'axios';
const EditCategory = ({categories}) => {
    const [modal, setModal] = useState(false);
    const [editModal, setEditModal] = useState(false); // For "Edit Category" modal
    const toggle = () => setModal(!modal);
    const [selectedCategory, setSelectedCategory] = useState(null);
  const [updatedCategoryName, setUpdatedCategoryName] = useState("");
  const toggleEditModal = () => setEditModal(!editModal);
    const handleEditClick = (category) => {
        setSelectedCategory(category);
        setUpdatedCategoryName(category.category_name); // Set initial value of the category name
        toggleEditModal(); // Open Edit Modal
      };

      const handleSave =()=>{
        console.log("dafadk")
      }
  return (
    <div>
        <Button onClick={toggle} variant="contained" color="primary" type="button">Edit Category</Button>
        <Modal isOpen={modal} toggle={toggle} >
        <ModalHeader toggle={toggle}>All Category</ModalHeader>
        <ModalBody>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 500 }} aria-label="category table">
              <TableHead>
                <TableRow>
                  <TableCell>Category Name</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {categories?.map((category) => (
                  <TableRow key={category}>
                    <TableCell>{category}</TableCell>
                    <TableCell>
                      <Button
                        variant="contained"
                        color="primary"
                        startIcon={<EditIcon />}  // Use Edit Icon
                        onClick={() => handleEditClick(category)}  // Open Edit modal
                      >
                        Edit
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </ModalBody>
      </Modal>

      {selectedCategory && (
        <Modal isOpen={editModal} toggle={toggleEditModal}>
          <ModalHeader toggle={toggleEditModal}>Edit Category</ModalHeader>
          <ModalBody>
            <TextField
              label="Category Name"
              fullWidth
              value={updatedCategoryName}
              onChange={(e) => setUpdatedCategoryName(e.target.value)}
            />
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={handleSave}>
              Save
            </Button>
            <Button color="secondary" onClick={toggleEditModal}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      )}
    </div>
  )
}

export default EditCategory