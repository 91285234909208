import React from "react";
import { Button } from "@mui/material";
import jsPDF from "jspdf";
import "jspdf-autotable";

const DownloadPDFButton = ({ data, headers, fileName }) => {
  const handleDownloadPdf = () => {
    const doc = new jsPDF();
    const tableColumn = headers.map((header) => header.label);
    const tableRows = data.map((row) =>
      headers.map((header) => row[header.key])
    );

    doc.autoTable({
      head: [tableColumn],
      body: tableRows,
    });

    doc.save(fileName || "table_data.pdf");
  };

  return (
    <Button
      variant="contained"
      style={{background:"rgb(14, 41, 84)",color:"white"}}
      onClick={handleDownloadPdf}
      className="mx-2"
    >
      DOWNLOAD PDF
    </Button>
  );
};

export default DownloadPDFButton;
