import { useState, useEffect } from 'react'
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, ArcElement, Tooltip as ChartTooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import { Card, CardContent, CardHeader, Typography } from '@mui/material';
import axios from 'axios';
import { NPS_Url } from '../../URL.js/URL';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, ArcElement, ChartTooltip, Legend);



export default function Npsdashbooard() {
  const [chartData, setChartData] = useState({ datasets: [] })
  const [npsScore, setNpsScore] = useState(0)
  const [npsData, setNpsData] = useState({})
  const { saasId } = JSON.parse(localStorage.getItem('STORE_DATA')) || {};
  //api hit to set nps data use Axios
  const GetNpsData = async () => {
    try {
      const response = await axios.get(`${NPS_Url}/feedback/get-monthly-data/${saasId}`);
      console.log('response:', response.data.data)
      setNpsData(response.data.data)
    } catch (error) {
      console.error('Error fetching NPS data:', error)
    }
  }

  useEffect(() => {
    GetNpsData()
  }, [])

  useEffect(() => {
    const score = ((npsData.promoter - npsData.detractor) / npsData.total * 100).toFixed(1)
    setNpsScore(parseFloat(score))

    setChartData({
      labels: ['Promoters', 'Passives', 'Detractors'],
      datasets: [
        {
          data: [npsData.promotor, npsData.passive, npsData.detractor],
          backgroundColor: [
            'rgba(34, 197, 94, 0.8)',  // green
            'rgba(251, 191, 36, 0.8)', // yellow
            'rgba(239, 68, 68, 0.8)',  // red
          ],
          borderColor: [
            'rgba(34, 197, 94, 1)',
            'rgba(251, 191, 36, 1)',
            'rgba(239, 68, 68, 1)',
          ],
          borderWidth: 1,
        },
      ],
    })
  }, [npsData])

  const options = {
    plugins: {
      legend: {
        position: 'bottom',
      },
      tooltip: {
        enabled: true,
      },
      // Center text plugin for displaying npsScore in the center of Doughnut chart
      beforeDraw(chart) {
        const { width, height, ctx } = chart;
        ctx.restore();
        const fontSize = (height / 160).toFixed(2);
        ctx.font = `${fontSize}em sans-serif`;
        ctx.textBaseline = 'middle';

        const text = npsScore.toString();
        const textX = Math.round((width - ctx.measureText(text).width) / 2);
        const textY = height / 2;

        ctx.fillText(text, textX, textY);
        ctx.save();
      }
    },
    responsive: true,
    cutout: '70%',
  }

  return (
    <Card sx={{ maxWidth: 600, width: '100%', margin: 'auto', maxHeight:"calc(100vh - 136px)" , overflowY:"auto" }}>
      <CardHeader
        title={<Typography variant="h5">Net Promoter Score (NPS)</Typography>}
        subheader={<Typography variant="subtitle1">Based on {npsData.total} responses</Typography>}
      />
      <CardContent className="flex flex-col items-center">
        <div className="w-full h-64 relative mb-6 flex items-center justify-center">
          <Doughnut data={chartData} options={options} />
        </div>
        <div className="grid grid-cols-3 gap-4 w-full text-center">
          <div className="flex flex-col items-center">
            <Typography variant="h6" sx={{ color: 'success.main' }}>{npsData.promotor}</Typography>
            <Typography variant="body2" color="textSecondary">Promoters</Typography>
          </div>
          <div className="flex flex-col items-center">
            <Typography variant="h6" sx={{ color: 'warning.main' }}>{npsData.passive}</Typography>
            <Typography variant="body2" color="textSecondary">Passives</Typography>
          </div>
          <div className="flex flex-col items-center">
            <Typography variant="h6" sx={{ color: 'error.main' }}>{npsData.detractor}</Typography>
            <Typography variant="body2" color="textSecondary">Detractors</Typography>
          </div>
        </div>
        <div className="mt-6">
          <Typography variant="body2" color="textSecondary">
            <p>Promoters (9-10): Loyal enthusiasts who will refer others</p>
            <p>Passives (7-8): Satisfied but unenthusiastic customers</p>
            <p>Detractors (0-6): Unhappy customers who can damage your brand</p>
          </Typography>
        </div>
      </CardContent>
    </Card>
  )
}
