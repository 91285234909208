import React, { useEffect, useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Button,
  Grid,
  Paper,
  TableFooter,
  Box,
  Fade,
  Backdrop,
  Modal,
  Autocomplete
} from '@mui/material';
import {  MenuItem, Select, InputLabel, FormControl } from '@mui/material';
import { DatePicker } from 'antd';
import { Tab, Tabs } from 'react-bootstrap';
import { BsCheck2 } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
import { BASE_Url } from '../../URL.js/URL';
import Swal from 'sweetalert2';
import axios from 'axios';
import { Viewer, Worker } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import { Document, Page } from 'react-pdf';
import GetAllSupplier from './GetAllSupplier';
import AddInventoryItemModal from '../inventory/AddInventoryItemModal';
// import { DatePicker } from '@mui/x-date-pickers/DatePicker';
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 800,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const Purches = () => {
  const defaultLayoutPluginInstance = defaultLayoutPlugin();
  const [inventryShow, setInventryShow] = useState(false);
    const [date, setDate] = React.useState(null);
  const [party, setParty] = React.useState('');
  const {storeId, saasId}= JSON.parse(localStorage.getItem('STORE_DATA'));
  const [tableData, setTableData] = useState([]);
  const [activeTab, setActiveTab] = useState("ParchesOrder");
  const handleAddSupplier = () => setActiveTab("addsuplire");
  
  const [data, setData] = useState([]);
  const [totalRows, setTotalRows] = useState(0); 
  const getAllData = (pageNumber) => {

    axios
      .get(`${BASE_Url}/getall-inventory-bom/${saasId}/${storeId}/${pageNumber}`)
      .then((res) => {
        if (res.status === 200) {
          console.log("TABLE", res);
          setData(res.data.data);
          setTotalRows(res.data.count);
        }
      })
      .catch((err) => console.log(err));
  };


  const [itemDetail, setItemDetail] = useState({
    item_name: "",
    price: "0",
    quantity: "0",
    unit:""
  });

  const [showpdf, setShowPdf] = useState(false);
  const [PdfFile, setPdfFile] = useState("");


  const handleDateChange = (newDate) => {
    setDate(newDate);
  };

  const handlePartyChange = (event) => {
    console.log("value: ", event.target.value)
    setParty(event.target.value);
  };

  const navigate = useNavigate()




  //Purchase  Order Tab Logic start here
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handelitemDetailChange = (e) => {
    const { name, value } = e.target;
    setItemDetail((prevState) => ({
     ...prevState,
      [name]: value
    }));
  };

  const [partyOptions, setPartyOptions] = useState([]);
    const getPartyOptions = async () => {
        const apiUrl = `${BASE_Url}/supplier/get-party-name/${saasId}/${storeId}`;
        try {
            const response = await axios.get(apiUrl);
            console.log("API response:", response.data);
            setPartyOptions(response.data.data);
            // setPartyOptions(response.data.data);
        } catch (error) {
            console.log("API request failed:", error);
        }
    }
    useEffect(() => {
        getPartyOptions()
    }, [saasId]);
    const handleAddMaterialDetail = () => {
      console.log("itemDetail: ", itemDetail);
      if (itemDetail.item_name && itemDetail.price && itemDetail.quantity && itemDetail.unit) {
        const materialData = {
           item_name: itemDetail.item_name,
           price: itemDetail.price,
           productQty: itemDetail.quantity,
           uom: itemDetail.unit,
        };
        setTableData((prevTableData) => [...prevTableData, materialData]);
        setItemDetail({
          item_name: "",
          price: "",
          quantity: "",
          unit:""
        });
      }
    };
    //calculate total amount in tableData
    const totalAmount = tableData.reduce((total, item) => total + parseFloat(item.price) , 0);
    
    //Api for create purchase order 
    const handlePurchaseOrder = async () => {
        const purchaseOrderData = {
            saas_id: saasId,
            store_id: storeId,
            supplier_name: party?.values,
            supplier_id: party?.id,
            item_list: tableData
        };
        try {
            const response = await axios.post(`${BASE_Url}/purchase/create`, purchaseOrderData);
            console.log("API response:", response.data);
            if(response.data.status){
              // Swal.fire({
              //     title: 'Purchase Order Created Successfully',
              //     icon:'success',
              //     timer:2000
              // })
               setTableData([]);
               handleOpen()
               setShowPdf(true);
               setPdfFile(response.data.data.pdfname);
            }
        } catch (error) {
            console.log("API request failed:", error);
        }
    }
    
    const options = [
      { label: 'Option 1', value: 1 },
      { label: 'Option 2', value: 2 },
      { label: 'Option 3', value: 3 },
      // Add more options here
    ];
    const [allinventory, setAllInventoryData] = useState([])
    const getAllInventoryList = () => {
      const { storeId, saasId } = JSON.parse(localStorage.getItem("STORE_DATA"));
      axios
        .get(
          `${BASE_Url}/inventory-master/get-all-inventory-list/${storeId}/${saasId}`
        )
        .then((res) => {
          if (res.status === 200) {
            res.data.data.forEach((el) => {
              el["value"] = el.inventory_name;
              el["label"] = el.inventory_name;
            });
            setAllInventoryData(res.data.data);
          }
        })
        .catch((err) => console.log(err));
    };
  
    useEffect(() => {
      getAllInventoryList();
    }, []);
  

  return (
    <>
      <Tabs
         activeKey={activeTab}
         onSelect={(key) => setActiveTab(key)}
      id="uncontrolled-tab-example"
      className="mb-3  fw-bold"
      fill
      style={{background: '#FFF',
        boxShadow: "4px 4px 10px 0px rgba(0, 0, 0, 0.25)"
        }}
        // onSelect={(key)=>{
        //   setActivetab(key)
        // }}
        
    >
      <Tab eventKey="ParchesOrder" title="Purchase Order">
      <h2 className="text-center text-lg font-semibold mb-4">Generate Purchase Order</h2>
    <div className=" mx-auto p-4 overflow-y-auto max-h-[400px] md:max-h-[400px] ">
    
    {/* Bill Number */}
    {/* <div className="mb-4">
      <TextField fullWidth label="Bill Number" variant="outlined" />
    </div> */}
    
    {/* Date Picker */}
    {/* <div className="mb-4">
    <DatePicker 
    className='lg:w-[605px] w-[270px]  py-3'
    // onChange={onChange} 
    />
     
    </div> */}

    {/* Phone Number */}
    {/* <div className="mb-4">
      <TextField fullWidth label="Search by Phone Number" variant="outlined" />
    </div> */}
    
    {/* Select Party */}
    <div className="mb-4">
    <Grid container spacing={2} alignItems="center">
    {/* Select Party Component */}
   
    <Grid item xs={12} md={8}>
      <FormControl fullWidth>
        <InputLabel>Select Party</InputLabel>
        <Select
          value={party}
          onChange={handlePartyChange}
          label="Select Party"
        >
          {partyOptions&& partyOptions.map((item)=>{return(<MenuItem value={item}><em>{item.values}</em></MenuItem>)})}
         
        </Select>
      </FormControl>
      </Grid>

{/* Add Supplier Button */}
<Grid item xs={12} md={2}>
  <Button 
    variant="contained" 
    color="primary" 
    fullWidth
    onClick={handleAddSupplier}
  >
    Add Supplier
  </Button>
</Grid>
<Grid item xs={12} md={2}>
  <Button 
    variant="contained" 
    color="primary" 
    fullWidth
    onClick={() => setInventryShow(true)} 
  >
    Add Inventory
  </Button>
</Grid>

</Grid>
    </div>

    {/* Table */}
    <TableContainer style={{ height: "200px", marginBottom:"10px" }} component={Paper}>
            <Table stickyHeader className="table-white table-striped  h-10 ">
              <TableHead>
                <TableRow className="text-nowrap">
                  <TableCell align="center">Material Name</TableCell>
                  <TableCell align="center">Purchase Qty</TableCell>
                  <TableCell align="center">Cost per Qty </TableCell>
                  <TableCell align="center">Purchase Unit</TableCell>
                  <TableCell align="center">Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {tableData.length > 0 ? (
                  tableData.map((el, index) => (
                    <TableRow key={index}>
                      <TableCell align="center">{el.item_name}</TableCell>
                      <TableCell align="center">{el.productQty}</TableCell>
                      <TableCell align="center">{el.price}</TableCell>
                      <TableCell align="center">{el.uom}</TableCell>
                      {/* <TableCell align="center">{el.cost_of_material}</TableCell>
                      <TableCell align="center">{el.uom}</TableCell> */}
                      <TableCell align="center">
                        <BsCheck2 />
                      </TableCell>
                    </TableRow>
                 ))
                ) : null} 
                <TableRow>
                  <TableCell align="center">
                  <Autocomplete
      options={allinventory}
      getOptionLabel={(option) => option.label} // Determines what to display in the dropdown
      // value={allinventory.find((opt) => opt.label === itemDetail.item_name) || null}
      onChange={(event, newValue) => {
        handelitemDetailChange({
          target: { name: 'item_name', value: newValue?.label || '' },
        });
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          name="item_name"
          placeholder="Search Item"
          className="form-control"
          aria-describedby="nameHelp"
        />
      )}
      isOptionEqualToValue={(option, value) => option.label === value.label}
    />
                  </TableCell>
                 
                  <TableCell align="center">
                    <TextField
                      type="text"
                      name='quantity'
                      value={itemDetail.quantity}
                      placeholder="Quantity"
                      onChange={handelitemDetailChange}
                      className="form-control"
                      aria-describedby="costHelp"
                    />
                  </TableCell>
                  <TableCell align="center">
                    <TextField
                      type="text"
                      name='price'
                      value={itemDetail.price}
                      placeholder="Cost"
                      onChange={handelitemDetailChange}
                      className="form-control"
                      aria-describedby="costHelp"
                    />
                  </TableCell>
                  <TableCell align="center">
                  <FormControl fullWidth className="form-control ">
          <InputLabel id="demo-simple-select-label">Unit</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            name='unit'
            value={itemDetail.unit}
            label="Age"
            onChange={handelitemDetailChange}
          >
            <MenuItem value="Kg">KG</MenuItem>
            <MenuItem value="Gm">Gm</MenuItem>
            <MenuItem value="Ml">Ml</MenuItem>
            <MenuItem value="Lt">Lt</MenuItem>
          </Select>
        </FormControl>
                  </TableCell>
                  <TableCell align="center">
                    <Button
                      variant="contained"
                      color="warning"
                      onClick={handleAddMaterialDetail}
                    >
                      Add
                    </Button>
                  </TableCell>
                </TableRow>
              </TableBody>
              <TableFooter>
      <TableRow>
        <TableCell colSpan={2} align="right">
          <strong>Total Cost</strong>
        </TableCell>
        <TableCell align="center">
          {/* Calculate total cost dynamically from tableData */}
          {totalAmount /* Replace with your total calculation */}
        </TableCell>
        <TableCell />
      </TableRow>
    </TableFooter>
            </Table>
          </TableContainer>

    {/* Buttons */}
    <div className="flex justify-center">
      {/* <Button variant="contained" onClick={handlePurchaseOrder} className="bg-gray-600 text-white hover:bg-gray-700">Submit</Button> */}
      <Button variant="contained" onClick={handlePurchaseOrder} className="bg-green-500 text-white hover:bg-green-600">Submit and Print PO</Button>
    </div>
  </div>
        </Tab>
        <Tab eventKey="addsuplire" title="Supplier">
    
    <GetAllSupplier />
        </Tab>

    </Tabs>
    <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
          {showpdf &&
         <div style= {{height:550}}>
         {PdfFile && <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js">
             <Viewer plugins={[defaultLayoutPluginInstance]}  fileUrl={`${BASE_Url}/transaction/pdf/${PdfFile}`} />
     
         ...
         </Worker>}
         </div>
} 
          </Box>
        </Fade>
      </Modal>
      <AddInventoryItemModal getAllData={getAllData} show={inventryShow} onHide={() => setInventryShow(false)} />

    </>
  )
}

export default Purches