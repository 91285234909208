import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { TextField } from '@mui/material';
import axios from 'axios';
import { BASE_Url } from '../../URL.js/URL';
import Swal from 'sweetalert2';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  minWidth: 300,
  width: "fit-content",
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export default function AddCatgorymodal({getCategory}) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [categoryName, setCategory] = React.useState('');
  const AddCategory = async (e) => {
    try {
        const data ={
            categoryName: categoryName
        }
     const response = await axios.post(`${BASE_Url}/expense/create-expenses-category`,data)  
     if(response.data.status){
        setCategory('');
        handleClose();
        getCategory()
        Swal.fire({
            title: response.data.message,
            icon:'success',
            timer:2000
        })
        // alert("Category added successfully");
     } 

    } catch (error) {
        
    }
}

  return (
    <div>
      <Button onClick={handleOpen} variant="contained" color="primary" type="button">Add Category</Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Add Category
            </Typography>
         <TextField
          id="standard-basic"
          label="Category Name"
          variant="standard"
          type='text'
          onChange={(e) => setCategory(e.target.value)}
          />
          <div className='mt-2 '>
          <Button onClick={AddCategory}  className='mx-2' variant="contained" color="primary">Save</Button>
          <Button onClick={handleClose} variant="contained" color="primary">Close</Button>
        </div>

        </Box>
      </Modal>
    </div>
  );
}