import React, { useEffect, useState } from 'react';
import { Button, IconButton } from '@mui/material';
import { Delete, Edit } from '@mui/icons-material';
import { Box } from '@mui/system';
import DataTable from 'react-data-table-component';
import Swal from 'sweetalert2';
import { BASE_Url } from '../../URL.js/URL';
import axios from 'axios';
import AddSupplier from './AddSupplier';
import UpdateSupplier from './UpdateSupplier';

const GetAllSupplier = () => {
  const { storeId, saasId } = JSON.parse(localStorage.getItem('STORE_DATA'));
  const [manufacturers, setManufacturers] = useState([]);
  const [MenufactureModalOpen, setMenufactureModalOpen] = useState(false);
  const [UpdaetMoadal, setUpdaetMoadal] = useState(false);
  const [selected, setSelected] = useState({});
  const [searchTerm, setSearchTerm] = useState('');
  const [partyOptions, setPartyOptions] = useState([]);
  const [page, setPage] = useState(1);
  const [totalRows, setTotalRows] = useState(0);
  const [updateModalOpen, setUpdateModalOpen] = useState(false);
  const [selectedSupplier, setSelectedSupplier] = useState(null);
  const getPartyOptions = async (pageNumber) => {
    const apiUrl = `${BASE_Url}/supplier/get-supplier-details/${saasId}/${storeId}/${pageNumber}`;
    try {
      const response = await axios.get(apiUrl);
      console.log('API response:', response.data);
      setPartyOptions(response.data.data);
      setTotalRows(response.data.totalRows); // Assuming the API returns total rows.
    } catch (error) {
      console.log('API request failed:', error);
    }
  };

  useEffect(() => {
    getPartyOptions(page);
  }, [saasId, page]);

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  const handleDelete = async (id) => {
    try {
      const result = await Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      });

      if (result.isConfirmed) {
        const apiUrl = `${BASE_Url}/supplier/deleteSuplire/${id}`;
        const response = await axios.delete(apiUrl);
        Swal.fire('Deleted!', 'Supplier has been deleted.', 'success');
        getPartyOptions(1); // Refresh the list after deletion
      }
    } catch (error) {
      Swal.fire('Error', 'Failed to delete  Supplier', 'error');
    }
  };

  const handleEditClick = (supplier) => {
    setSelectedSupplier(supplier);
    setUpdateModalOpen(true);
  };

  const columns = [
    {
      name: 'ID',
      selector: (row) => row.supplier_id,
      sortable: true,
    },
    {
      name: 'Name',
      selector: (row) => row.party_name,
      sortable: true,
    },

    {
      name: 'Email',
      selector: (row) => `${row.email}`,
      sortable: true,
    },
    {
      name: 'Contact Info',
      selector: (row) => `${row.phone_number}`,
      sortable: false,
    },
    {
      name: 'Actions',
      selector: (row) => row.actions,
      center: true,
      cell: (row) => (
        <>
          <IconButton aria-label="edit"  onClick={() => handleEditClick(row)}>
            <Edit style={{ color: 'green' }} />
          </IconButton>
          <IconButton aria-label="delete" onClick={() => handleDelete(row.supplier_id)}>
            <Delete style={{ color: '#C81D20' }} />
          </IconButton>
        </>
      ),
    },
  ];

  return (
    <>
      <div className="p-4">
        <Box
          className="mb-2"
          p={2}
          borderRadius={2}
          border="1px solid #e0e0e0"
          boxShadow="0 2px 10px rgba(0,0,0,0.1)"
          width="100%"
          height="fit-content"
          bgcolor="white"
        >
          <div className="flex justify-between items-center">
            <h1 className="text-2xl">Create Supplier Directory</h1>
            <Button
              className="fw-bold"
              style={{ background: 'rgb(14, 41, 84)' }}
              variant="contained"
              color="primary"
              onClick={() => setMenufactureModalOpen(true)}
            >
              Create
            </Button>
          </div>
        </Box>
        <div className="bg-white p-6 shadow rounded-lg">
          <h2 className="text-xl mb-4 fw-bold">Supplier Directory</h2>
          <div style={{ height: '300px', overflow: 'auto' }}>
            <DataTable
              columns={columns}
              data={partyOptions}
              pagination
              responsive
              striped
              highlightOnHover
              pointerOnHover
              noHeader
              paginationServer
              paginationTotalRows={totalRows}
              onChangePage={handlePageChange}
            />
          </div>
        </div>
      </div>
      <AddSupplier getPartyOptions={getPartyOptions}  open={MenufactureModalOpen} onHide={() => setMenufactureModalOpen(false)} />

      <UpdateSupplier
        isOpen={updateModalOpen}
        toggle={() => setUpdateModalOpen(false)}
        supplierData={selectedSupplier}
        refreshList={() => getPartyOptions(page)}
      />
    </>
  );
};

export default GetAllSupplier;
