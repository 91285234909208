import React, { useEffect, useState } from "react";
import Table from 'react-bootstrap/Table';
import axios from 'axios';
import { BASE_Url } from "../../URL.js/URL";
const Staffperformance_reports = ({formDate,toDate}) => {
  const [allData, setAllData] = useState([]);
  const {storeId, saasId}= JSON.parse(localStorage.getItem('STORE_DATA'));
  useEffect(() => {
    axios.get(`${BASE_Url}/user-master/sales_person_performance/${saasId}/${storeId}/${formDate}/${toDate}`)
      .then((res) => {
        setAllData(res.data.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, [formDate,toDate]);

  return (
    <div className="container">
      <div className="border-1 h-100 w-100 rounded-lg mt-4">
        <div className="table-responsive">
          <Table bordered={false}>
            <thead>
              <tr>
                {/* <th>Staff Name</th>
                <th>Role</th> */}
                <th>Average Customer</th>
                <th>Total Sale</th>
              </tr>
            </thead>
            <tbody>
              {allData?.map((ele) => (
                <tr key={ele.date}>
                
                  <td style={{ borderRight: 'none' }}>{ele.avg_customer}</td>
                  <td style={{ borderRight: 'none' }}>{ele.sales}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </div>
    </div>
  );
}

export default Staffperformance_reports;
