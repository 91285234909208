import React, { useState } from "react";
import { Modal, Button as BootstrapButton } from "react-bootstrap";
import {
  TextField,
  Button as MUIButton,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import axios from "axios";
import { BASE_Url } from "../../URL.js/URL";
import Swal from "sweetalert2";
const AddInventoryItemModal = ({ show, onHide,getAllData }) => {
  const [name, setName] = useState("");
  const [quantity, setQuantity] = useState("0");
  const [cost, setCost] = useState("");
  const [minimumOrderlevel, setMinimumOrderlevel ] = useState("0");
  const [unit, setunit] = useState("");

  const handleAddMaterialDetail = () => {
    const { storeId, saasId } = JSON.parse(localStorage.getItem("STORE_DATA"));

    if (name && cost && quantity) {
      // Make API request
      const apiUrl = `${BASE_Url}/add-inventory-bom`;

      const requestBody = {
        saas_id: saasId,
        store_id: storeId,
        inventory_name: name,
        quantity,
        cost,
        unit: unit,
        received_quantity: "0",
        sold_quantity: "0",
        Minium_Order_Level:minimumOrderlevel
      };

      const headers = {
        "Content-Type": "application/json",
      };

      axios
        .post(apiUrl, requestBody, { headers })
        .then((res) => {
          Swal.fire({
            title: "Item Add Successful",
            icon: "success",
            timer: 1000,
          });
          getAllData(1)
          onHide();
          //Create a new material data object
          setMinimumOrderlevel("");
          setName("");
          setQuantity("");
          setCost("");
        })
        .catch((err) => {
          console.error("API Error:", err);
        });
    }
  };

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Add Raw Material Detail</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <TextField
          type="text"
          value={name}
          placeholder="Item Name"
          onChange={(e) => setName(e.target.value)}
          className="form-control mb-3"
          aria-describedby="nameHelp"
          fullWidth
        />
        <TextField
          type="text"
          value={quantity}
          placeholder="Opening Quantity"
          onChange={(e) => setQuantity(e.target.value)}
          className="form-control mb-3"
          aria-describedby="quantityHelp"
          fullWidth
        />
        <TextField
          type="text"
          value={cost}
          placeholder="Cost per Unit"
          onChange={(e) => setCost(e.target.value)}
          className="form-control mb-3"
          aria-describedby="costHelp"
          fullWidth
        />
        <TextField
          type="text"
          value={minimumOrderlevel}
          placeholder="Minimum Order Level"
          onChange={(e) => setMinimumOrderlevel(e.target.value)}
          className="form-control mb-3"
          aria-describedby="costHelp"
          fullWidth
        />
        <FormControl fullWidth className="form-control mb-3">
          <InputLabel id="demo-simple-select-label">Unit</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            // value={age}
            label="Age"
            onChange={(e) => setunit(e.target.value)}
          >
            <MenuItem value="Kg">KG</MenuItem>
            <MenuItem value="Gm">Gm</MenuItem>
            <MenuItem value="Ml">Ml</MenuItem>
            <MenuItem value="Lt">Lt</MenuItem>
          </Select>
        </FormControl>
        
      </Modal.Body>
      <Modal.Footer>
        <Button className="mb-1" fullWidth variant="outlined" onClick={onHide}>
          Cancel
        </Button>
        <Button fullWidth variant="contained" onClick={handleAddMaterialDetail}>
          Add
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddInventoryItemModal;
