import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import axios from 'axios';
import React, { useRef } from 'react'
import { BASE_Url } from '../../URL.js/URL';
import DownloadComponent from '../CSVDownload/DownloadComponent';
import DownloadPDFButton from '../CSVDownload/DownloadPDFButton';
import { useReactToPrint } from 'react-to-print';
import { Button } from 'react-bootstrap';

const CategoryReport = ({Startdate, toDate}) => {

    //call api and set state with data
    const [allData, setAllData] = React.useState([]);
    const { storeId, saasId } = JSON.parse(localStorage.getItem('STORE_DATA'));
    const fetchData = async () => {
        try {
            const response = await axios.get(`${BASE_Url}/user-master/category_performance/${saasId}/${storeId}/${Startdate}/${toDate}`)
            if (response.data.status) {
                setAllData(response.data.data && response.data.data.length > 0? response.data.data : []);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }

    React.useEffect(() => {
        fetchData();
    }, [Startdate, toDate])

    const Header=[
        { label: "Category Name", key: "name" },
        { label: "Total Amount", key: "net_sale" },
    ]


        //new update code
        const tableRef = useRef(null); // Ref for the table container

        const handleTablePrint = useReactToPrint({
          content: () => tableRef.current,
          documentTitle: 'Sales_Report',
          onBeforePrint: () => console.log("Printing Sales Table..."),
        });
      
  return (
    <div>
        <DownloadComponent headers={Header} data={allData} fileName="CategoryReport"/>
        <Button
          variant="contained"
          className="mx-2"
          style={{background:"rgb(14, 41, 84)",color:"white"}}
       color="primary"
          onClick={handleTablePrint}
        >
          PRINT 
        </Button>
           {/* Reusable PDF Download Button */}
           <DownloadPDFButton
          data={allData}
          headers={Header}
          fileName="Invoice_Report.pdf"
        />
        <Box  ref={tableRef} className="h-100 w-100 rounded-lg">
    <TableContainer component={Paper}>
      <Table className="text-nowrap" aria-label="simple table">
        <TableHead className="bg-dark-purple">
          <TableRow>
            <TableCell className=" text-white">Category Name</TableCell>
            <TableCell className=" text-white">Total Amount</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {allData?.map((ele,index) => (
            <TableRow key={index}>
              <TableCell>{ele.name}</TableCell>
              <TableCell>{ele.net_sale}</TableCell>
              {/* <TableCell>{ele.sum(amount)}</TableCell> */}
              {/* <TableCell>{ele.tax_total}</TableCell>
              <TableCell>{ele.discount}</TableCell> */}
             
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  </Box></div>
  )
}

export default CategoryReport