import React, { useEffect, useState } from "react";
import { Grid, TextField, Button, Card, CardContent, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography, Checkbox, Box, Container, ButtonGroup, CircularProgress, Chip } from '@mui/material';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import "react-datepicker/dist/react-datepicker.css";
import { CardBody, Col,Input, Label } from "reactstrap";
// import { Button, Card, , Table } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
// import { BsArrowLeft } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { BASE_Url } from "../../URL.js/URL";
import Swal from "sweetalert2";
import ImageSelector from "./ImageSelector";
const DigitalMarketing = () => {
  const { storeId, saasId } = JSON.parse(localStorage.getItem("STORE_DATA"));
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [filter, setFilter] = useState('all');
  const [userData, setUserData] = useState();
  const [promotionName, setPromotionName] = useState("");
  const [promotionText, setPromotionText] = useState("");
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [image, setImage] = useState("");
  const [imageName, setImageName] = useState("");
  const [video, setVideo] = useState("");
  const [videoName, setVideoName] = useState("");
  const navigate = useNavigate();


  const handleChange = (e) => {
    const { name, checked } = e.target;
    // console.log(checked);
    if (name === "allSelect") {
      let tempUser = userData.map((el) => {
        return { ...el, isChecked: checked };
      });
      setUserData(tempUser);
    } else {
      const tempUser = userData.map((el) =>
        el.customer_name === name ? { ...el, isChecked: checked } : el
      );
      setUserData(tempUser);
    }
  };


  useEffect(() => {
    // dispatch(handelCustomerListRequest());
  }, []);

  const getCustomers = () => {
    setLoading(true)
    axios
      .get(`${BASE_Url}/dashboard/get-customer-status/${saasId}/${storeId}`)
      .then((res) => {
        if (res.status === 200) {
          // console.log("All Data", res.data.data);
          setUserData(res.data.data);
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  useEffect(() => {
    getCustomers();
  }, []);

 
  const allSelect = () => {
    const selectedUser = userData.filter((el) => el.isChecked === true);
    if (!video) {
      // If any required field is empty, return without updating step
      Swal.fire({
        icon: "warning",
        title: "Please fill all the required details.",
      });
      return;
    }
 if (!video || !imageName ) {
      // If any required field is empty, return without updating step
      Swal.fire({
        icon: "warning",
        title: "Please fill all the required details.",
      });
      return;
    }
    
    selectedUser.forEach((el) => {
      const { storeId, saasId } = JSON.parse(localStorage.getItem("STORE_DATA"));

      const formData = new FormData();
      formData.append("promotional_video", video);
      formData.append("promotional_image", image);
      formData.append("promotional_text", promotionText);
      formData.append("saas_id", saasId);
      formData.append("store_id", storeId);
      formData.append("promotion_name", promotionName);
      formData.append("start_date", startDate.toISOString());
      formData.append("end_date", endDate.toISOString());

      const requestOptions = {
        method: "POST",
        body: formData,
        redirect: "follow",
      };

      fetch(`${BASE_Url}/digital-promotion/create-digital-promotion-master`, requestOptions)
      .then((res) => {
        setVideo('')
        setImageName('')
        promotionText('')
        Swal.fire({
            title: "Promotion Add Successful",
            icon: "success",
            timer: 1000,
          });
    })
        .catch((error) => console.log("error", error));
    });
  };
  const getFilteredCustomers = () => {
    
    switch (filter) {
      case 'active':
        return userData?.active?.map(customer => ({ ...customer, status: 'Active'  }));
      case 'inactive':
        return userData?.inActive?.map(customer => ({ ...customer, status: 'Inactive'}));
      default:
        return [
          ...userData?.active?.map(customer => ({ ...customer, status: 'Active'})),
          ...userData?.inActive?.map(customer => ({ ...customer, status: 'Inactive' })),
        ];
    }
  };

  //select costumer from customers array create function for select all and unselect all customers and single select customer
  const handleSelectCustomer = (e) => {
    try {
      const { name, checked } = e.target;
      const customers = getFilteredCustomers(); // Assuming this gets all customers (active + inactive)
  
      // Update the `isChecked` property for all or specific customers
      let temp;
      if (name === "allSelect") {
        temp = customers.map((el) => ({ ...el, isChecked: checked }));
      } else {
        temp = customers.map((el) =>
          el.customer_name === name ? { ...el, isChecked: checked } : el
        );
      }
  
      // Split the updated `temp` data into `active` and `inactive` arrays
      if(filter== "active"){
        const updatedActive = temp.filter((el) => el.status === "Active");
        setUserData({
          active: updatedActive,
          inActive: userData?.inActive,
        });
      }else if(filter== "inactive"){
        const updatedInactive = temp.filter((el) => el.status === "Inactive");
        setUserData({
          active: userData?.active,
          inActive: updatedInactive,
        });
      }else{
        const updatedActive = temp.filter((el) => el.status === "Active");
        const updatedInactive = temp.filter((el) => el.status === "Inactive");
        setUserData({
          active: updatedActive,
          inActive: updatedInactive,
        });
      }
  
      // Update the state (active and inactive arrays separately)
      
      
      
    } catch (error) {
      console.log(error);
    }
  };
  
  // useEffect(() => {
  //   console.log(userData)
  //   getFilteredCustomers()
  // }, [userData])
  const [images, setImages] = useState([]);
  const [selectedImageIndex, setSelectedImageIndex] = useState(null);
  const [selectedimage, setSelectedImage] = useState(null);

  const handleImageSelect = (index) => (file) => {
    const newImages = [...images];
    newImages[index] = {
      file,
      url: URL.createObjectURL(file)
    };
    setImages(newImages);
  };

  // get uploaded images from api and set to state 
  const getUploadedImages = () => {
      axios
       .get(`${BASE_Url}/digital-promotion/get-all-promo-image/${saasId}/${storeId}`)
       .then((res) => {
          if (res.status === 200) {
            //res.data.data is object with all images so we can not use map 
            const transformedData = Object.values(res.data.data)
            // .filter(url => url !== null) // Exclude null values
            .map(url => ({ file: {}, url , isFrombackend : url ?true: false })); 
            console.log("transformedData", transformedData)
            setImages(transformedData);
            // setImages(res.data.data.map((image, index) => ({ ...image, url: image[`image${index}`] })));
          }
        })
       .catch((err) => {
          console.log(err);
        });
    };
    console.log(images)
  useEffect(() => {
    getUploadedImages();
  }, []);


  const handleImageClick = (index) => {
    if (images[index]) {
      // Toggle selection: if clicking the already selected image, unselect it
      console.log(images[index].file)
      setSelectedImage(images[index].file);
      setSelectedImageIndex(selectedImageIndex === index ? null : index);
    }
  };


    const renderTable = (customers) => {
      if (customers.length === 0) {
        return (
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '268px' }}>
            <Typography variant="h6" color="text.secondary">
              No customers found
            </Typography>
          </Box>
        );
      }
  
      return (
        <TableContainer style={{height:'calc(100vh - 350px)' , overflowY:"auto"}} component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="customers table">
            <TableHead className="bg-dark-purple">
              <TableRow>
                <TableCell className=" text-white flex">

              <Typography variant="h6" component="div" sx={{ margin: 0, padding: 0 }}>
                      All
                    </Typography>
                    <input
                    type="checkbox"
                    className="form-check-input"
                    name="allSelect"
                    // checked={
                      //   userData &&
                      //   userData?.filter((el) => el?.isChecked !== true).length < 1
                      // }
                      onChange={handleSelectCustomer}
                      />
                      </TableCell>
                <TableCell className=" text-white">Customer ID</TableCell>
                <TableCell className=" text-white">Name</TableCell>
                <TableCell className=" text-white">Mobile Number</TableCell>
                <TableCell className=" text-white">Email</TableCell>
                {filter === 'all' && <TableCell className=" text-white">Status</TableCell>}
              </TableRow>
            </TableHead>
            <TableBody>
              {customers.map((customer) => (
                <TableRow key={customer.customer_id}>
                   <TableCell align="center">
                    <input
                        type="checkbox"
                        className="form-check-input"
                        name={customer.customer_name}
                        checked={customer?.isChecked || false}
                        onChange={handleSelectCustomer}
                      />
                    </TableCell>
                  <TableCell>{customer.customer_id}</TableCell>
                  <TableCell>{customer.customer_name || 'N/A'}</TableCell>
                  <TableCell>{customer.mobile_number}</TableCell>
                  <TableCell>{customer.email}</TableCell>
                  {filter === 'all' && (
                    <TableCell>
                      <Chip 
                        label={customer.status} 
                        color={customer.status === 'Active' ? 'success' : 'error'}
                        size="small"
                      />
                    </TableCell>
                  )}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      );
    };

    const UploadImage = async (number)=>{
      try {
        const formData = new FormData();
        formData.append("image", selectedimage);
        const response = await axios.post(`${BASE_Url}/digital-promotion/save-digital-promo-img/${saasId}/${storeId}/${number}`, formData);
        if(response.data.status){
        Swal.fire({
        title: "Image uploaded successfully",
        icon: "success",
        timer: 1000,
        });
        }


      } catch (error) {
        console.log(error);
        
      }
    }

  return (
    <>
      <Grid container justifyContent={"center"} item xs={12}>
       <div
          className="mx-2  mb-5 mt-1"
          style={{
            overflowX: "auto",
            boxShadow: "0 2px 12px rgba(36, 36, 39, 0.12)",
            borderRadius: "0.75rem",
            overflow: "hidden",
            fontFamily: "DM sans-serif",
            fontSize: "1.25rem",
            border: "0 2px 12px rgba(36, 36, 39, .12)",
            padding: "10px",
            height: "fit-content"
          }}
        >
      <div className="flex fw-bold flex-row items-center justify-center mt-2 mx-2"
      
      >
      <div className="d-flex justify-content-center"
      >
          <h3 className="fw-bold">Digital Marketing</h3>
     
        </div>
        
      </div>
      <hr className="m-0"/>
      <div className="mt-3" style={{ height: "70vh", overflowY: "auto"}}> 
      <div className="mt-2  mx-2" style={{ marginBottom: "0%" }}>
      {/* <Grid className="mt-1 mx-2" item xs={12}>
        <Card>
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={12} md={4}>
                <TextField
                  size="small"
                  type="text"
                  fullWidth
                  id="promotion-name"
                  value={promotionName}
                  onChange={(e) => setPromotionName(e.target.value)}
                  label="Promotion Name"
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <DatePicker
                      selected={startDate}
                      onChange={(date) => setStartDate(date)}
                      className="form-control"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <DatePicker
                      selected={endDate}
                      onChange={(date) => setEndDate(date)}
                      className="form-control"
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid> */}
     
      <Grid >

      <div className="mb-8">
          <h2 className="text-lg font-semibold mb-4">Select Images</h2>
          <div className="flex gap-4 flex-wrap">
            {[0, 1, 2, 3].map((index) => (
              <div className="flex flex-col items-center">
              <ImageSelector
                key={index}
                index={index}
                onImageSelect={handleImageSelect(index)}
                imageUrl={images[index]?.url}
                isSelected={selectedImageIndex === index}
                onSelect={() => handleImageClick(index)}
                onUpload={UploadImage}
                />
               {selectedImageIndex === index&& 
               <>
               {images[index]?.isFrombackend ?""
            //    <Button
            // className="mt-2 mx-2"
            //   variant="contained"
            //   color="success"
            //   // onClick={() => UploadImage(index+1)}
            // >
            //   Edit Image
            // </Button>
            :<Button
            className="mt-2 mx-2"
              variant="contained"
              color="success"
              onClick={() => UploadImage(index+1)}
            >
              Upload
            </Button>}
            </>}
                </div>
            ))}
          </div>
          
          {/* {images && images?.some(img => img) && (
            <p className="mt-4 text-sm text-gray-600">
              Click on an uploaded image to select it
            </p>
          )} */}
        </div>

      {/* <Grid container className="flex justify-center " item xs={12} md={3}>
        <Card>
          <CardContent>
          <Label>Upload Promotional Video</Label>
            <input
              type="file"
              accept="video/*"
              placeholder="Upload Promotional Video"
              id="movie"
              onChange={(e) => {
                setVideo(e.target.files[0]);
                setVideoName(e.target.files[0].name);
              }}
            />
            {videoName && <div>{videoName}</div>}
            
          </CardContent>
        </Card>
      </Grid> */}
      {/* <Grid container className="flex justify-center " item xs={12} md={3}>
        <Card>
          <CardContent>
          <Label>Upload Promotional Image</Label>

            <input
              type="file"
              accept="image/png, image/jpeg"
              placeholder="Upload Promotional Image"
              id="poster"
              onChange={(e) => {
                setImage(e.target.files[0]);
                setImageName(e.target.files[0].name);
              }}
            />
            {imageName && <div>{imageName}</div>}
           
          </CardContent>
        </Card>
      </Grid> */}
      <Grid  className="mx-2" >
        <Card>
          <CardContent >
           
          <Label>Enter Text Message</Label>
            <TextField
              size="small"
              type="textarea"
              fullWidth
              id="promotion-text"
              value={promotionText}
              onChange={(e) => setPromotionText(e.target.value)}
              label="Message"
              multiline
              // rows={5}
            />
            <Button
            className="mt-2"
              variant="contained"
              color="primary"
            >
              Send SMS Message
            </Button>
            <Button
            className="mt-2 mx-2"
              variant="contained"
              color="success"
            >
              Send Whatsapp Message
            </Button>
          </CardContent>
        </Card>
      </Grid>
      </Grid>

      <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Box sx={{ mb: 4 }}>
        <Typography variant="h4" component="h1" gutterBottom>
          Customer List
        </Typography>
        <ButtonGroup variant="contained" aria-label="filter button group" sx={{ mb: 2 }}>
          <Button 
            onClick={() => setFilter('all')}
            color={filter === 'all' ? 'primary' : 'inherit'}
          >
            All
          </Button>
          <Button 
            onClick={() => setFilter('active')}
            color={filter === 'active' ? 'primary' : 'inherit'}
          >
            Active
          </Button>
          <Button 
            onClick={() => setFilter('inactive')}
            color={filter === 'inactive' ? 'primary' : 'inherit'}
          >
            Inactive
          </Button>
        </ButtonGroup>
        
        <Box sx={{ display: 'flex', justifyContent: 'space-around' }}>
          <Typography variant="h6" color="text.secondary">
            Total Active Customers: {userData?.active?.length || 0}
          </Typography>
          <Typography variant="h6" color="text.secondary">
            Total Inactive Customers: {userData?.inActive?.length || 0}
          </Typography>
        </Box>
      

      </Box>
      {loading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <CircularProgress />
        </Box>
      ) : (
        renderTable(getFilteredCustomers())
      )}
    </Container>

      {/* <Grid item xs={12} sx={{ mt: 3, }}>
      <Card>
        <CardContent>
          <TableContainer component={Paper} >
            <Table sx={{ minWidth: 650 }} aria-label="user table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">
                    <Typography variant="h6" component="div" sx={{ margin: 0, padding: 0 }}>
                      All
                    </Typography>
                    <input
                    type="checkbox"
                    className="form-check-input"
                    name="allSelect"
                    checked={
                      userData &&
                      userData.filter((el) => el?.isChecked !== true).length < 1
                    }
                    onChange={handleChange}
                  />
                  </TableCell>
                  <TableCell align="center">Name</TableCell>
                  <TableCell align="center">Mobile No.</TableCell>
                  <TableCell align="center">Whatsapp</TableCell>
                  <TableCell align="center">SMS</TableCell>
                  <TableCell align="center">Email</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {userData && userData.map((user, index) => (
                  <TableRow key={index}>
                    <TableCell align="center">
                    <input
                        type="checkbox"
                        className="form-check-input"
                        name={user.customer_name}
                        checked={user?.isChecked || false}
                        onChange={handleChange}
                      />
                    </TableCell>
                    <TableCell align="center">{user.customer_name}</TableCell>
                    <TableCell align="center">{user.mobile_number}</TableCell>
                    <TableCell align="center">{user.mobile_number}</TableCell>
                    <TableCell align="center">{user.mobile_number}</TableCell>
                    <TableCell align="center">{user.email}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <hr className="m-0" />
          <Grid container justifyContent="center" sx={{ mb: 2 }}>
            <Button 
              variant="contained" 
              sx={{ 
                backgroundColor: '#0E2954', 
                color: 'dark', 
                fontWeight: 'bold', 
                mt: 4 
              }}
              onClick={() => allSelect(userData)}
            >
              Send
            </Button>
          </Grid>
        </CardContent>
      </Card>
    </Grid> */}

</div>

</div></div>
</Grid>
    </>
  );
}

export default DigitalMarketing;
