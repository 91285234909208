import React, { useState, useEffect } from 'react';
import { Container } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import { Grid, TextField, Button, InputLabel, Select, MenuItem, FormControl } from '@mui/material';
import { useDispatch } from 'react-redux';
import axios from 'axios';
import { BASE_Url } from '../../URL.js/URL';
import { linkCustomer } from '../../redux/slices/selectOrdersSlice';
import Swal from "sweetalert2";

function LinkCustomer(props) {
  const dispatch = useDispatch();
  const { storeId, saasId } = JSON.parse(localStorage.getItem("STORE_DATA"));
  const [selectedCustomer, setSelectedCustomer] = useState('');
  const [categories, setCategories] = useState([]);

  const handleCloseModal = () => {
    props.onHide();
  };

  const fetchCategories = async () => {
    try {
      const response = await axios.get(`${BASE_Url}/user-master/get-user-details/${saasId}/${storeId}`);
      setCategories(response.data.data);
    } catch (error) {
      console.error('Error fetching categories:', error);
    }
  };

  useEffect(() => {
    fetchCategories();
  }, [saasId, storeId]);

  const handleSave = () => {
    const customer = categories.find(category => category.mobile_number === selectedCustomer);
    if (customer) {
      dispatch(linkCustomer(customer));
      Swal.fire("Customer Linked Successfully");
      handleCloseModal();
    } else {
      Swal.fire("Failed to Link Customer");
    }
  };

 

  return (
    <Modal
      {...props}
      onHide={handleCloseModal}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header className="container" closeButton>
        <Modal.Title>Link Customer</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <FormControl fullWidth>
                <InputLabel id="linkCustomerLabel">Link Customer</InputLabel>
                <Select
                  id="linkCustomer"
                  label="Link Customer"
                  value={selectedCustomer}
                  onChange={(e) => setSelectedCustomer(e.target.value)}
                >
                  {categories.map((option) => (
                    <MenuItem key={option.customer_id} value={option.mobile_number}>
                      {option.mobile_number}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Button className='mx-2' variant="contained" color="primary" onClick={handleSave}>
          Save
        </Button>
        <Button variant="contained" color="secondary" onClick={handleCloseModal}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default LinkCustomer;
