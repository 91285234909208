import React, { useEffect, useState } from "react";

import axios from "axios";
import Box from "@mui/material/Box";
import { BsArrowLeft } from "react-icons/bs";
import Modal from "@mui/material/Modal";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { BASE_Url } from "../URL.js/URL";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

//
function NewOrders() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // for table

  // **  // creat customer post api********

  useEffect(() => {
    const apiUrl = `${BASE_Url}/order/create`;

    axios
      .post(apiUrl, {
        createCustomer: "createCustomer",
      })
      .then((response) => {
        console.log(response);

        // dispatch(addCustomer(response.data.data));
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  // **********************************
  // modal

  // const handleOpen = () => setOpen(true);
  // const handleClose = () => setOpen(false);
  //view table
  const [dinnerArray, setDinnerArray] = useState([]);
  const [dinnerArray1, setDinnerArray1] = useState([]);
  const [dinnerArray2, setDinnerArray2] = useState([]);

  // create table
  const [category, setcategory] = useState("");
  const [table, setTable] = useState([]);
  //

  // view table
  const getTables = () => {
    const { storeId, saasId } = JSON.parse(localStorage.getItem("STORE_DATA"));
    const apiUrl = `${BASE_Url}/tableview/table_view/${saasId}/${storeId}`;

    // Make the API call
    axios
      .get(apiUrl)
      .then((response) => {
        console.log(response);
        setTable(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    getTables();
  }, []);
  // ****

  // create Table
  // const createTable = () => {
  //   const apiUrl = "http://3.111.70.84:8093/api/v1/tableview/createtable";

  //   // Make the API call
  //   axios
  //     .post(apiUrl, {
  //       CATEGORY: category,
  //       TABLE_NUMBER: table,
  //       SAAS_ID: "2",
  //       STORE_ID: "1",
  //     })
  //     .then((response) => {
  //       console.log(response);
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // };
  //

  const handleNavigate = (el) => {
    if (el.status === "Reserved") {
      navigate(`/NewOrders`);
    } else if (el.status === "Occupied") {
      navigate(`/NewOrders`);
    } else if (el.status === "Empty") {
      navigate(`/SelectOrders/${el.table_name}/${el.table_id}`);
    }
  };

  //Check Store Type 
  const jsonStoreString = localStorage.getItem("STORE_DATA");
  const parsedstoreData = JSON.parse(jsonStoreString || '{}'); // Fallback to an empty object if null
  const { qsr } = parsedstoreData;
  const RType =  qsr;   
  if(RType){
    return (
      <div className="flex flex-col items-center justify-center">
        <h2 className="text-2xl font-bold text-center">QSR Mode</h2>
        <p className="text-sm font-medium text-center">
          QSR Mode is not available for this store type.
        </p>
      </div>
    );
  }else{
  return (
    <>
    <div className="sticky top-0 z-20 bg-white shadow-md">
  
  <div className="flex  w-full mt-1 justify-around fw-bold "
       style={{
        boxShadow: '0 2px 12px rgba(36, 36, 39, 0.12)',
        borderRadius: '0.75rem',
        overflow: 'hidden',
        fontFamily: "DM sans-serif",
        fontSize: "1.125rem",
        border: "0 2px 12px rgba(36, 36, 39, .12)", padding: "20px"
       
      }}>
      <div className="sticky top-0 z-10 ">
    <div className="flex flex-row items-center justify-start ">
      <BsArrowLeft
        size={25}
        className="cursor-pointer mr-7"
        onClick={() => navigate("/")}
      />

      <h3 className="text-start p- mt-2 font-medium text-xl font-['inter']">
        New Orders
      </h3>
    </div>
  </div>
        <div className="flex items-center justify-end items-center ">
          <p className="grey m-0 p-0"></p>
          &nbsp;
          <p className="text-lg m-0 p-0">Empty</p>
        </div>
        <div className="flex items-center justify-center">
          <p className="yellow m-0 p-0 text-lg"></p>
          &nbsp;
          <p className="text-lg m-0 p-0">Occupied</p>
        </div>
        <div className="flex items-center justify-center">
          <p className="green m-0 p-0"></p>
          &nbsp;
          <p className="text-lg m-0 p-0">Reserved</p>
        </div>
      </div>

</div>

      <div className="flex flex-col " style={{ height: "100vh" }}>
      <div className="flex justify-between  gap-15 w-full">
          <div className="left-2 flex-col items-center justify-center h-6 top-80 w-[200]  text-blue-500">
            {table.map((el) => (
              <div className="flex flex-col my-3"style={{marginLeft:"20px"}}>
                <div className="my-2 fw-bold d-flex justify-center  text-white "
                  style={{
                    borderRadius: '8px',
                    background: '#0E2954',
                    boxShadow: '0px 0px 4px 3px rgba(0, 0, 0, 0.25)',
                    width: '90px',
                    height: '30px',
                    flexShrink: 0,
                    fontFamily: "Inter",
                    fontStyle: "normal",
                    fontWeight: "700",
                    lineHeight: "normal" 
                  }}><button> {el.category}</button></div>

                <div className="flex flex-row flex-wrap">
                  {el.table_list.map((el1) => (
                    <div
                      key={el1.table_id}
                      style={{
                        height: "65px",
                        border: `4px solid ${
                          el1.status === "Occupied"
                            ? "#d97706" // Change border color for Occupied status
                            : el1.status === "Available"
                            ? "#65a30d" // Change border color for Available status
                            : "rgb(117, 116, 114)" // Change border color for other statuses
                        }`,
                        position: "relative",
                        borderRadius: 70,
                        color: "#000",
                        fontFamily: "Roboto",
                        fontSize: "15px",
                        fontStyle: "normal",
                        fontWeight: "700",
                        lineHeight: "normal"
 
                        // width: "9%", // Adjusted width for small screens
                      }}
                      className="flex flex-col text-black items-center p-3 sm:p-6 text-base sm:text-lg justify-center bg-zinc-100 m-2"
                      onClick={() => handleNavigate(el1)}
                    >
                      {el1.table_name}
                      <div
                        // style={{
                        //   height: "20px", // Adjusted height for small screens
                        //   width: "100%",
                        //   borderBottomLeftRadius: 20,
                        //   borderBottomRightRadius: 20,
                        //   position: "absolute",
                        //   bottom: "0",
                        // }}
                        className={`${el1.status === "Occupied"
                            ? "bg-amber-600"
                            : el1.status === "Available"
                              ? "bg-lime-600"
                              : "bg-zinc-500"
                          }`}
                      ></div>
                    </div>
                  ))}
                </div>

              </div>
            ))}
          </div>


        </div>

        {/* <div className="left-2 flex-col items-center justify-center h-6 top-80 w-[200] mt-1 text-blue-500">
          {table.map((el) => (
            <div className="flex flex-col my-3">
              <div className="my-4 text-2xl text-dark ">{el.category}</div>

              <div className="flex flex-row" style={{ flexWrap: "wrap" }}>
                {el.table_list.map((el1) => (
                  <div
                    style={{
                      border: "2px dashed #AEB6BF",
                      position: "relative",
                      borderRadius: 20,
                    }}
                    className="flex flex-col text-black mx-4 items-center p-5 text-lg justify-center bg-zinc-100 m-2"
                    onClick={() => handleNavigate(el1)}
                  >
                    {el1.table_name}
                    <div
                      style={{
                        height: "20px",
                        width: "100%",
                        borderBottomLeftRadius: 20,
                        borderBottomRightRadius: 20,
                        width: "100%",
                        position: "absolute",
                        bottom: "0",
                      }}
                      className={`${
                        el1.status === "Occupied"
                          ? "bg-amber-600"
                          : el1.status === "Available"
                          ? "bg-lime-600"
                          : "bg-zinc-500"
                      }`}
                    ></div>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div> */}
      </div>
      <Modal
        // open={open}
        // onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={style}
          className=" bg-light-grey border w-1/4 rounded  text flex flex-col text-black m-4 p-4"
        >
          <label for="Category">TABLE NUMBER</label>
          <input
            value={table}
            // onChange={(e) => setTable(e.target.value)}
            // // onChange={(e) => setTable(e.target.value)}
            className="border border-black rounded py-1"
            type="number"
          ></input>
          <button
            // onClick={createTable}
            className="bg-dark-purple mt-4 justify-center flex rounded  sm:px-4 sm:py-.5 lg:px-6 lg:py-2.5 text-xs font-medium uppercase leading-normal text-white shadow-md transition duration-150 ease-in-out hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0"
          >
            ADD TABLES
          </button>
        </Box>
      </Modal>
    </>
  );
}
}

export default NewOrders;
