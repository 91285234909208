import axios from "axios";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { BASE_Url } from "../../URL.js/URL";
import Swal from "sweetalert2";
const Register = () => {
  const [userName, setUserName] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("");
  const [password, setPassword] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    // axios
    //   .post(`${BASE_Url}/auth/user-registration`, {
    //     user_name: userName,
    //     password: password,
    //     store_name: "Fresh Mart",
    //     store_id: "9876123",
    //     saas_id: "5432211",
    //     register_id: "PQRSTUWV",
    //     city: "Newtown",
    //     special_price: "789.01",
    //     category_name: "Groceries",
    //     state: "Newville",
    //     country: "Novania",
    //     brand_logo: "https://freshmart.com/logo.png",
    //     taxable: true,
    //     gst_code: "GST54321",
    //     hsn_code: "HSN8765",
    //     store_type: "Supermarket",
    //     address: "456 Fresh Street",
    //   })
    //   .then((res) => {
    //     Swal.fire({
    //         title: "Register Successful",
    //         icon: "success",
    //         timer: 1000,
    //       });
    // })
    //   .catch((err) => console.log("ERR", err));
  };

  return (
    <section className="text-gray-600 body-font">
      <div className="container px-5  mx-auto flex items-center justify-center">
        <form
          onSubmit={handleSubmit}
          className="lg:w-1/2 md:w-full bg-blue-950 rounded-lg p-8 flex flex-col  w-full mt-10 md:mt-0 relative z-10 shadow-md"
        >
          <div className="flex items-center justify-center text-lg">
            <h2 className="text-gray-100 text-lg mb-1 font-medium title-font">
              Register
            </h2>
          </div>
          <div className="relative mb-4">
            <label htmlFor="email" className="leading-7 text-sm text-gray-100">
              User Name
            </label>
            <input
              type="text"
              id="email"
              value={userName}
              onChange={(e) => setUserName(e.target.value)}
              name="email"
              className="w-full bg-white rounded border border-gray-300  focus:ring-2 focus:ring-pink-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
            />
          </div>
          <div className="relative mb-4">
            <label
              htmlFor="password"
              className="leading-7 text-sm text-gray-100"
            >
              Password
            </label>
            <input
              type="password"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              name="password"
              className="w-full bg-white rounded border border-gray-300  focus:ring-2 focus:ring-pink-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
            />
          </div>
          <div className="relative mb-4">
            <label
              htmlFor="password"
              className="leading-7 text-sm text-gray-100"
            >
              Confirm Password
            </label>
            <input
              type="password"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              name="password"
              className="w-full bg-white rounded border border-gray-300  focus:ring-2 focus:ring-pink-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
            />
          </div>
          <div className="relative mb-4">
            <label htmlFor="email" className="leading-7 text-sm text-gray-100">
              City
            </label>
            <input
              type="text"
              id="email"
              value={city}
              onChange={(e) => setCity(e.target.value)}
              name="email"
              className="w-full bg-white rounded border border-gray-300  focus:ring-2 focus:ring-pink-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
            />
          </div>
          <div className="relative mb-4">
            <label htmlFor="email" className="leading-7 text-sm text-gray-100">
              State
            </label>
            <input
              type="text"
              id="email"
              value={state}
              onChange={(e) => setState(e.target.value)}
              name="email"
              className="w-full bg-white rounded border border-gray-300  focus:ring-2 focus:ring-pink-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
            />
          </div>
          <div className="relative mb-4">
            <label htmlFor="email" className="leading-7 text-sm text-gray-100">
              Country
            </label>
            <input
              type="text"
              id="email"
              value={country}
              onChange={(e) => setCountry(e.target.value)}
              name="email"
              className="w-full bg-white rounded border border-gray-300  focus:ring-2 focus:ring-pink-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
            />
          </div>

          <button
            type="submit"
            className="text-white bg-violet-600 border-0 py-2 px-6 focus:outline-none  rounded text-lg"
          >
            Register
          </button>
          <p className="text-xs text-gray-100 mt-3">
            Don’t have an account?
            <Link to="/login"> Login</Link>
          </p>
        </form>
      </div>
    </section>
  );
};

export default Register;
