import React, { useState } from "react";
import { AiOutlineCaretDown } from "react-icons/ai";
import { BsArrowLeft } from "react-icons/bs";
import Form from "react-bootstrap/Form";
import Grid from '@mui/material/Grid';
import { useNavigate } from "react-router-dom";
import DailyStore_reports from "./DailyStore_reports";
import Reports from "./Reports";
import ItemReport from "./ItemReport";
import OrderReport from "./OrderReport";
import Staffperformance_reports from "./Staffperformance_reports";
import Inventory_reports from "./Inventory_reports";
import CRM_reports from "./CRM_reports";
import Allinvoiced from "./Allinvoiced";
import { styled } from '@mui/material/styles';
import { 
  List, 
  ListItem, 
  ListItemText, 
  Typography, 
  Box, 
  Paper 
} from '@mui/material';
import CustomerList from "./CustomerList";
import Npsdashbooard from "./Npsdashbooard";
import PaymentReport from "./PaymentReport";
import CategoryReport from "./CategoryReport";
import DueAmountReport from "./DueAmountReport";
import KotReport from "./KotReport";
import BahikhataReport from "./BahikhataReport";
import ExpeseReport from "./ExpeseReport";
const StyledListItem = styled(ListItem)(({ theme, selected }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(1.5, 2),
  position: 'relative', // Ensure the ::before pseudo-element is positioned relative to the list item
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
  },
  '&::before': {
    display: selected ? 'block' : 'none',
    content: '""',
    width: 4,
    height: '100%',
    backgroundColor: selected ? theme.palette.success.main : theme.palette.success.light,
    position: 'absolute', // Position the line absolutely
    left: 0, // Align it to the left side
  },
  backgroundColor: selected ? theme.palette.action.selected : 'transparent',
}));
const Reports_names = () => {
  const navigate = useNavigate();
  const [selectedOption, setSelectedOption] = useState("total");
  const [formDate, setFormDate] = useState(new Date().toISOString().split('T')[0]);
  const [toDate, setToDate] = useState(new Date().toISOString().split('T')[0]);

  const handleSelectChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const handleFormDateChange = (event) => {
    setFormDate(event.target.value);
  };

  const handleToDateChange = (event) => {
    setToDate(event.target.value);
  };
  //make a condition to check user_type is Admin then return true else false
  const jsonString = localStorage.getItem("USER_DATA");
  const parsedData = JSON.parse(jsonString || '{}'); // Fallback to an empty object if null
  const { userType } = parsedData;
  const isAdmin =  userType =="ADMIN"; 

  const reportNames = [
    { name: "All Sales Invoiced", value: "total" ,isActive: true },
    { name: "Daily Sales Reports", value: "daily" ,isActive: isAdmin},
    { name: "Order Related Reports", value: "order" ,isActive: isAdmin},
    { name: "Item Related Reports", value: "item" ,isActive: isAdmin},
    { name: "Table Performance Reports", value: "table" ,isActive: isAdmin},
    { name: "Payment Reports", value: "Payment" ,isActive: isAdmin},
    { name: "Due Amount Reports", value: "DueAmount" ,isActive: isAdmin},
    { name: "KOT Reports", value: "KOTReport" ,isActive: isAdmin},
    { name: "Category Reports", value: "Category" ,isActive: isAdmin},
    { name: "Staff Performance Reports", value: "staff" ,isActive: isAdmin},
    { name: "Inventory Reports", value: "inventory" ,isActive: isAdmin},
    { name: "Customer List", value: "customer" ,isActive: isAdmin},
    { name: "NPS Dashboard", value: "nps" ,isActive: isAdmin},
    // { name: "Expense", value: "Expense" ,isActive: isAdmin},
    // { name: "Bahikhata", value: "Bahikhata" ,isActive: isAdmin},
    // { name: "CRM Reports", value: "crm" }
  ]
  // const reports = [
  //   'Sales Report',
  //   'Todays Report',
  //   'Items Report',
  //   'Payment Report',
  //   'Order Report',
  //   'Category-wise Report',
  //   'All Sales Invoiced'
  // ];

  //Create Function to hide todate input on selected option
  const hideToDate = (option) => {
    if(option === "total" || option === "order"|| option === "customer" || option === "nps"){
      return false;
    }
    return true;
  }
  
  const hidebothdates = (option) => {
    if(option === "nps"|| option === "customer" || option === "inventory"  ){
      return false;
    }
    return true;
  }

  return (
    <Box sx={{ display: 'flex', height: '100vh', bgcolor: 'grey.100' }}>
   <Paper 
  elevation={3} 
  sx={{ 
    width: 280, 
    maxHeight: 'calc(100vh - 110px)', // Adjust to your desired max height based on viewport height
    maxWidth: 'calc(100vh - 400px)',  // Ensure the form's width doesn't exceed the available space
    overflowY: 'auto', 
  }}
>
  <Box sx={{ p: 2, borderBottom: 1, borderColor: 'divider' }}>
    <Typography variant="h6" component="h2">
      Reports
    </Typography>
  </Box>
  <List disablePadding>
    {reportNames.filter((item) => item.isActive).map((report) => (
      <>
      
      <StyledListItem
        key={report.value}
        selected={selectedOption === report.value}
        onClick={() => setSelectedOption(report.value)}
        button
        >
        <ListItemText primary={report.name} />
      </StyledListItem>
        </>
    ))}
  </List>
</Paper>

  {/* <div
            style={{
              overflowX: "auto",
              boxShadow: "0 2px 12px rgba(36, 36, 39, 0.12)",
              borderRadius: "0.75rem",
              overflow: "hidden",
              fontFamily: "DM Sans, sans-serif",
              fontSize: "1.125rem",
              border: "0 2px 12px rgba(36, 36, 39, .12)",
              padding: "10px",
            }}
          > */}
            <Box className="gap-3" sx={{ flexGrow: 1, p: 3 }}>
                <>
              {hidebothdates(selectedOption) &&<Box className="gap-2 flex ">
            <Grid item xs={12} sm={3}>
              <Form.Group controlId="formDate">
                <Form.Control
                  type="date"
                  className="font-bold form-control"
                  style={{ width: "100%" }}
                  onChange={handleFormDateChange}
                  value={formDate}
                />
              </Form.Group>
            </Grid>
           {hideToDate(selectedOption) && <Grid item xs={12} sm={3}>
              <Form.Group controlId="toDate">
                <Form.Control
                  type="date"
                  className="font-bold form-control"
                  style={{ width: "100%" }}
                  onChange={handleToDateChange}
                  value={toDate}
                />
              </Form.Group>
            </Grid>}
              </Box>}
          </>
        
            {selectedOption === "total" && <Allinvoiced formDate={formDate} toDate={toDate}/>}
            {selectedOption === "daily" && <DailyStore_reports formDate={formDate} toDate={toDate} />}
            {selectedOption === "table" && <Reports formDate={formDate} toDate={toDate} />}
            {selectedOption === "item" && <ItemReport formDate={formDate} toDate={toDate} />}
            {selectedOption === "order" && <OrderReport formDate={formDate} toDate={toDate} />}
            {selectedOption === "staff" && <Staffperformance_reports formDate={formDate} toDate={toDate} />}
            {selectedOption === "inventory" && <Inventory_reports formDate={formDate} toDate={toDate} />}
            {selectedOption === "customer" && <CustomerList formDate={formDate} toDate={toDate} />}
            {selectedOption === "nps" && <Npsdashbooard />}
            {selectedOption === "Payment" && <PaymentReport  startdate={formDate} endDate={toDate} />}
            {selectedOption === "Category" && <CategoryReport  Startdate={formDate} toDate={toDate} />}
            {selectedOption === "DueAmount" && <DueAmountReport  Startdate={formDate} toDate={toDate} />}
            {selectedOption === "KOTReport" && <KotReport  start={formDate} todate={toDate} />}
            {/* {selectedOption === "Bahikhata" && <BahikhataReport  startdate={formDate} todate={toDate} />}
            {selectedOption === "Expense" && <ExpeseReport  formDate={formDate} toDate={toDate} />} */}
            
            {/* {selectedOption === "crm" && <CRM_reports formDate={formDate} toDate={toDate} />} */}
            </Box>
          {/* </div> */}
</Box>
    // <div style={{ padding: "20px" }}>
    //   <Grid container spacing={2}>
    //     <Grid item xs={12} style={{ display: 'flex', alignItems: 'center' }}>
    //       <h4 style={{ fontSize: '1.4rem', fontWeight: 'bold' }}>{reportNames[selectedOption]}</h4>
    //     </Grid>
    //     {selectedOption !== "inventory" && (
    //       <>
    //         <Grid item xs={12} sm={3}>
    //           <Form.Group controlId="formDate">
    //             <Form.Control
    //               type="date"
    //               className="font-bold form-control"
    //               style={{ width: "100%" }}
    //               onChange={handleFormDateChange}
    //               value={formDate}
    //             />
    //           </Form.Group>
    //         </Grid>
    //        {selectedOption !== "total" && <Grid item xs={12} sm={3}>
    //           <Form.Group controlId="toDate">
    //             <Form.Control
    //               type="date"
    //               className="font-bold form-control"
    //               style={{ width: "100%" }}
    //               onChange={handleToDateChange}
    //               value={toDate}
    //             />
    //           </Form.Group>
    //         </Grid>}
    //       </>
    //     )}
    //     <Grid item xs={12} sm={4}>
    //       <Form.Select
    //         aria-label="Select a Report"
    //         onChange={handleSelectChange}
    //         value={selectedOption}
    //         className="form-select"
    //         style={{ width: "100%" }}
    //       >
    //       <option value="total"> All Sales Invoiced</option>
    //         <option value="daily">Daily Sales Reports</option>
    //         <option value="order">Order Related Reports</option>
    //         <option value="item">Item Related Reports</option>
    //         <option value="table">Table Performance Reports</option>
    //         {/* <option value="category">Category Wise Reports</option> */}
    //         <option value="staff">Staff Performance Reports</option>
    //         <option value="inventory">Inventory Reports</option>
    //         {/* <option value="crm">CRM Reports</option> */}
    //       </Form.Select>
    //     </Grid>
    //     <Grid item xs={12}>
    //       <div
    //         style={{
    //           overflowX: "auto",
    //           boxShadow: "0 2px 12px rgba(36, 36, 39, 0.12)",
    //           borderRadius: "0.75rem",
    //           overflow: "hidden",
    //           fontFamily: "DM Sans, sans-serif",
    //           fontSize: "1.125rem",
    //           border: "0 2px 12px rgba(36, 36, 39, .12)",
    //           padding: "10px",
    //         }}
    //       >
    //         {selectedOption === "total" && <Allinvoiced formDate={formDate} toDate={toDate}/>}
    //         {selectedOption === "daily" && <DailyStore_reports formDate={formDate} toDate={toDate} />}
    //         {selectedOption === "table" && <Reports formDate={formDate} toDate={toDate} />}
    //         {selectedOption === "item" && <ItemReport formDate={formDate} toDate={toDate} />}
    //         {selectedOption === "order" && <OrderReport formDate={formDate} toDate={toDate} />}
    //         {selectedOption === "staff" && <Staffperformance_reports formDate={formDate} toDate={toDate} />}
    //         {selectedOption === "inventory" && <Inventory_reports formDate={formDate} toDate={toDate} />}
    //         {/* {selectedOption === "crm" && <CRM_reports formDate={formDate} toDate={toDate} />} */}
    //       </div>
    //     </Grid>
    //   </Grid>
    // </div>
  );
};

export default Reports_names;
