import { useEffect, useState } from 'react';
import { ArrowBack } from '@mui/icons-material';
import {
  Box,
  Button,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  FormControlLabel,
  RadioGroup,
  Radio,
  Typography,
  IconButton,
  Grid
} from '@mui/material';
import { AiOutlinePlusCircle } from 'react-icons/ai';

const Expense = ({activeTab}) => {
    const [category, setCategory] = useState('');
    const [date, setDate] = useState('');
    const [paymentMode, setPaymentMode] = useState('');
    const [item, setItem] = useState('');
    const [notes, setNotes] = useState('');
    const [isMounted, setIsMounted] = useState(false);
    const [additemodal, setAddItemModal] = useState(false);
    const handleSubmit = (e) => {
      e.preventDefault();
      console.log({ category, date, paymentMode, item, notes });
    };
    useEffect(() => {
        if (activeTab === 'Expense' ) {
          setIsMounted(true);
        }
      }, [activeTab]);
    if (!isMounted) {
        return null;
      }
  return (
    <div>  <Box
      sx={{
        minHeight: '8vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        bgcolor: 'amber.50',
        p: { xs: 2, md: 4 },
        overflow: 'auto' // Enable scrolling
      }}
    >
      <Box
        sx={{
          maxWidth: { xs: '100%', md: 500 },
          width: '100%',
          maxHeight: '70vh', // Limit the form's height to ensure space for scrolling
          overflowY: 'auto',
          boxShadow: 3,
          borderRadius: 2,
          p: 4,
          bgcolor: 'background.paper'
        }}
      >
        <Typography variant="h5" component="h1" sx={{ mb: 4, display: 'flex', alignItems: 'center' }}>
          <IconButton>
            <ArrowBack />
          </IconButton>
          Create Expense
        </Typography>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormControl fullWidth required>
                <InputLabel id="category-label">Select Category</InputLabel>
                <Select
                  labelId="category-label"
                  id="category"
                  value={category}
                  onChange={(e) => setCategory(e.target.value)}
                  label="Select Category"
                >
                  <MenuItem value="">
                    <em>Select Category</em>
                  </MenuItem>
                  <MenuItem value="main">
                    <em>Main</em>
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                required
                label="Expense Date"
                type="date"
                id="date"
                value={date}
                onChange={(e) => setDate(e.target.value)}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>

            <Grid item xs={12}>
              <FormControl component="fieldset" fullWidth>
                <Typography variant="body1">Payment Mode</Typography>
                <RadioGroup
                  row
                  value={paymentMode}
                  onChange={(e) => setPaymentMode(e.target.value)}
                >
                  {['Online', 'Cheque', 'Cash'].map((mode) => (
                    <FormControlLabel
                      key={mode}
                      value={mode}
                      control={<Radio />}
                      label={mode}
                    />
                  ))}
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid item xs={12}>
            <div
            
                style={{ width: "100%" }}
                onClick={() => {
                    setAddItemModal(!additemodal);
                }}
              >
                <button
                  className="btn btn-outline-primary mt-3 border"
                  style={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: "30px",
                    backgroundColor: "white",
                    color: "silver",
                    border: "aliceblue"
                  }}
                >
                  <AiOutlinePlusCircle />
                  Add Item (Optional)
                </button>
              </div>
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                required
                label="Expense Notes"
                id="notes"
                value={notes}
                onChange={(e) => setNotes(e.target.value)}
                multiline
                rows={3}
              />
            </Grid>

            <Grid item xs={12}>
              <Button
                fullWidth
                type="submit"
                variant="contained"
                color="primary"
              >
                Save
              </Button>
            </Grid>

            <Grid item xs={12}>
              {/* <Button
                fullWidth
                variant="outlined"
                color="primary"
              >
                Cancel
              </Button> */}
            </Grid>
          </Grid>
        </form>
      </Box>
    </Box></div>
  )
}

export default Expense