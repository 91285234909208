import { useEffect, useState } from "react";
import { ArrowBack } from "@mui/icons-material";
import {
  Box,
  Button,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  FormControlLabel,
  RadioGroup,
  Radio,
  Typography,
  IconButton,
  Grid,
} from "@mui/material";
import { AiOutlinePlusCircle } from "react-icons/ai";
import AddCatgorymodal from "./AddCatgorymodal";
import axios from "axios";
import { BASE_Url } from "../../URL.js/URL";
import Swal from "sweetalert2";
import EditCategory from "./EditCategory";

const Expense = ({ activeTab }) => {
  const [category, setCategory] = useState("");
  const [date, setDate] = useState("1/01/2024");
  const [paymentMode, setPaymentMode] = useState("");
  const [amount, setAmount] = useState("");
  const [notes, setNotes] = useState("");
  const [isMounted, setIsMounted] = useState(false);
  const [CData, setCDData] = useState([]);
  const [additemodal, setAddItemModal] = useState(false);
  const { storeId, saasId } = JSON.parse(localStorage.getItem("STORE_DATA"));
  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = {
      category_name: category,
      expense_date: date,
      payment_mode: paymentMode,
      amount: amount,
      add_expenses: [],
      saas_id:saasId,
    };
    try {
      const response = await axios.post(
        `${BASE_Url}/expense/create-expenses`,
        data
      );
      if (response.data.status) {
        Swal.fire({
          title: "Expense Created Successfully",
          icon: "success",
          timer: 2000,
        });
        setCategory("");
        setDate("");
        setPaymentMode("");
        setAmount("");
        setNotes("");
      } else {
        Swal.fire({
          title: response.data.message,
          icon: "error",
          timer: 2000,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getCategory = async () => {
    try {
      const response = await axios.get(
        `${BASE_Url}/expense/get-all-category-name`
      );
      setCDData(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getCategory();
  }, []);
  useEffect(() => {
    if (activeTab === "Expense") {
      setIsMounted(true);
    }
  }, [activeTab]);
  if (!isMounted) {
    return null;
  }

  return (
    <div>
      {" "}
      <Box
        sx={{
          minHeight: "8vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          bgcolor: "amber.50",
          p: { xs: 2, md: 4 },
          overflow: "auto", // Enable scrolling
        }}
      >
        <Box
          sx={{
            // maxWidth: { xs: '100%', md: 500 },
            width: "100%",
            maxHeight: "70vh", // Limit the form's height to ensure space for scrolling
            overflowY: "auto",
            boxShadow: 3,
            borderRadius: 2,
            p: 4,
            bgcolor: "background.paper",
          }}
        >
          <div className="flex justify-between">
            <Typography
              variant="h5"
              component="h1"
              sx={{ mb: 4, display: "flex", alignItems: "center" }}
            >
              <IconButton>
                <ArrowBack />
              </IconButton>
              Create Expense
            </Typography>
            <div className="flex gap-3">
              <AddCatgorymodal getCategory={getCategory} />
              <EditCategory categories={CData} />
            </div>
          </div>

          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <FormControl fullWidth required>
                  <InputLabel id="category-label">Select Category</InputLabel>
                  <Select
                    labelId="category-label"
                    id="category"
                    value={category}
                    onChange={(e) => setCategory(e.target.value)}
                    label="Select Category"
                  >
                    <MenuItem value="">
                      <em>Select Category</em>
                    </MenuItem>
                    {CData &&
                      CData?.map((el) => {
                        return (
                          <MenuItem key={el} value={el}>
                            <em>{el}</em>
                          </MenuItem>
                        );
                      })}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12}>
              <TextField
  fullWidth
  required
  label="Expense Date"
  type="date"
  id="date"
  value={date}
  onChange={(e) => setDate(e.target.value)}
  // InputLabelProps={{ shrink: true }}
  // inputProps={{ min: new Date().toISOString().split("T")[0] }} // Sets the min date to today
/>
              </Grid>

              <Grid item xs={12}>
                <FormControl component="fieldset" fullWidth>
                  <Typography variant="body1">Payment Mode</Typography>
                  <RadioGroup
                    row
                    value={paymentMode}
                    onChange={(e) => setPaymentMode(e.target.value)}
                  >
                    {["Online", "Cheque", "Cash"].map((mode) => (
                      <FormControlLabel
                        key={mode}
                        value={mode}
                        control={<Radio />}
                        label={mode}
                      />
                    ))}
                  </RadioGroup>
                </FormControl>
              </Grid>

              {/* <Grid item xs={12}>
            <div
            
                style={{ width: "100%" }}
                onClick={() => {
                    setAddItemModal(!additemodal);
                }}
              >
                <button
                  className="btn btn-outline-primary mt-3 border"
                  style={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: "30px",
                    backgroundColor: "white",
                    color: "silver",
                    border: "aliceblue"
                  }}
                >
                  <AiOutlinePlusCircle />
                  Add Item (Optional)
                </button>
              </div>
            </Grid> */}
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  required
                  label="Amount"
                  id="item"
                  value={amount}
                  onChange={(e) => setAmount(e.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  required
                  label="Expense Notes"
                  id="notes"
                  value={notes}
                  onChange={(e) => setNotes(e.target.value)}
                  multiline
                  rows={3}
                />
              </Grid>

              <Grid item xs={12}>
                <Button
                  fullWidth
                  type="submit"
                  variant="contained"
                  color="primary"
                >
                  Save
                </Button>
              </Grid>

              <Grid item xs={12}>
                {/* <Button
                fullWidth
                variant="outlined"
                color="primary"
              >
                Cancel
              </Button> */}
              </Grid>
            </Grid>
          </form>
        </Box>
      </Box>
    </div>
  );
};

export default Expense;
