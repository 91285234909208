import React, { useEffect, useState } from "react";
import { Grid, TextField, Button, Card, CardContent, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography, Checkbox } from '@mui/material';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import "react-datepicker/dist/react-datepicker.css";
import { CardBody, Col,Input, Label } from "reactstrap";
// import { Button, Card, , Table } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
// import { BsArrowLeft } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { BASE_Url } from "../../URL.js/URL";
import Swal from "sweetalert2";
const DigitalMarketing = () => {
  const dispatch = useDispatch();
  const [userData, setUserData] = useState();
  const [promotionName, setPromotionName] = useState("");
  const [promotionText, setPromotionText] = useState("");
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [image, setImage] = useState("");
  const [imageName, setImageName] = useState("");
  const [video, setVideo] = useState("");
  const [videoName, setVideoName] = useState("");
  const navigate = useNavigate();


  const handleChange = (e) => {
    const { name, checked } = e.target;
    // console.log(checked);
    if (name === "allSelect") {
      let tempUser = userData.map((el) => {
        return { ...el, isChecked: checked };
      });
      setUserData(tempUser);
    } else {
      const tempUser = userData.map((el) =>
        el.customer_name === name ? { ...el, isChecked: checked } : el
      );
      setUserData(tempUser);
    }
  };


  useEffect(() => {
    // dispatch(handelCustomerListRequest());
  }, []);

  const getCustomers = () => {
    const { storeId, saasId } = JSON.parse(localStorage.getItem("STORE_DATA"));
    axios
      .get(`${BASE_Url}/digital-promotion/get-customer-list/${saasId}`)
      .then((res) => {
        if (res.status === 200) {
          // console.log("All Data", res.data.data);
          setUserData(res.data.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getCustomers();
  }, []);

 
  const allSelect = () => {
    const selectedUser = userData.filter((el) => el.isChecked === true);
    if (!video) {
      // If any required field is empty, return without updating step
      Swal.fire({
        icon: "warning",
        title: "Please fill all the required details.",
      });
      return;
    }
 if (!video || !imageName ) {
      // If any required field is empty, return without updating step
      Swal.fire({
        icon: "warning",
        title: "Please fill all the required details.",
      });
      return;
    }
    
    selectedUser.forEach((el) => {
      const { storeId, saasId } = JSON.parse(localStorage.getItem("STORE_DATA"));

      const formData = new FormData();
      formData.append("promotional_video", video);
      formData.append("promotional_image", image);
      formData.append("promotional_text", promotionText);
      formData.append("saas_id", saasId);
      formData.append("store_id", storeId);
      formData.append("promotion_name", promotionName);
      formData.append("start_date", startDate.toISOString());
      formData.append("end_date", endDate.toISOString());

      const requestOptions = {
        method: "POST",
        body: formData,
        redirect: "follow",
      };

      fetch(`${BASE_Url}/digital-promotion/create-digital-promotion-master`, requestOptions)
      .then((res) => {
        setVideo('')
        setImageName('')
        promotionText('')
        Swal.fire({
            title: "Promotion Add Successful",
            icon: "success",
            timer: 1000,
          });
    })
        .catch((error) => console.log("error", error));
    });
  };
  return (
    <>
      <Grid container justifyContent={"center"} item xs={12}>
       <div
          className="mx-2  mb-5 mt-1"
          style={{
            overflowX: "auto",
            boxShadow: "0 2px 12px rgba(36, 36, 39, 0.12)",
            borderRadius: "0.75rem",
            overflow: "hidden",
            fontFamily: "DM sans-serif",
            fontSize: "1.25rem",
            border: "0 2px 12px rgba(36, 36, 39, .12)",
            padding: "10px",
            height: "fit-content"
          }}
        >
      <div className="flex fw-bold flex-row items-center justify-center mt-2 mx-2"
      
      >
      <div className="d-flex justify-content-center"
      >
          <h3 className="fw-bold">Digital Marketing</h3>
     
        </div>
        
      </div>
      <hr className="m-0"/>
      <div className="mt-3" style={{ height: "70vh", overflowY: "auto"}}> 
      <div className="mt-2  mx-2" style={{ marginBottom: "0%" }}>
      <Grid className="mt-1 mx-2" item xs={12}>
        <Card>
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={12} md={4}>
                <TextField
                  size="small"
                  type="text"
                  fullWidth
                  id="promotion-name"
                  value={promotionName}
                  onChange={(e) => setPromotionName(e.target.value)}
                  label="Promotion Name"
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <DatePicker
                      selected={startDate}
                      onChange={(date) => setStartDate(date)}
                      className="form-control"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <DatePicker
                      selected={endDate}
                      onChange={(date) => setEndDate(date)}
                      className="form-control"
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
     
      <Grid container className="mt-2" spacing={2}>
      <Grid container className="flex justify-center " item xs={12} md={3}>
        <Card>
          <CardContent>
          <Label>Upload Promotional Video</Label>
            <input
              type="file"
              accept="video/*"
              placeholder="Upload Promotional Video"
              id="movie"
              onChange={(e) => {
                setVideo(e.target.files[0]);
                setVideoName(e.target.files[0].name);
              }}
            />
            {videoName && <div>{videoName}</div>}
            {/* <Button
             className="mt-3"
              variant="contained"
              color="primary"
              onClick={() => {}}
            >
              Submit
            </Button> */}
          </CardContent>
        </Card>
      </Grid>
      <Grid container className="flex justify-center " item xs={12} md={3}>
        <Card>
          <CardContent>
          <Label>Upload Promotional Image</Label>

            <input
              type="file"
              accept="image/png, image/jpeg"
              placeholder="Upload Promotional Image"
              id="poster"
              onChange={(e) => {
                setImage(e.target.files[0]);
                setImageName(e.target.files[0].name);
              }}
            />
            {imageName && <div>{imageName}</div>}
            {/* <Button
             className="mt-3"
              variant="contained"
              color="primary"
            >
              Submit
            </Button> */}
          </CardContent>
        </Card>
      </Grid>
      <Grid  className="mx-2" item xs={12} md={4}>
        <Card>
          <CardContent>
          <Label>Enter Promotion Text</Label>

            <TextField
              size="small"
              type="text"
              fullWidth
              id="promotion-text"
              value={promotionText}
              onChange={(e) => setPromotionText(e.target.value)}
              label="Promotion Text"
            />
            {/* <Button
            className="mt-2"
              variant="contained"
              color="primary"
            >
              Submit
            </Button> */}
          </CardContent>
        </Card>
      </Grid>
      </Grid>



      <Grid item xs={12} sx={{ mt: 3, }}>
      <Card>
        <CardContent>
          <TableContainer component={Paper} >
            <Table sx={{ minWidth: 650 }} aria-label="user table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">
                    <Typography variant="h6" component="div" sx={{ margin: 0, padding: 0 }}>
                      All
                    </Typography>
                    <input
                    type="checkbox"
                    className="form-check-input"
                    name="allSelect"
                    checked={
                      userData &&
                      userData.filter((el) => el?.isChecked !== true).length < 1
                    }
                    onChange={handleChange}
                  />
                  </TableCell>
                  <TableCell align="center">Name</TableCell>
                  <TableCell align="center">Mobile No.</TableCell>
                  <TableCell align="center">Whatsapp</TableCell>
                  <TableCell align="center">SMS</TableCell>
                  <TableCell align="center">Email</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {userData && userData.map((user, index) => (
                  <TableRow key={index}>
                    <TableCell align="center">
                    <input
                        type="checkbox"
                        className="form-check-input"
                        name={user.customer_name}
                        checked={user?.isChecked || false}
                        onChange={handleChange}
                      />
                    </TableCell>
                    <TableCell align="center">{user.customer_name}</TableCell>
                    <TableCell align="center">{user.mobile_number}</TableCell>
                    <TableCell align="center">{user.mobile_number}</TableCell>
                    <TableCell align="center">{user.mobile_number}</TableCell>
                    <TableCell align="center">{user.email}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <hr className="m-0" />
          <Grid container justifyContent="center" sx={{ mb: 2 }}>
            <Button 
              variant="contained" 
              sx={{ 
                backgroundColor: '#0E2954', 
                color: 'dark', 
                fontWeight: 'bold', 
                mt: 4 
              }}
              onClick={() => allSelect(userData)}
            >
              Send
            </Button>
          </Grid>
        </CardContent>
      </Card>
    </Grid>

</div>

</div></div>
</Grid>
    </>
  );
};

export default DigitalMarketing;
