// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.css-de1c49 { 
    display: flex;
    height: 25px;
    float: left;
} 
.myCart{
    float: right;
    margin-right: 15px;
    font-size: 30px;
}`, "",{"version":3,"sources":["webpack://./src/Components/Opt/Footer.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,YAAY;IACZ,WAAW;AACf;AACA;IACI,YAAY;IACZ,kBAAkB;IAClB,eAAe;AACnB","sourcesContent":[".css-de1c49 { \r\n    display: flex;\r\n    height: 25px;\r\n    float: left;\r\n} \r\n.myCart{\r\n    float: right;\r\n    margin-right: 15px;\r\n    font-size: 30px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
