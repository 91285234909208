import { createSlice } from "@reduxjs/toolkit";
import Swal from "sweetalert2";

const selectOrdersSlice = createSlice({
  name: "Orders",
  initialState: {
    cartProduct: [],
    loylityCustomer:[],
    linkCustomerdata:[],
    data: [],
    menuList: [],
    opencart: false
  },
  reducers: {
    addOrders: (state, action) => {
      state.data.push(action.payload);
    },
    addMenu: (state, action) => {
      state.menuList[0] = action.payload;
    },
    updateCart: (state, action) => {
      console.log("naya action", action.payload);
      state.cartProduct = action.payload;
    },
    linkloylity: (state, action) => {
      console.log("linkloylity action", action.payload);
      state.loylityCustomer = action.payload;
    },
    linkCustomer: (state, action) => {
      console.log("linkCustomerdata action", action.payload);
      state.linkCustomerdata = action.payload;
    },
    //remove linkCustomer data
    removeLinkCustomer: (state, action) => {
      state.linkCustomerdata = [];
    },
    addCartItem: (state, action) => {
      const existingItemIndex = state.cartProduct.findIndex(
        (existing) => existing.item_id === action.payload.item_id
      );
      
    
      if (existingItemIndex !== -1) {
        const updatedItems = [...state.cartProduct];
        updatedItems[existingItemIndex] = {
          ...updatedItems[existingItemIndex],
          item_qty: updatedItems[existingItemIndex].item_qty + 1,
        };
    
        state.cartProduct = updatedItems;
        Swal.fire({
          title: "Item Quantity Update Successful",
          icon: "success",
          timer: 1000,
        });
      } else {
        const newItem = {
          ...action.payload,
          item_qty: 1,
        };
        state.cartProduct = [...state.cartProduct, newItem];
    
        Swal.fire({
          title: "Item Add Successful",
          icon: "success",
          timer: 1000,
        });
      }
    },
    
    openCart: (state, action) => {
      state.opencart = action.payload;
    },
    removeCart: (state, action) => {
      state.cartProduct = [];
    },
    removeCartItem: (state, action) => {
      state.cartProduct = state.cartProduct.filter(
        (item) => item.item_id !== action.payload.item_id

      );
    },
  },
});

export const {
  removeCartItem,
  updateCart,
  removeCart,
  openCart,
  addOrders,
  addMenu,
  addCartItem,
  linkloylity,
  linkCustomer,
  removeLinkCustomer
} = selectOrdersSlice.actions;
export const selectReducer = selectOrdersSlice.reducer;
