import axios from "axios";
import React, { useEffect, useState, useRef } from "react";
// import React from "react";
import Dropdown from "react-bootstrap/Dropdown";
import "bootstrap/dist/css/bootstrap.min.css";
import { useReactToPrint } from 'react-to-print';
import { Link, useNavigate } from "react-router-dom";
import { BiSolidChevronRight } from "react-icons/bi";
import zIndex from "@mui/material/styles/zIndex";
import useSound from "use-sound";
import { StatusFilter } from "../../redux/slices/dashboardStatus";
import { useDispatch } from "react-redux";
import { BASE_Url } from "../../URL.js/URL";
import { LocalDining } from '@mui/icons-material';
import FastfoodIcon from '@mui/icons-material/Fastfood';

const OnlineOrders = () => {
  const [selectedButton, setSelectedButton] = useState('Pending');
  const navigate = useNavigate();
  const [itemData, setItemData] = useState([]);
  const [playenow, setPlayenow] = useState(false);
  const printRef = useRef()
  const dispatch=useDispatch()
  const HandleButton=(status)=>{
    setSelectedButton()
    dispatch(StatusFilter())
    console.log("gautam",status)
  }
  useEffect(()=>{
if(playenow){

}
  },[playenow])

  // const [status, setStatus] = useState("pending");

const GetOrderByStatus=()=>{

  const Token = JSON.parse(localStorage.getItem("Token"));
  if (!Token) {
    navigate('/login')
  } else {
    const { storeId, saasId } = JSON.parse(localStorage.getItem("STORE_DATA"));
    const apiUrl = `${BASE_Url}/order/view-order/${saasId}`;
    axios
      .get(apiUrl)
      .then((response) => {
        console.log("dsorder", response);
        if (response.status === 200)  {
          const data = response.data;
          // Assuming you want to display the data for the fourth item (index 3)
          // setItemData(data.data.item_list[3]);
          console.log("response.data.data.length>itemData.length",response.data.data.length>itemData.length)
          if(response.data.data.length>itemData.length){ 
            setPlayenow(true)
          }

          // const jsondata = data.data.map((item)=>{
          //   item['Approved']= "panding"
          //   return item
          // })
          // console.log("modify",jsondata)
          setItemData(data.data);
        } else {
          console.log("Failed to fetch data from the API.");
        }
      })
      .catch((error) => {
        console.error("An error occurred:", error);
      });
  }
}

  useEffect(() => {
    GetOrderByStatus()
  }, []);




 
// Define a function to get the background color based on status
const getBackgroundColor = (status) => {
  switch (status) {
    case "preparing":
      return '#30A52D';
    case "Order Recevied":
      return 'rgb(245 158 11)';
    case "Ready_to_Serve":
      return 'rgb(219 37 69)';
    default:
      return 'rgb(245 158 11)';
  }
};

// Define a function to get the content based on status
const getContent = (status) => {
  switch (status) {
    case "Ready_to_Serve":
      return (
        <div className="d-flex justify-around">
          <LocalDining fontSize="small" color="dark" />
          <span>zomato</span>
        </div>
      );
    case "Order Recevied":
      return (
        <div className="d-flex">
          <FastfoodIcon fontSize="small" color="dark" />
          <span>swiggy</span>
        </div>
      )
      case "Order Received":
        return (
          <div className="d-flex">
            <FastfoodIcon fontSize="small" color="dark" />
            <span>swiggy</span>
          </div>
        )
    case "preparing":
      return <span>BoxB</span>;
    default:
      return null;
  }
};


  
  return (
    
    <div>
   <div className="flex fw-bold flex-row items-center justify-center mt-2 mx-4"
        style={{
          overflowX: "auto",
          boxShadow: "0 2px 12px rgba(36, 36, 39, 0.12)",
          borderRadius: "0.75rem",
          overflow: "hidden",
          fontFamily: "DM sans-serif",
          fontSize: "1rem",
          border: "0 2px 12px rgba(36, 36, 39, .12)",
          padding: "10px",
        }}>
      <div className="d-flex justify-content-center"
      >
          <h3 className="fw-bold">OnlineOrders</h3>
        </div>
      </div>

      <div style={{ height: "100vh", overflowY: "auto"}}>

        <div className="flex flex-wrap flex-row mx-2" style={{ marginBottom: "40%" }}>
          {itemData.map((data) => {
            return (
              <div className="w-full max-w-[18rem] rounded-lg border-2 border-gray-300 bg-white m-4 ml-2">
                <div className="flex border-b-2" style={{borderRadius: " 8px 8px 0px 0px", background: "#0E2954"}}>
                  <li style={{color: "#FFF",

fontFamily: "Inter",

fontStyle: "normal",
fontWeight: "700",
lineHeight: " normal",}} className="w-full list-none	 font-medium border-gray-300  px-4 py-3 p-6">
                    {data.table_id}
                  </li>

                     <li className="fw-bold" style={{position: "relative",
    top: "-9px",
    right: "-36px"}}>
 <div className=" text-white ">
 <div
  className="px-3 rounded-4 p-2 text-white"
  style={{
    background: getBackgroundColor(data.status),
    zIndex: 999,
  }}
>
  {getContent(data.status)}
</div>

                   
                  </div>
</li>
                  <BiSolidChevronRight className="items-center m-6 font-bold"></BiSolidChevronRight>
                </div>

                {/* <div className="flex" style={{ zIndex: 1, position: "relative" }}>
                <li className="w-full list-none font-semibold ml-2 py-2">
                  {data.customer_name == null
                    ? "Walk in Custumer"
                    : data.customer_name}
                </li>

                <span className="font-semibold list-none flex items-center justify-between px-4">
                  {data.time}
                </span>
              </div> */}
                <div
                  className="flex mt-1 w-1/3 "
              
                >
                  <li className="w-full fw-bold ml-3 py-3  list-none	">
                    {data.order_type}
                  </li>
            
                </div>
       <hr className="m-0"/>

                <div className="d-flex">
                  <li className="font-semibold text-xl px-4 py-3 mt-3 list-none	">
                    Items
                  </li>
                  <div style={{ maxHeight: '100px', overflowY: 'auto' }}>
                  <ul>
                    {data.item_name_list.slice(0, 5).map((item, index) => {
                      return (
                        <li  key={index} className="px-3 rounded-2 text-nowrap text-white  mt-3 mb-3 list-none"    style={{
                        background: data.status === "preparing" ? '#30A52D' :
                          data.status === "Order Recevied" ? ' rgb(245 158 11)' :
                            data.status === "Ready_to_Serve" ? 'rgb(219 37 69)' :
                              'rgb(245 158 11)',
                        zIndex: 999,
                      }}>
                          {item.item_name}
                        </li>
                      );
                    })}
                  </ul>
                </div>
                </div>
              
            

                {/* <div className="font-bold mt-2 text-xl flex items-center ml-1 mb-2">
               
             
             {data.approval_status=='pending' &&  <div className="flex">
                 <button
                   className=" m-2 items-center border-r-2 px-3 pl-9"
                   style={{borderRadius: "8px",
                     background: "#0E2954",
                     boxShadow: "0px 0px 4px 3px rgba(0, 0, 0, 0.25)" }}
                     onClick={() => {
                      updateOrderStatus(data.order_id, "Accept")
                    }}

                 >
                  <span style={{color: "#FFF",

fontFamily: "Inter",
fontSize: "22px",
fontStyle: "normal",
fontWeight: "700",
lineHeight: " normal"}}> Accept </span>
                 </button>

                 <button
                   className=" m-2 items-center border-r-2 px-3 pl-9"
                   style={{borderRadius: "8px",
                     background: "#FFF",
                     boxShadow: " 0px 0px 4px 3px rgba(0, 0, 0, 0.25)" }}
                     onClick={() =>    updateOrderStatus(data.order_id, "Rejecte")}
                    
                 >
                  <span style={{color: "rgba(0, 0, 0, 0.70)",

fontFamily: "Inter",
fontSize: "22px",
fontStyle: "normal",
fontWeight: "700",
lineHeight: " normal"}}> Rejecte </span>
                 </button>
               </div>} 
       
           </div>
           */}
        
              </div>
            );
          })}
        </div>

      </div>
    </div>
  );
};

export default OnlineOrders;
