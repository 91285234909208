import React from "react";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { BsArrowLeft, BsSearch } from "react-icons/bs";
import { useLocation, useNavigate } from "react-router-dom";
import LogoutIcon from '@mui/icons-material/Logout';
import MenuIcon from '@mui/icons-material/Menu';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import {openCart,removeCart} from "../redux/slices/selectOrdersSlice";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "@mui/material";
import ReceiptIcon from "@mui/icons-material/Receipt";
const CustomNavbar = ({toggle,setToggle}) => {
  const dispatch = useDispatch()
  const location = useLocation();
  const navigate = useNavigate();
  const handleLogout = () => {
    localStorage.clear();
    navigate("/login");
  };
  const jsonString = localStorage.getItem('STORE_DATA');
const parsedData = JSON.parse(jsonString || '{}'); // Fallback to an empty object if null
const { storeName } = parsedData;
  const screenWidth = window.innerWidth;

  // Define styles based on the screen width
  // const styles = {
  //   color: 'grey',
  //   fontSize: 'xx-large',
  //   position: 'fixed',
  //   top: screenWidth >= 768 ? '14px' : 'auto', // Desktop position
  //   right: screenWidth >= 768 ? '20px' : 'auto', // Desktop position
  //   bottom: screenWidth < 768 ? '20px' : 'auto', // Mobile position
  //   left: screenWidth < 768 ? '20px' : 'auto', // Mobile position
  // };

 
  const product = useSelector((state) => {
    return state.selectOrders.cartProduct;
  });
  return (
    <div className="flex h-14 justify-between items-center gap-2 border ">
      {toggle ==false&& <MenuIcon className="m-3" onClick={()=>setToggle(true)} />}
      {location.pathname !== "/" &&
         <BsArrowLeft
            size={25}
            className="cursor-pointer "
            onClick={() =>{
              dispatch(removeCart());
              
              navigate(-1);}}
          />
      }
      <div>
      
      </div>
      <div className="p-2 hidden sm:block" >
                  <strong className="fs-5 lh-1">{storeName}</strong>
                </div>
      <div className="flex justify-stretch " style={{position: "relative",
    left: "-20px"}}>
<Button
  variant="contained"
  style={{ backgroundColor: "rgb(188, 54, 45)", color: "white" }}
  className="mr-2"
  onClick={() => navigate("/Orders")}
  startIcon={<ReceiptIcon />}
>
  Quick Bill
</Button>
        <NotificationsNoneIcon size={25} className="ml-4 mt-2 cursor-pointer" />
        <AccountCircleIcon size={25} className="ml-4 mt-2 cursor-pointer" />
        <LogoutIcon onClick={handleLogout} size={25} className="ml-4 mt-2 cursor-pointer" />
        {location.pathname !== "/" && location.pathname !== "/Menu" && location.pathname !== "/Orders" && 
        location.pathname !== "/Tables" && location.pathname !== "/Reports" &&      location.pathname !== "/digitalMarketing" &&
        location.pathname !== "/onlineOrdrs" && location.pathname !== "/inventory" && (
  <IconButton
    aria-label="cart"
    onClick={() => dispatch(openCart(true))}
    className="cart-icon "
  >
    <Badge
      badgeContent={product?.length}
      color="error"
    >
      <ShoppingCartIcon style={{ fontSize: "27px" }} />
    </Badge>
  </IconButton>
)}

      </div>
    </div>
  );
};

export default CustomNavbar;
