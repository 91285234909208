import React, { useState } from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import List from "./List";
import { useDispatch } from "react-redux";
import { addOrderDetails } from "../redux/slices/tableSlice";

const TabClick = ({ ArrayItem, handleQtyChange }) => {
  const [value, setValue] = useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const dispatch = useDispatch;
  const handleOrders = (e) => {
    dispatch(addOrderDetails);
  };

  return (
    <div>
      <Box sx={{ width: "100%", typography: "body1" }}>
        <TabContext value={value}>
          <Box
            className="grid-cols-12"
            sx={{ borderBottom: 1, borderColor: "divider" }}
          >
            <TabList
              onChange={handleChange}
              aria-label="lab API tabs example"
              className="w-full grid grid-cols-12"
            >
              <Tab
                className="text-white w-1/3 text-lg col-span-4 flex items-center gap-x-4 cursor-pointer p-0 m-0 rounded-sm hover:bg-dark-purple hover:text-white mt-4"
                label="Dine-in"
                value="1"
              />
              <Tab
                className="text-white w-1/3 text-lg flex items-center gap-x-4 cursor-pointer p-0 m-0 rounded-sm hover:bg-dark-purple hover:text-white mt-4"
                label="Delivery"
                value="2"
              />
              <Tab
                className="text-white w-1/3 text-lg flex items-center gap-x-4 cursor-pointer p-0 m-0 rounded-sm hover:bg-dark-purple hover:text-white mt-4"
                label="Pickup"
                value="3"
              />
            </TabList>
          </Box>
          <TabPanel value="1">Item One</TabPanel>
          <TabPanel value="2">Item Two</TabPanel>
          <TabPanel value="3">
            <List
              ArrayItem={ArrayItem}
              onClick={(e) => handleOrders(e.order_details)}
              handleQtyChange={handleQtyChange}
            />
          </TabPanel>
        </TabContext>
      </Box>
    </div>
  );
};

export default TabClick;
