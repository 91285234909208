import { Payment } from "@mui/icons-material";
import { createSlice } from "@reduxjs/toolkit";

const tableSlice = createSlice({
  name: "tableData",
  initialState: {
    data: [],
    createCustomer: {
      order_date: "",
      table_name: "",
      saas_id: "8",
      store_id: "80002",
      category: "Pick-up",
      payment_mode: "",
      status: "pending",
      order_details: [],
      table_number: null,
      add_customer: [],
      //feedback object create here 
      feedback: {
    "saasId": "",
    "message": "  ",
    "surveyId": 0,
    "surveyName": null,
    "categoryId": null,
    "categoryName": null,
    "storeName": "",
    "customer_name": "",
    "customer_mobile": "",
    "customer_email": "",
    "question": "",
    "subQuestion": "",
    "feedbackDate": "",
    "ratingLevel": null
      },
    },
  },
  reducers: {
    addData: (state, action) => {
      state.data.push(action.payload);
      // state.data = action.payload
    },
    addCustomer: (state, action) => {
      state.createCustomer.addCustomer.push(action.payload);
    },
    addTableName: (state, action) => {
      state.createCustomer.table_name = action.payload;
    },
    addTableCategory: (state, action) => {
      state.createCustomer.category = action.payload;
    },
    addPaymentMode: (state, action) => {
      state.createCustomer.payment_mode = action.payload;
    },
    addOrderDetails: (state, action) => {
      state.createCustomer.order_details.push(action.payload);
      //   state.data = action.payload
    },
    addCustomerDetails: (state, action) => {
      state.createCustomer.add_customer.push(action.payload);
      //   state.data = action.payload
    },
    addQuantityChange: (state, action) => {
      const updatedItems = [...state.createCustomer.order_details];
      updatedItems[action.payload.index].item_qty = action.payload.newQty;
    },
    addDate: (state, action) => {
      state.createCustomer.order_date = action.payload;
    },
    handleTableNumber: (state, payload) => {
      state.createCustomer.table_number = payload.payload;
    },
    //set setQuestionAndSubQuestion in the feedback object
    setFeedbackQuestionAndSubQuestion: (state, action) => {
      console.log(action.payload)
      state.createCustomer.feedback.saasId = action.payload.saasId;
      state.createCustomer.feedback.question = action.payload.question;
      state.createCustomer.feedback.subQuestion = action.payload.subQuestion;
      state.createCustomer.feedback.feedbackDate = action.payload.feedbackDate;
    },
    //set setRatingLevel in the feedback object
    setFeedbackRatingLevel: (state, action) => {
      console.log(action.payload)
      state.createCustomer.feedback.ratingLevel = action.payload;
    },
    //set setFeedbackDate in the feedback object
    setFeedbackDate: (state, action) => {
      state.createCustomer.feedback.feedbackDate = action.payload.feedbackDate;
    },
    //set customer_name, customer_mobile , customer_email in the feedback object on onChange
    setFeedbackCustomerDetails: (state, action) => {
      console.log(action.payload)
      state.createCustomer.feedback.customer_name = action.payload.customer_name;
      state.createCustomer.feedback.customer_mobile = action.payload.customer_mobile;
      state.createCustomer.feedback.customer_email = action.payload.customer_email;
    },
    
    //function to clear feedback object
    clearFeedback: (state) => {
      state.createCustomer.feedback = {
        "saasId": "",
        "message": "  ",
        "surveyId": 0,
        "surveyName": null,
        "categoryId": null,
        "categoryName": null,
        "storeName": "",
        "customer_name": "",
        "customer_mobile": "",
        "customer_email": "",
        "question": "",
        "subQuestion": "",
        "feedbackDate": "",
        "ratingLevel": null
      };
    },
    //function to submit feedback

    
  },
});

// for exporting in react componenrt
export const {
  addData,
  handleTableNumber,
  addCustomer,
  addTableName,
  addDate,
  addTableCategory,
  addPaymentMode,
  addOrderDetails,
  addCustomerDetails,
  addQuantityChange,
  setFeedbackQuestionAndSubQuestion,
  setFeedbackRatingLevel,
  setFeedbackCustomerDetails,
  clearFeedback
} = tableSlice.actions;
// for exporting in store(reducer)
export const tableReducer = tableSlice.reducer;
