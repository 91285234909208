import Sidebar from "./Components/Sidebar";
import React, { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import Dashboard from "./Components/Dashboard";
import Tables from "./Components/Tables";
import OrderPage from "./Components/Order/OrderPage";
import Menu from "./Components/Menu/Menu";
// import Reports from "./Components/Reports";
import Reports from "./Components/Reports/Reports";
import CRM from "./Components/CRM";
import Navbar from "./Components/Navbar";
import NewOrders from "./Components/NewOrders";
import SelectOrders from "./Components/SelectOrders";

import ProtectedRoutingWhenLogout from "./ProtectedRoutingWhenLogout";
import MiniLayout from "./Components/MiniLayout";
import SidebarLayout from "./Components/SidebarLayout";
import Update from "./Components/Order/Update";
import Login from "./Components/Login/Login";
import Register from "./Components/Register/Register";
import { BiRegistered } from "react-icons/bi";
import Opt from "./Components/Opt/Opt";
import Beverages from "./Components/Menu/Beverages";
import Reports_names from "./Components/Reports/Reports_names";

import Usermanagement from "./Management/Usermanagement";
import FinishOrder from "./Components/FinishOrder";
import Inventory from "./Components/inventory/Inventory";
import AddStaff from "./Management/AddStaff";
import OnlineOrders from "./Components/Online Orders/OnlineOrders";
import DigitalMarketing from "./Components/Digital Marketing/DigitalMarketing";
import AddBom from "./Components/Add Bom/AddBom";
import OrderStatus from "./Components/Opt/OrderStatus";
import RoleMaster from "./Management/RoleMaster";
import eachDayOfInterval from "date-fns/eachDayOfInterval";
import Recipe from "./Components/Recipe/Recipe";
import Purches from "./Components/Purchase/Purches";
import Bahikhata from "./Components/Financial/Bahikhata";
import logo from "./assets/food4ulogo.png"
import FoodDashboard from "./Components/Food4u/FoodDashboard";
import ErrorPage from "./Components/Opt/ErrorPage";
import FeedbackComponent from "./Components/Feedback/FeedbackComponent";
import Feedlayout from "./Components/Feedback/Feedlayout";

function App() {
  const location = useLocation();
  // This Is Comment!
  const Token= JSON.parse(localStorage.getItem("Token"));
    // ali pwa code start
    const jsonString = localStorage.getItem('STORE_DATA');
const parsedData = JSON.parse(jsonString || '{}'); // Fallback to an empty object if null
const { storeName } = parsedData;
    const [deferredPrompt, setDeferredPrompt] = useState(null);
    const [installButtonVisible, setInstallButtonVisible] = useState(false);
  
    useEffect(() => {
      console.log('User accepted the PWA installatio');
      window.addEventListener('beforeinstallprompt', (e) => {
        e.preventDefault();
        setDeferredPrompt(e);
        setInstallButtonVisible(true);
      });
  
      return () => {
        window.removeEventListener('beforeinstallprompt', handleInstallClick);
      };
    }, []);
  
    const handleInstallClick = () => {
      console.log('User accepted the PWA installation',deferredPrompt);
      if (deferredPrompt) {
        deferredPrompt.prompt();
        deferredPrompt.userChoice.then((choiceResult) => {
          if (choiceResult.outcome === 'accepted') {
            console.log('User accepted the PWA installation');
          }
          setDeferredPrompt(null);
          setInstallButtonVisible(false);
        });
      }
    };
   // ali pwa code end
   const isDynamicRoute = /\/\w+\/\w+\/\w+/.test(location.pathname) || 
                       /\/category-name\/\w+\/\w+\/\w+\/\w+/.test(location.pathname) || 
                       /\/Order-status\/\w+\/\w+\/\w+/.test(location.pathname) ||
                       /\/feedback\/\w+/.test(location.pathname);

                         
  return (
    <>
   
    {/* <BrowserRouter> */}

    {!isDynamicRoute && installButtonVisible && Token &&  (
        <div className="row">
          <div>
            <div
              className="bg-dark-purple p-2 mb-lg-0 text-white"
              data-tor="inview:bg(primary) hover:bg(danger)"
              // style={{ backgroundColor: "rgb(14 41 84)" }}
            >
              <div className="hstack gap-3 flex justify-between">
                <img className="h-10 w-10 mx-3" src={logo} alt="" />
                <div className="p-2">
                  <strong className="fs-5 lh-1">{storeName}</strong>
                </div>
                <div>
                  <button
                    className="btn btn-outline-info rounded-3"
                    id="install-button"
                    onClick={handleInstallClick}
                  >
                    Download App
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="flex-row flex">
        <div className="flex flex-col w-full">
          <Routes>
            <Route path="/error" element={<ErrorPage />} />
            <Route path="/:saas_id/:store_id/:table_id" element={<Opt />} />
            <Route path="/category-name/:saas_id/:store_id/:id/:table_id" element={<Beverages />} />
            <Route path="/Order-status/:saas_id/:store_id/:table_id" element={<OrderStatus/>}/>
            <Route path="/feedback/:saas_id/:store_id/:table_id" element={<Feedlayout/>}/>
            <Route>
              <Route
                path="/login"
                element={<ProtectedRoutingWhenLogout Component={Login} />}
              />
              <Route
                path="/register"
                element={<ProtectedRoutingWhenLogout Component={Register} />}
              />
              <Route
                path="/"
                element={
                  <div>
                    <SidebarLayout children={<FoodDashboard />} />
                  </div>
          }
              />
              <Route
                path="/online"
                element={
                      <div>
                       <SidebarLayout children={<Dashboard />} />
                     </div>
          }
              />
              <Route
                path="/Recipe"
                element={
                  <div>
                    <SidebarLayout children={<Recipe />} />
                  </div>
                }
              />
              <Route
                path="/Orders"
                element={
                  <div>
                    <SidebarLayout children={<OrderPage />} />
                  </div>
                }
              />
              <Route
                path="/Tables"
                element={
                  <div>
                    <SidebarLayout children={<Tables />} />
                  </div>
                }
              />
              {/* <Route path="/Menu" element={<Menu />} /> */}
              <Route
                path="/Menu"
                element={
                  <div>
                    <SidebarLayout children={<Menu />} />
                  </div>
                }
              />
              {/* <Route path="/Reports" element={<Reports_names />} /> */}
              <Route
                path="/Reports"
                element={  <div><SidebarLayout children={<Reports_names />} /></div> }
              />
              <Route
                path="/CRM"
                element={
                  <div>
                    <SidebarLayout children={<CRM />} />
                  </div>
                }
              />

              <Route
                path="/NewOrders"
                element={
                  <div>
                    <NewOrders />
                  </div>
                }
              />
              <Route
                path="/finishorder/:order_id"
                element={
                  <div>
                    <MiniLayout children={<FinishOrder />}></MiniLayout>
                  </div>
                }
              />
              <Route
                path="/SelectOrders/:name/:id"
                element={
                  <div>
                    <MiniLayout children={<SelectOrders />}></MiniLayout>
                  </div>
                }
              />
              <Route
                path="/Update"
                element={
                  <div>
                    <MiniLayout children={<Update />} />
                  </div>
                }
              />
              <Route
                path="/StaffAndUsers"
                element={
                  <div>
                    <SidebarLayout children={<Usermanagement />} />
                  </div>
                }
              />
              <Route
                path="/inventory"
                element={
                  <div>
                    <SidebarLayout children={<Inventory />} />
                  </div>
                }
              />
              <Route
                path="/onlineOrdrs"
                element={
                  <div>
                    <SidebarLayout children={<OnlineOrders />} />
                    
                  </div>
                }
              />
              <Route
                path="/digitalMarketing"
                element={
                  <div>
                    <SidebarLayout children={<DigitalMarketing />} />
                    
                  </div>
                }
              />
              <Route
                path="/Purchase"
                element={
                  <div>
                    <SidebarLayout children={<Purches />} />
                    
                  </div>
                }
              />
              <Route
                path="/financial"
                element={
                  <div>
                    <SidebarLayout children={<Bahikhata />} />
                    
                  </div>
                }
              />
              <Route
                path="/addBOM"
                element={
                  <div>
                    <AddBom />
                  </div>
                }
              />
              <Route
                path="/AddStaff"
                element={
                  <div>
                    <AddStaff />
                  </div>
                }
              />
              <Route
                path="/EditStaff"
                element={
                  <div>
                    <AddStaff />
                  </div>
                }
              />
               <Route
                path="/RoleMaster"
                element={
                  <div>
                    <RoleMaster/>
                  </div>
                }
              />
                <Route
                path="/AddRole"
                element={
                  <div>
                    <RoleMaster/>
                  </div>
                }
              />
            </Route>
            {/* <Route
                path="/Update:id"
                element={
                  <div>
                    <Update />
                  </div>
                }
              />
            </Route> */}
          </Routes>
        </div>
      </div>
    {/* </BrowserRouter> */}
  </>

  );
}

export default App;
