import React from "react";
import { useState } from "react";
import { screen } from "@testing-library/react";
import {
  AiFillAmazonCircle,
  AiOutlineClose,
  AiOutlineProfile,
  AiOutlineShoppingCart,
} from "react-icons/ai";
import { FiMenu } from "react-icons/fi";
import {
  Search as SearchIcon,
  Menu as MenuIcon,
  ShoppingCart as CartIcon,
  Receipt as OrdersIcon,
} from '@mui/icons-material';
import corn from "../../images/corn.jpg";
import paubhaji from "../../images/paubhaji.jpg";
import momo from "../../images/momo.jpg";
import vegthali from "../../images/vegthali.jpg";
import { useParams, Link, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { handleCategory } from "../../redux/slices/tableSlice";
import { RxCross2 } from "react-icons/rx";
import Cart from "./Cart";
import Swal from "sweetalert2";
import Navbar from "./Navbar";
import Product from "./Product";
import "./Footer.css";
import { IoReceiptSharp } from "react-icons/io5";
import { BsBucketFill } from "react-icons/bs";
import { BASE_Url } from "../../URL.js/URL";
import { BottomNavigation, BottomNavigationAction, Box, Grid, Modal } from "@mui/material";
import { Viewer, Worker } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import OrderBillmodal from "./OrderBillmodal";
import Header from "./Header";
import PreviewIcon from '@mui/icons-material/Preview';
const Opt = () => {
  const storeData = localStorage.getItem('Store_data') ? JSON.parse(localStorage.getItem('Store_data')) : {};
  const { storeLogo} = storeData
  const defaultLayoutPluginInstance = defaultLayoutPlugin();
  const [PdfFile, setPDFFile] = useState("");
  // const [selectedMenu, setSelectedMenu] = useState([]);
  const [updatecart, setUpdatecart] = useState(true);
  const [cartData, setCartData] = useState(null);
  // const [menu, setMenu] = useState([]);
  const [viewPdf, setViewPdf] = useState(false);
  const dispatch = useDispatch();
  const params = useParams();
  // console.log("check", params);
  const [count, setCount] = useState(0);
  const [search, setSearch] = useState("");
  const [recommandItem, setRecommandItem] = useState("");
  const [open, setOpen] = useState(false)
  const [totalsum, setTotalSum] = useState(0)
  // const [productId, setProducteId] = useState();
  const [show, setShow] = useState(false);
  const {saas_id,store_id,table_id} = params

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 480,
    height: 575,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };
  // const getMenu = () => {
  //   axios
  //     .get(
  //       `${BASE_Url}/item/view-menu/${selectedMenu}/${saas_id}`
  //     )
  //     .then((res) => {
  //       if (res.status === 200) {
  //         console.log("SELECTED MENU", res);

  //         setMenu(res.data.data);
  //       }
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };
  // useEffect(() => {
  //   getMenu();
  // }, [selectedMenu]);

  // const getCategory = () => {
  //   axios
  //     .get(`http://3.111.70.84:8093/test/api/v1/item/category-list/8`)
  //     .then((res) => {
  //       if (res.status === 200) {
  //         // console.log("CATEGORY DATA", res);
  //         setCategory(res.data.data);
  //       }
  //     })
  //     .catch((err) => console.log(err));
  // };

  // useEffect(() => {
  //   getCategory();
  // }, []);
  const [category, setCategory] = useState([]);
  const getCategory = () => {
    axios
      .get(`${BASE_Url}/item/category-list/${saas_id}`)
      .then((res) => {
        if (res.status === 200) {
          // console.log("CATEGORY DATA", res);
          setCategory(res.data.data);
        }
      })
      .catch((err) => console.log(err));
  };

  const recommend = () => {
    axios
      .get(
        `${BASE_Url}/search/recommended-item/${store_id}/${saas_id}`
      )
      .then((res) => {
        if (res.status === 200) {
          console.log("recommended DATA", res);
          setRecommandItem(res.data.data);
        }
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    // recommend();
    getCategory()
  }, []);

  const navigate = useNavigate();
  function handle() {
    navigate("/Breakfast");
    return alert("jhjdfd");
  }

  useEffect(() => {
    if (search) {
      const apiUrl = `${BASE_Url}/search/get-result/${store_id}/${saas_id}/${search}`;
      axios
        .get(apiUrl)
        .then((response) => {
          if (response.status === 200) {
            console.log("new Api", response);
            const data = response.data.data;
            console.log(" search", data);
            // Assuming you want to display the data for the fourth item (index 3)
            // setItemData(data.data.item_list[3]);
            setRecommandItem(data);
            // setSearch(data);
          } else {
            console.log("Failed to fetch data from the API.");
          }
        })
        .catch((error) => {
          console.error("An error occurred:", error);
        });
    } else {
      // recommend();
    }
  }, [search]);

  useEffect(() => {
    const t1 = JSON.parse(localStorage.getItem("my-cart"));
    setCartData(t1);
    setCount(t1?.length);
    console.log("ye console hua", t1);
  }, [updatecart]);

  useEffect(() => {
    const t1 = JSON.parse(localStorage.getItem("my-cart"));
    let finalSum = 0;
    let itemTotalPrice;
    t1?.map((item) => {
      itemTotalPrice = item.item_newprice
        ? item.item_newprice
        : item.item_price * item.item_qty;
      finalSum += itemTotalPrice;
      setTotalSum(finalSum);
    });
    setCount(t1?.length);
  }, [cartData]);

  //Add to cart Function

  const myorders = () => {
      const data = JSON.parse(localStorage.getItem("my-order")) 
      console.log("this is order_id",data?.order_id)
        if (data?.order_id) {
         
          axios.get(
            `${BASE_Url}/order/get-invoice-for-order/${data?.order_id}/${saas_id}/${store_id}`
          ).then((res)=>{
            // console.log("invoice", res.data.data.invoice );
            setPDFFile(res?.data?.data?.invoice);
            console.log("pdf file",PdfFile)
            // setQr(res.data?.data?.qr_file_name); 
            if (res?.data?.data?.invoice) {
              setViewPdf(true);
              localStorage.removeItem('my-order')
            }else{
              Swal.fire(
                "Please Wait",
                `Your Invoice not Generated Now`,
                "info"
              );
            }
          })
      
        }else{
          Swal.fire(
            "Please Wait",
            `Your Invoice not Generated Now`,
            "info"
          );
        }
      
  };


  const MyTotalbill= () =>{
    const data = JSON.parse(localStorage.getItem("my-order")) 
    console.log('total bill',data )
    if(data?.order_id){
      axios
      .get(
        `${BASE_Url}/order/total-bill-amount/${data?.order_id}`
      )
      .then((res) => {
        if (res.status === 200) {
          console.log("recommended DATA", res.data.data.total_bill_amount);
          Swal.fire(`Your Total Amount: Rs.${res.data.data.total_bill_amount}`)
        }
      })
      .catch((err) => console.log(err));
    }else{
      Swal.fire(
        "Please Wait",
        `Your Invoice not Generated Now`,
        "info"
      );
    }
  }
  return (
    <>
      {show && (
        <Cart
         table_id={table_id}
          show={show}
          setShow={setShow}
          updatecart={updatecart}
          setCount={setCount}
          cartData={cartData}
          setCartData={setCartData}
        />
      )}
      {/* {open &&(
        <OrderBillmodal
        open={open}
        setOpen={setOpen}
        />
      )} */}
      {/* navbar div */}
      <div className="shadow-md w-full fixed top-0 left-0 overflow-y-auto h-screen ">
        <Header/>
      <div className='main-container mt-3 max-w-[370px] h-[154px] bg-[rgba(217,217,217,0.2)]  bg-cover bg-no-repeat rounded-[6px] relative mx-auto my-0' 
      style={{
        backgroundImage: `url(${storeLogo})`,
      }}
      />
      <div className="flex justify-center flex-col items-center">
        {/* <Navbar 
        recommend={recommend}
        setRecommandItem={setRecommandItem}
        saas_id={saas_id}
        table_id={table_id}
        store_id={store_id}
        /> */}
        <h1 className="font-bold text-xl px-3">What Would you like to order ?</h1>

      </div>
       
        {/* search    */}
        <div className="">
        

          <li className="flex py-6 ">
            <div className=" flex flex-1 flex-col">

              <div className="flex justify-center items-center mb-14" style={{ maxHeight: "60vh", overflowY: "auto" }}>
  <Grid container spacing={2} className="max-w-[350px] max-h-[270px]">
    {category && category.map((el, index) => (
      <Grid item xs={6} key={index}>
        <div
          className="relative  overflow-hidden shadow-lg cursor-pointer max-h-[150px]"
          style={{
            backgroundImage: `linear-gradient(270deg, rgba(0, 0, 0, 0.00) 0%, #000 100%), 
                              url(${el.url}`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
          onClick={() =>navigate(`/category-name/${saas_id}/${store_id}/${el?.category_id}/${table_id}`)}
        >
          <div className="absolute inset-0 bg-black bg-opacity-50 flex flex-col justify-end p-4">
            <h3 className="text-white text-[13px] font-bold text-start text-nowrap">
              {el?.category_name}
            </h3>
            
          </div>
        </div>
      </Grid>
    ))}
  </Grid>
  </div>
              {/* <Product
                recommandItem={recommandItem}
                updatecart={updatecart}
                setUpdatecart={setUpdatecart}
              /> */}
            </div>
          </li>
          {/* <div className="border-t border-gray-200 px-4 py-6 sm:px-6 divide-y divide-solid">
        
        <div className="mt-6">
          <a href="#" className="flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-6 py-3 text-base font-medium text-white shadow-sm hover:bg-indigo-700">
          <AiOutlineShoppingCart onClick={()=>setShow(true)}/>
          </a>
        </div>
        
      </div> */}
          <footer
            style={{
              width: "100%",
              boxShadow: "0 2px 12px rgba(36, 36, 39, 0.12)",
              // borderRadius: "0.75rem",
              padding: "10px",
              backgroundColor: "white",
              height:"80px"
            }}
            className="
            d-flex
            justify-between
            text-white
             fixed
             bottom-0"
          >
            <BottomNavigation
        showLabels
        style={{width: "100%" }}
        // value={value}
        // onChange={(event, newValue) => {
        //   setValue(newValue);
        // }}
      >
        <BottomNavigationAction onClick={()=>navigate(`/${saas_id}/${store_id}/${table_id}`)} label="Menu" icon={<MenuIcon />} />
        <BottomNavigationAction onClick={()=>setShow(true)} label="Cart" icon={<CartIcon />} />
        <BottomNavigationAction onClick={()=>{navigate(`/Order-status/${saas_id}/${store_id}/${table_id}`)}} label="Orders" icon={<OrdersIcon />} />
        <BottomNavigationAction onClick={()=>{myorders()}} label="MyBill" icon={<PreviewIcon />} />
      </BottomNavigation>
            {/* {cartData?.length > 0 ? (
              <>
                {" "}
                <div className="d-flex flex-column">
                <div className=" css-de1c49">
                  <AiOutlineShoppingCart
                   style={{ height: "25px", fontSize: "26px" }}
                    onClick={() => setShow(true)}
                  />
                  <span className="fw-bold mx-1" style={{fontFamily:"sans-serif"}}>( {count} )</span>
                </div>
                  <span style={{fontSize:"25px"}}>Rs. {totalsum}</span>

                </div>

                <span style={{fontFamily:"sans-serif"}} className="fw-bold cursor-pointer" onClick={() => setShow(true)}>
                  View Cart
                </span>
              </>
            ) : (
              
             
            )} */}
          </footer>
        </div>
      </div>
      <Modal
            open={viewPdf}
            // onClose={}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                  marginBottom: "20px",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setViewPdf((state) => !state);
                  // navigate("/");
                }}
              >
                <AiOutlineClose size={20} />
              </div>
              {PdfFile && (
                <div style={{ height: "400px" }}>
                  <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js">
                    <Viewer
                      fileUrl={`${BASE_Url}/transaction/pdf/${PdfFile}`}
                      plugins={[defaultLayoutPluginInstance]}
                    />
                  </Worker>
                </div>
              )}
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <button className="bg-dark-subtle mt-10 p-2" onClick={()=>setViewPdf(false)}>
                 Close
                </button>
                {/* {
                  <div style={{ height: 200, width: 200 }}>
                    {qr && (
                      <img
                        style={{ height: "100%", width: "100%" }}
                        src={`${BASE_Url}/transaction/pdf-qr/${qr}`}
                        alt="qr code"
                      />
                    )}
                  </div>
                } */}
              </div>
            </Box>
          </Modal>
    </>
  );
};

export default Opt;
