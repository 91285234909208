import { Box, Button, FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Col, Modal, Row } from 'react-bootstrap'
import { BASE_Url } from '../../URL.js/URL';
import axios from 'axios';
import { useSearchParams } from 'react-router-dom';
import Swal from 'sweetalert2';

const TableChange = (props) => {
    const { onHide, show, tableId } = props;
    const [params, setParams] = useSearchParams();
    const [table, setTable] = useState([]);
    const [selectedTable, setSelectedTable] = useState('');

    const getTables = () => {
        const { storeId, saasId } = JSON.parse(localStorage.getItem("STORE_DATA"));
        const apiUrl = `${BASE_Url}/tableview/table_view/${saasId}/${storeId}`;
    
        // Make the API call
        axios
          .get(apiUrl)
          .then((response) => {
            console.log(response);
            setTable(response.data.data);
          })
          .catch((error) => {
            console.log(error);
          });
      };
      useEffect(() => {
        getTables();
      }, [show]);

     const handleTableChange = () => {
        const apiUrl = `${BASE_Url}/order/changetable/${params.get("orderId")}/${selectedTable}`;
        //complete the API call
        axios
         .put(apiUrl)
         .then((response) => {
            console.log(response);
            if(response.status === 200) {
                Swal.fire('Success', 'Table Changed Successfully','success');
            }
            onHide();
          })
         .catch((error) => {
            console.log(error);
          });
      };


  return (
    <div><Modal {...props} aria-labelledby="contained-modal-title-vcenter" centered>
    <Modal.Body className='p-0'>
        <Row className='m-3'>
            <Col sm={12}>
                <div className='d-flex justify-content-center'>
                    <h4>Change Table</h4>
                </div>
            </Col>
            <Col>
            <Box sx={{ minWidth: 120 }}>
      {table&& table.map((el)=>{return(<FormControl className='my-3' fullWidth>
        <InputLabel id="demo-simple-select-label">{el.category}</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={selectedTable}
          label="Age"
          onChange={(e)=>setSelectedTable(e.target.value)}
        >
          {el?.table_list&& el?.table_list.map((item)=>{return(<MenuItem value={item.table_id}>{item.table_name}</MenuItem>)})}
          
        </Select>
      </FormControl>)})}
    </Box>
            </Col>
            <Col sm={12}>
            <div className='flex justify-around'>
            <Button variant="contained" onClick={onHide}>Close</Button>
            <Button variant="contained" onClick={() => handleTableChange()}>Submit</Button>
               
            </div>
            </Col>
        </Row>
    </Modal.Body>
</Modal></div>
  )
}

export default TableChange