import React, { useEffect, useState } from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { Col } from "react-bootstrap";
import { BsArrowLeft } from 'react-icons/bs';
import { TextField } from '@mui/material';

import Swal from 'sweetalert2';
import axios from 'axios';
import { BASE_Url } from '../../URL.js/URL';
import { useDispatch } from 'react-redux';
import { linkCustomer } from '../../redux/slices/selectOrdersSlice';

const LinkCustomerModal = ({ open, setAddCustomer }) => {
  const dispatch = useDispatch();
  const { storeId, saasId } = JSON.parse(localStorage.getItem("STORE_DATA"));
  const [categories, setCategories] = useState([]);
  const fetchCategories = async () => {
    try {
      const response = await axios.get(`${BASE_Url}/user-master/get-user-details/${saasId}/${storeId}`)
      setCategories(response.data.data);
    } catch (error) {
      console.error('Error fetching categories:', error);
    }
  };
  const handleSave = (customer) => {
    // const customer = categories.find(category => category.name === selectedCustomer);
    if (customer) {
      dispatch(linkCustomer(customer));
      Swal.fire("Customer Linked Successfully");
      // handleCloseModal();
    } else {
      Swal.fire("Failed to Link Customer");
    }
  };
  useEffect(() => {
  fetchCategories();
  }, [saasId, storeId]);

  const [data, setData] = useState({
  "mobile_number": "",
  "email": "",
  "name": "",
  "discount_percent": "",
  "customer_type": "",
  "address_1":"",
  "saas_id": saasId,
  "store_id": storeId,
  })

  // API call for update department
  const AddSalesMan = async () => {
    try {
      //if mobile number is greater then 10 digits or less then 10 digits then display error message
      if(data.mobile_number.length < 10 || data.mobile_number.length > 10){
        Swal.fire({
          title:"Mobile Number should be 10 digits",
          icon:"error",
          timer:2000
        })
        return;
      }
      if(data.mobile_number ){

        const response = await axios.post(`${BASE_Url}/customer/create`,data)
        console.log(response.data);
        if (response.data.status) {
          setAddCustomer(false);
          Swal.fire({
            title:"New Customer Added Successfully",
            icon:"success",
            timer:2000
          })
            handleSave(response.data.data);
          fetchCategories();
        }else{
          Swal.fire({
              title: response.data.message,
              icon: "error",
              timer: 2000
          });
        }
      }else{
        Swal.fire({
          title:"Please Fill All Field",
          icon:"info",
          timer:2000
        })
      }
      setData('')
    } catch (error) {
      console.log("Error:", error);
      Swal.fire({
        title:"New customer have to Unique mobile number",
        icon:"error",
        timer:2000
    })
    }
  };
  

  const handMobailnumberchange=async(e)=>{
    setData({
     ...data,
      mobile_number: e.target.value,
    });
    try {
      if(e.target.value.length == 10 ){
      const response = await axios.get(`${BASE_Url}/customer/getByMobile/${e.target.value}`)
      console.log(response.data.data);
      if (response.data.status) {
        dispatch(linkCustomer(response.data.data));
      Swal.fire({
        title:"Customer Linked Successfully",
        icon:"success",
        timer:2000
      });
      setAddCustomer(false);
        // Swal.fire({
        //   title:"Mobile Number Already Exist",
        //   icon:"error",
        //   timer:2000
        // })
      }
    }
    } catch (error) {
      console.log("Error:", error);
    }
  }

 
 
   const handleonchange = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <div>
      <Modal isOpen={open} toggle={() => setAddCustomer(!open)}>
        <ModalHeader className='d-flex'>
          {/* <BsArrowLeft
            onClick={() => setAddCustomer(!open)}
            className="mouse-pointer"
          /> */}
          Add Customer
        </ModalHeader>
        <ModalBody>
          <div className="row d-flex justify-content-center">
            <div className="">
              <form className="form-box" encType="Content-Type">
                <div className="d-flex flex-col" style={{ display: "flex", flexDirection: "column" }}>
                  <Col md={12}></Col>
                  
                  <TextField
                  required
                  id="outlined-number"
                  type="number"
                size="small"
                className="form-control my-2"
                value={data.mobile_number}
                name='mobile_number'
                onChange={handMobailnumberchange }
                label="Mobile Number"
                // rows={1}
              />
              <TextField
                size="small"
                type="text"
                className="form-control my-2"
                value={data.name}
                name='name'
                onChange={handleonchange }
                label="Enter Customer Name"
                multiline
                required
                rows={1}
              />
              <TextField
                size="small"
                type="email"
                className="form-control my-2"
                value={data.email}
                name='email'
                onChange={handleonchange }
                label="Email"
                multiline
                required
                rows={1}
              />
              <TextField
                size="small"
                type="number"
                className="form-control my-2"
                value={data.address_1}
                name='address_1'
                onChange={handleonchange }
                label="Enter Address"
                multiline
                required
                rows={1}
              />
                </div>
                <div className="mt-2">
                  <button
                    className=''
                    type="button"
                    style={{
                      backgroundColor: "rgb(46, 69, 175)",
                      outline: "none",
                      border: "none",
                      fontSize: "20px",
                      padding: "10px 20px",
                      borderRadius: "10px",
                      color: "#fff",
                    }}
                    onClick={AddSalesMan}
                  >
                    Add Customer
                  </button>
                  <span
                    onClick={() => setAddCustomer(!open)}
                    className="btn btn-primary mb-2"
                    style={{
                      backgroundColor: "grey",
                      outline: "none",
                      border: "none",
                      marginLeft: "20px",
                      fontSize: "20px",
                      padding: "10px 20px",
                      borderRadius: "10px",
                      color: "#fff",
                    }}
                  >
                    Close
                  </span>
                </div>
              </form>
            </div>
          </div>
        </ModalBody>
        {/* <ModalFooter><h1>FOOTER</h1></ModalFooter> */}
      </Modal>
    </div>
  );
};

export default LinkCustomerModal;
