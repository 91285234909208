// MainLayout.js
import React, { useState } from "react";
import Navbar from "./Navbar";
import Sidebar from "./Sidebar";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const SidebarLayout = ({ children }) =>{
  const navigate = useNavigate();
  const Token= JSON.parse(localStorage.getItem("Token"));
  useEffect(() => {
    if(!Token){
     navigate('/login')
    }
  }, [])
  const [toggle , setToggle]= useState(true)
  return(
    <>
 { Token &&<div className="App flex-row  flex">
    <Sidebar toggle={toggle} setToggle={setToggle} />
    <div className="flex flex-col w-full">
      <Navbar toggle={toggle} setToggle={setToggle} />
      {children}
    </div>
  </div>}
  </>
);}

export default SidebarLayout;
