import { FaPlus, FaCheck } from 'react-icons/fa';
import { useState, useRef } from 'react';

const ImageSelector = ({
  onImageSelect,
  isSelected,
  imageUrl: providedImageUrl,
  onSelect,
  index,
}) => {
  const [localImageUrl, setLocalImageUrl] = useState(null);
  const fileInputRef = useRef(null);

  const imageUrl = providedImageUrl || localImageUrl;

  const handleImageSelect = (event) => {
    const file = event.target.files?.[0];
    if (file) {
      const newImageUrl = URL.createObjectURL(file);
      setLocalImageUrl(newImageUrl);
      onImageSelect?.(file);
    }
  };

  const handleClick = () => {
    if (imageUrl) {
      onSelect?.();
    } else {
      fileInputRef.current?.click();
    }
  };

  return (
    <div
      className={`relative w-[150px] h-[150px] rounded-lg overflow-hidden ${
        imageUrl ? 'cursor-pointer hover:ring-2 hover:ring-blue-400' : ''
      } ${isSelected ? 'ring-2 ring-blue-500' : ''}`}
      onClick={handleClick}
    >
      <input
        type="file"
        ref={fileInputRef}
        className="hidden"
        accept="image/*"
        onChange={handleImageSelect}
      />
      {imageUrl ? (
        <div className="relative w-full h-full">
          <img
            src={imageUrl}
            alt={`Image ${index + 1}`}
            className="w-full h-full object-cover"
          />
          {isSelected && (
            <div className="absolute inset-0 bg-blue-500 bg-opacity-40 flex items-center justify-center">
              <FaCheck className="w-8 h-8 text-white" />
            </div>
          )}
        </div>
      ) : (
        <button
          className="w-full h-full bg-gray-100 hover:bg-gray-200 transition-colors flex items-center justify-center border-2 border-dashed border-gray-300 rounded-lg"
        >
          <FaPlus className="w-6 h-6 text-gray-400" />
        </button>
      )}
    </div>
  );
};

export default ImageSelector;
