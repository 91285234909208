import React, { useState } from "react";

import Button from "@mui/joy/Button";

import Modal from "@mui/joy/Modal";
import ModalDialog from "@mui/joy/ModalDialog";

import Add from "@mui/icons-material/Add";

import { useDispatch, useSelector } from "react-redux";
import { addCustomerDetails, addDate } from "../redux/slices/tableSlice";

const ModalCustomer = () => {
  const [open, setOpen] = useState(false);

  const dispatch = useDispatch();
  // for add customer api
  const [customerData, SetCustomerData] = useState({
    mobile_number: "",
    customer_name: "",
    address: "",
    payment_mode: "Due",
    split_amount: "0",
  });
  const handleCancel = () => {
    setOpen(false); // Close the modal when the "cancel" button is clicked
  };

  const doneCustomer = useSelector((state) => {
    return state.tableData.createCustomer;
  });
  console.log(doneCustomer);
  const addCustomer = (e) => {
    dispatch(addCustomerDetails(e.target.value));
  };
  function formatDateToYYYYMMDD(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based, so add 1 and pad with leading zeros if needed
    const day = String(date.getDate()).padStart(2, "0"); // Pad with leading zeros if needed
    return `${year}-${month}-${day}`;
  }
  const currentDate = new Date();
  const formattedDate = formatDateToYYYYMMDD(currentDate);

  // const selector = useSelector();
  const handleCustomerSave = () => {
    dispatch(addCustomerDetails(customerData));
    dispatch(addDate(formattedDate));
  };
  return (
    <div>
      <Button
        variant="outlined"
        color="neutral"
        startDecorator={<Add />}
        onClick={() => setOpen(true)}
      >
      Link Loyalty Member Enrollment
      </Button>
      <Modal open={open} onClose={() => setOpen(false)}>
  <ModalDialog
    aria-labelledby="basic-modal-dialog-title"
    aria-describedby="basic-modal-dialog-description"
    className="w-full md:w-[518px]"
  >
    <div className="flex flex-col w-full justify-end border rounded-[12px]">
      <div className="border-b-2 flex justify-between font-semibold p-2">
        Add Customer
        <button
          className="border-spacing-6 font-bold "
          onClick={() => setOpen(false)}
        >
          X
        </button>
      </div>

      <div className="p-2">
        <div className="mb-4">
          <input
            type="number"
            onChange={(e) =>
              SetCustomerData({
                ...customerData,
                mobile_number: e.target.value,
              })
            }
            className="p-1 border border-dark-grey rounded outline-none w-full"
            placeholder="Enter your Mobile"
          />
          <span>Mobile</span>
        </div>
        <div className="mb-4">
          <input
            onChange={(e) =>
              SetCustomerData({
                ...customerData,
                customer_name: e.target.value,
              })
            }
            type="text"
            className="p-1 border border-dark-grey rounded outline-none w-full"
            placeholder="Enter your Name"
          />
          <span>Name</span>
        </div>
        <div className="mb-4">
          <textarea
            onChange={(e) =>
              SetCustomerData({
                ...customerData,
                address: e.target.value,
              })
            }
            className="border border-dark-grey rounded outline-none p-2 w-full"
            placeholder="Enter Customer's Address"
          />
          <span>Address</span>
        </div>
        <div className="flex flex-row-reverse">
          <button
            className="bg-dark-purple mx-2 flex rounded p-2 text-xs font-medium uppercase leading-normal text-white shadow-md transition duration-150 ease-in-out hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 "
            onClick={handleCustomerSave}
          >
            Done
          </button>
          <button
            className="bg-light-grey mx-2 flex rounded p-2 text-xs font-medium uppercase leading-normal text-black shadow-md transition duration-150 ease-in-out hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg"
            onClick={handleCancel}
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  </ModalDialog>
</Modal>

    </div>
  );
};

export default ModalCustomer;
