import React, { useEffect, useState } from 'react'
import FeedbackComponent from './FeedbackComponent'
import Header from '../Opt/Header'
import FeedBackQuetion from './FeedBackQuetion'
import Customerfeedback from './Customerfeedback'
import SuccessPage from './SuccessPage'
import { useParams } from 'react-router-dom'
import { NPS_Url } from '../../URL.js/URL'
import axios from 'axios'
import { useDispatch, useSelector } from 'react-redux'
   import {  clearFeedback, setFeedbackQuestionAndSubQuestion} from "../../redux/slices/tableSlice"
   import {setFeedbackCustomerDetails} from "../../redux/slices/tableSlice"
import moment from 'moment/moment'
const Feedlayout = () => {
  const params = useParams();
  const { saas_id } = params;
  const [questions, setQuestions] = useState([])
  const [expanded, setExpanded] = useState(false)
  const [step , setStep] = useState(1)
  const dispatch = useDispatch()
  const {feedback} = useSelector((state) => {
    return state.tableData.createCustomer;
  });

//set question and subQuestion in setFeedback state by get in function perameter
const setQuestionAndSubQuestion = (question, subQuestion) => {
  const data ={
    saasId: saas_id,
    question:question,
    subQuestion:subQuestion,
    feedbackDate:moment(new Date()).format('YYYY-MM-DDTHH:mm:ss.SSSSSSSSS')
  }
   dispatch(setFeedbackQuestionAndSubQuestion(data))
  // setFeedback(prevState => ({...prevState, question, subQuestion }))
}
  const handlenext = () => {
    setStep(step + 1)
  }
  const {ratingLevel} = feedback
  const GetAllQuestion = async ()=>{
    try {
      // Call your API here to get all questions
      const response = await axios.get(`${NPS_Url}/SubQuestion/get-Allby-saasId-level/${saas_id}/${ratingLevel}`)
      const data = response.data.data
      console.log("questions: ", data)
      setQuestions(data)
    } catch (error) {
      console.error('Error fetching questions:', error)
    }
  }
  useEffect(()=>{
    if(ratingLevel == null){
      return;
    }
    GetAllQuestion()
  }, [ratingLevel])
  useEffect(()=>{
    if(feedback){
    console.log(feedback)
    }
  }, [feedback])


  //Api Hit to Sumbit Feedback after setFeedbackCustomerDetails call complete 

  const submitFeedback = async (data) => {
  try {
    // Dispatch data to Redux state and wait for the state update.
    await dispatch(setFeedbackCustomerDetails(data));

    // Fetch the latest state (optional step) to ensure correct data
    const latestFeedback = feedback;  // If feedback is already from useSelector, this is not needed

    // Log the feedback to verify before sending the API request.
    console.log("Feedback Submitted: ", latestFeedback);

    // Call the API with updated feedback
    const response = await axios.post(`${NPS_Url}/feedback/add`, latestFeedback);
    if (response.data.status) {
      handlenext();
      dispatch(clearFeedback());
    }
  } catch (error) {
    console.error("Error submitting feedback:", error);
  }
};



  const RenderComponentBystep=() => {
    switch(step){
      case 1:
        return <FeedbackComponent handlenext={handlenext}   />
      case 2:
        return <FeedBackQuetion questions={questions}  setQuestionAndSubQuestion={setQuestionAndSubQuestion}  handlenext={handlenext} expanded={expanded} setExpanded={setExpanded}/>
      case 3:
        return <Customerfeedback submitFeedback={submitFeedback}  />
      default:
        return <SuccessPage />
    }
  }
  return (
    <div>
        <Header/>
        <RenderComponentBystep/>
    </div>
  )
}

export default Feedlayout