import React from "react";
import {
  Card,
  CardBody,
  CardFooter,
  Typography,
} from "@material-tailwind/react";
import { Switch } from "@material-tailwind/react";

// const TF2 = [
//   {
//     Name: "RAjendra Sharma",
//     Status: "Dine-in",
//     Items: "Fruits",
//   },
// ];

const Orders = () => {
  return (
    <>
      <div className="flex flex-col mt-4">
        <h3 className="text-start p-2 m-4 font-medium text-xl font-['inter']">
          Orders
        </h3>
        <div className="flex m-5 border-x-2">
          <Card className="mt-6 m-5  w-1/5 h-72 bg-red-500">
            <CardBody>
              <Typography className="mb-2">Table TF2</Typography>
              <Typography className="border-b-2">Rajendra Sharma</Typography>
              {/* <Switch label="Automatic Update" />
               */}
              <div className="space-x-8">
                <Switch label="Ripple Effect On" ripple={true} />
                <Switch label="Ripple Effect Off" ripple={false} />
              </div>
            </CardBody>
            <CardFooter className="pt-0 ">update Order</CardFooter>
          </Card>
          <Card className="mt-6 w-1/5 h-72 bg-red-500">
            <CardBody>
              <Typography className="mb-2">Table TF2</Typography>
              <Typography className="border-b-2">Rajendra Sharma</Typography>
            </CardBody>
            <CardFooter className="pt-0 ">update Order</CardFooter>
          </Card>
        </div>
      </div>
    </>
  );
};

export default Orders;
