import React, { useState } from 'react';
import { 
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableHead, 
  TableRow, 
  Paper, 
  Button, 
  TextField
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';  // Import Edit Icon
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { BASE_Url } from '../../URL.js/URL';
import axios from 'axios';

const DeleteCategory = ({DeleteCategorybyid, categories,getCatogery}) => {
  const [modal, setModal] = useState(false); // For "All Category" modal
  const [editModal, setEditModal] = useState(false); // For "Edit Category" modal
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [updatedCategoryName, setUpdatedCategoryName] = useState("");

  const toggle = () => setModal(!modal); // For "All Category" modal
  const toggleEditModal = () => setEditModal(!editModal); // For "Edit Category" modal

  const handleEditClick = (category) => {
    setSelectedCategory(category);
    setUpdatedCategoryName(category.category_name); // Set initial value of the category name
    toggleEditModal(); // Open Edit Modal
  };

  const handleSave = async () => {
    if (selectedCategory) {
      try {
        const response = await axios.put(
          `${BASE_Url}/item/update-category/${selectedCategory.category_id}`,
          {
            categoryName: updatedCategoryName,  // Only updating category name
            saasId: selectedCategory.saas_id,   // Assuming it's the same for simplicity
            storeId: selectedCategory.store_id, // Assuming it's the same for simplicity
            status: selectedCategory.status     // Assuming it's the same for simplicity
          },
          {
            headers: {
              'Content-Type': 'application/json',
            }
          }
        );

        if (response.status === 200) {
          console.log("Category updated successfully");
          getCatogery()
          toggleEditModal(); // Close the Edit modal after success
        }
      } catch (error) {
        console.error("Error updating category:", error);
      }
    }
  };

  return (
    <>
      <Button  
        style={{background:"#0E2954"}}  
        className="text-white semibold rounded md:mt-0 mx-2"
        onClick={toggle} // Open "All Category" modal
      >
        Edit Category
      </Button>

      {/* "All Category" modal */}
      <Modal isOpen={modal} toggle={toggle} >
        <ModalHeader toggle={toggle}>All Category</ModalHeader>
        <ModalBody>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 500 }} aria-label="category table">
              <TableHead>
                <TableRow>
                  <TableCell>Category ID</TableCell>
                  <TableCell>Category Name</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {categories.map((category) => (
                  <TableRow key={category.category_id}>
                    <TableCell>{category.category_id}</TableCell>
                    <TableCell>{category.category_name}</TableCell>
                    <TableCell>
                      <Button
                        variant="contained"
                        color="primary"
                        startIcon={<EditIcon />}  // Use Edit Icon
                        onClick={() => handleEditClick(category)}  // Open Edit modal
                      >
                        Edit
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </ModalBody>
      </Modal>

      {/* "Edit Category" modal */}
      {selectedCategory && (
        <Modal isOpen={editModal} toggle={toggleEditModal}>
          <ModalHeader toggle={toggleEditModal}>Edit Category</ModalHeader>
          <ModalBody>
            <TextField
              label="Category Name"
              fullWidth
              value={updatedCategoryName}
              onChange={(e) => setUpdatedCategoryName(e.target.value)}
            />
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={handleSave}>
              Save
            </Button>
            <Button color="secondary" onClick={toggleEditModal}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      )}
    </>
  );
}

export default DeleteCategory;
