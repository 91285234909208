import React, { useEffect, useState } from 'react';
import { Card, CardContent, Typography, TextField, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import { styled } from '@mui/system';
import axios from 'axios';
import { BASE_Url } from '../../URL.js/URL';
import moment from 'moment';
import { Tab, Tabs } from 'react-bootstrap';
import BahikhataReport from '../Reports/BahikhataReport';
import ExpeseReport from '../Reports/ExpeseReport';

const StyledCard = styled(Card)({
  maxWidth: '800px',
  margin: 'auto',
  border: '2px solid #b8afaf',
});

const StyledTextField = styled(TextField)({
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#b8afaf',
    },
    '&:hover fieldset': {
      borderColor: '#b8afaf',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#b8afaf',
    },
  },
  '& .MuiInputLabel-root': {
    color: '#b8afaf',
    fontWeight: 'bold',
  },
});

const StyledTableCell = styled(TableCell)({
  color: '#b8afaf',
  fontWeight: 'bold',
});

const Statement = () => {
  const [searchDate, setSearchDate] = useState(moment().format('YYYY-MM-DD'));
  const [toDate, setToDate] = useState(moment().format('YYYY-MM-DD'));
  const { saasId, storeId } = JSON.parse(localStorage.getItem('STORE_DATA'));
  const [transactions, setTransactions] = useState([]);
  const [expenses, setExpenses] = useState([]);
  const [activeTab, setActiveTab] = useState('bahikhata');

  // Fetch Bahikhata data
  const fetchBahikhataData = async () => {
    try {
      const apiUrl = `${BASE_Url}/bahikhata/view-bahikhata-details/${saasId}/${storeId}/${searchDate}/${toDate}`;
      const response = await axios.get(apiUrl);
      setTransactions(response.data.data);
    } catch (error) {
      console.error('Error fetching Bahikhata data:', error);
    }
  };

  // Fetch Expenses data
  const fetchExpensesData = async () => {
    try {
      const apiUrl = `${BASE_Url}/expense/view-expenses/${saasId}/${searchDate}/${toDate}`;
      const response = await axios.get(apiUrl);
      setExpenses(response.data.data);
    } catch (error) {
      console.error('Error fetching Expenses data:', error);
    }
  };

  useEffect(() => {
    if (activeTab === 'bahikhata') {
      fetchBahikhataData();
    } else if (activeTab === 'expenses') {
      fetchExpensesData();
    }
  }, [searchDate, toDate, activeTab]);

  const renderTable = (data) => (
    <TableContainer component={Paper} style={{ height: '250px', marginBottom: '10px' }}>
      <Table>
        <TableHead>
          <TableRow>
            <StyledTableCell>DATE</StyledTableCell>
            <StyledTableCell>MODE</StyledTableCell>
            <StyledTableCell>TYPE</StyledTableCell>
            <StyledTableCell>Party NAME</StyledTableCell>
            <StyledTableCell>Amount</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((item, index) => (
            <TableRow key={index}>
              <TableCell>{item.paymentDate}</TableCell>
              <TableCell>{item.paymentMode}</TableCell>
              <TableCell>{item.paymentType}</TableCell>
              <TableCell>{item.partyName}</TableCell>
              <TableCell>{item.amount?.toFixed(2)}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );

  const renderExpensesTable = () => (
    <TableContainer component={Paper} style={{ height: '250px', marginBottom: '10px' }}>
      <Table>
        <TableHead>
          <TableRow>
            <StyledTableCell>CATEGORY</StyledTableCell>
            <StyledTableCell>DATE</StyledTableCell>
            <StyledTableCell>AMOUNT</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {expenses.map((item, index) => (
            <TableRow key={index}>
              <TableCell>{item.category_name}</TableCell>
              <TableCell>{item.expense_date}</TableCell>
              <TableCell>{item.total_amount?.toFixed(2)}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );

  return (
    <StyledCard>
      <Tabs
        activeKey={activeTab}
        onSelect={(key) => setActiveTab(key)}
        id="uncontrolled-tab-example"
        className="mb-3  fw-bold"
        fill
        style={{background: '#FFF',
          boxShadow: "4px 4px 10px 0px rgba(0, 0, 0, 0.25)"
          }}
      >
        <Tab eventKey="bahikhata" title="Bahikhata Statement">
          {/* Content for Bahikhata tab */}
        </Tab>
        <Tab eventKey="expenses" title="Expense Statement">
          {/* Content for Expenses tab */}
        </Tab>
      </Tabs>
      <CardContent>
        <div className="flex justify-between gap-2">
          <StyledTextField
            label="From Date"
            type="date"
            value={searchDate}
            onChange={(e) => setSearchDate(e.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
            fullWidth
            margin="normal"
          />
          <StyledTextField
            label="To Date"
            type="date"
            value={toDate}
            onChange={(e) => setToDate(e.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
            fullWidth
            margin="normal"
          />
        </div>
        {activeTab === 'bahikhata' ? (
          <>
           <BahikhataReport  startdate={searchDate} todate={toDate}  />
            {transactions.length > 0 && (
              <Typography style={{ marginTop: '20px', color: '#b8afaf' }}>
                <strong>Payment Notes:</strong> {transactions[0]?.paymentNotes}
              </Typography>
            )}
          </>
        ) : (
          <ExpeseReport  formDate={searchDate} toDate={toDate} />
        )}
      </CardContent>
    </StyledCard>
  );
};

export default Statement;
