import React from 'react';
import { 
  Box, 
  Card, 
  CardContent, 
  CardActions, 
  Typography, 
  Button, 
  ThemeProvider, 
  createTheme 
} from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { useNavigate, useParams } from 'react-router-dom';

// Create a custom theme to match the color scheme
const theme = createTheme({
  palette: {
    primary: {
      main: '#4caf50', // Green color for success
    },
  },
});

export default function SuccessPage() {
  const params = useParams();
  const { table_id, saas_id, store_id } = params;
  const navigate = useNavigate()
  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <Card sx={{ maxWidth: 300, textAlign: 'center' }}>
          <CardContent>
            <Box sx={{ mb: 2 }}>
              <CheckCircleOutlineIcon color="primary" sx={{ fontSize: 60 }} />
            </Box>
            <Typography variant="h5" component="div" color="primary" gutterBottom>
              Success!
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Thanks for your valuable feedback.
            </Typography>
          </CardContent>
          <CardActions sx={{ justifyContent: 'center', pb: 2 }}>
            <Button onClick={()=> navigate(`/${saas_id}/${store_id}/${table_id}`)} variant="outlined" color="primary" fullWidth>
              Close
            </Button>
          </CardActions>
        </Card>
      </Box>
    </ThemeProvider>
  );
}