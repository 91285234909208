import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import chola from "../../images/chola.jpg";
import axios from "axios";
import { BASE_Url } from "../../URL.js/URL";
import Swal from "sweetalert2";
import { useParams } from "react-router-dom";
import { BsTrash3 } from "react-icons/bs";
import { Button } from "@mui/material";
import { FaMinus, FaPlus, FaRupeeSign, FaTrashAlt } from "react-icons/fa";
import { FaCartShopping } from "react-icons/fa6";
const Cart = ({
  show,
  setShow,
  cartData,
  setCartData,
  setCount,
  table_id,
  catgoryName,
}) => {
  // const {bankAccount} = JSON.parse(localStorage.getItem('STORE_DATA'))
  const handleClose = () => {
    setShow(false);
  };

  const updateCartInLocalStorage = (updatedCart) => {
    localStorage.setItem("my-cart", JSON.stringify(updatedCart));
  };

  const { saas_id, store_id } = useParams();

  const increment = (item) => {
    const q = item.item_qty + 1;
    item.item_qty = q;
    const newP = item.item_price * q;
    item.new_price = newP;

    cartData.map((item) => {
      item.item_newprice = item.item_price * item.item_qty;
    });
    setCartData([...cartData]);
    updateCartInLocalStorage(cartData);
  };

  //decriment function
  const decrement = (item) => {
    if (item.item_qty === 1) {
      item.item_qty = 1;
      item.item_price = item.item_price;
    } else {
      const q = item.item_qty - 1;
      item.item_qty = q;
      const newP = item.item_price * q;
      item.new_price = newP;
    }
    cartData.map((item) => {
      item.item_newprice = item.item_price * item.item_qty;
    });
    setCartData([...cartData]);
    updateCartInLocalStorage(cartData);
  };

  const ConsfirmOrder = () => {
    const currentDate = new Date().toISOString().split("T")[0];
    if (cartData.length > 0) {
      axios
        .post(`${BASE_Url}/order/create`, {
          order_date: currentDate,
          saas_id: saas_id,
          table_id: table_id,
          store_id: store_id,
          status: "preparing",
          order_type: "Dine in",
          order_details: cartData,
          add_customer: [],
          approval_status: "Accept",
        })
        .then((res) => {
          console.log("SAVE CREATE ORDER", res);
          if (res.status === 200) {
            console.log("this is my order", res.data.data);
            localStorage.setItem("my-order", JSON.stringify(res.data.data));
            localStorage.removeItem("my-cart");
            Swal.fire("Good job", `Your Order is confirmed `, "success");
            setCartData([]);
            setCount(0);
            handleClose();
          }
        });
    } else {
      Swal.fire("Not Allow", `please add some item in cart `, "error");
    }
  };

  const handleDeleteCartItem = (item) => {
    const getData = JSON.parse(localStorage.getItem("my-cart"));
    console.log(getData);
    if (getData) {
      if (getData?.length > 0) {
        if (getData?.length > 1) {
          const updateCart = getData.filter((el) =>
            el.item_id
              ? el.item_id !== item.item_id
              : el.productId !== item.productId
          );

          localStorage.setItem("my-cart", JSON.stringify(updateCart));
          setCartData(updateCart);
          // dispatch(handlecartCount(updateCart?.length));
        } else {
          localStorage.removeItem("my-cart");
          setCartData([]);
          // dispatch(handlecartCount(0));
        }
      }
    }
  };
  const calculateTotal =  cartData && cartData?.reduce((total, item) => total + item.item_price * item.item_qty, 0)
  
 
  
  
  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        animation={false}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>My Basket</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        {cartData?.length> 0 ? <div className="max-w-md mx-auto bg-white p-6 rounded-lg shadow">
          {cartData.map((item) => (
          <div key={item.id} className="mb-4">
          <div className="flex items-center justify-between mb-2">
            <div className="flex items-center">
              <img
                src={`${BASE_Url}/item/get-image/${item.item_id}`}
                // alt={item.name}
                className="w-10 h-10 rounded-full mr-3"
              />
              <span>{item.item_name}</span>
            </div>
            <span className="font-semibold">&#8377;{item?.item_newprice ? item?.item_newprice : item?.item_price}</span>
          </div>
          <div className="flex items-center justify-between">
            <div className="flex items-center border rounded-md">
              <button
                onClick={() => decrement(item)}
                className="p-2 text-gray-600 hover:text-gray-800"
              >
                <FaMinus className="w-4 h-4" />
              </button>
              <span className="px-4 py-2 text-center w-12"> 
              {item.item_qty}
              </span>
              <button
                onClick={() => increment(item)}
                className="p-2 text-gray-600 hover:text-gray-800"
              >
                <FaPlus className="w-4 h-4" />
              </button>
            </div>
            <button onClick={() => handleDeleteCartItem(item)} className="text-red-500 hover:text-red-700">
              <FaTrashAlt className="w-5 h-5" />
            </button>
          </div>
        </div>
    ))}
     {/* <div className="mb-4">
        <button className="text-green-600 hover:text-green-800">
          Add Order Instructions
        </button>
      </div> */}
      {/* <div className="bg-gray-100 p-3 rounded-md mb-6">
        <span className="font-semibold">You are Currently on the C4</span>
      </div> */}
      <div className="border-t pt-4">
        <h3 className="font-semibold mb-2">Order Summary</h3>
        <div className="flex justify-between mb-2">
          <span>Subtotal</span>
          <span>Rs {calculateTotal?.toFixed(2)}</span>
        </div>
        <div className="flex justify-between font-semibold">
          <span>Amount to pay</span>
          <span>Rs {calculateTotal?.toFixed(2)}</span>
        </div>
      </div>
      <button  onClick={ConsfirmOrder} className="w-full bg-green-500 text-white py-3 rounded-md mt-6 hover:bg-green-600 transition-colors">
        Confirm Order
      </button>
      </div>:<>
      <div className="max-w-md mx-auto bg-white p-6 rounded-lg shadow text-center">
        <FaCartShopping className="w-16 h-16 mx-auto text-gray-400 mb-4" />
        <h2 className="text-xl font-semibold mb-2">Your cart is empty</h2>
        <p className="text-gray-600 mb-4">Add some items to your cart to get started!</p>
        <button onClick={handleClose} className="bg-green-500 text-white py-2 px-4 rounded-md hover:bg-green-600 transition-colors">
          Browse Menu
        </button>
      </div>
      </>}
          {/* {cartData &&
            cartData.map((item) => (
              <div className="flex flex-row items-center bg-white border   md:flex-row mt-1 ">
                <div className="mx-1 h-24 w-24 flex-shrink-0 overflow-hidden rounded-md border border-gray-200">
                  <img
                    className="h-full w-full object-cover object-center"
                    src={`${BASE_Url}/item/get-image/${item.item_id}`}
                  />
                </div>

                <div className="flex flex-col justify-betweenleading-normal mx-2 mb-1">
                  <p
                    className="mb-2  font-medium lowercase tracking-tight text-gray-900 dark:text-black fw-bolder"
                    style={{ position: "relative", top: "20px" }}
                  >
                    {item.item_name}
                  </p>
                  <div
                    className="d-flex"
                    style={{
                      position: " relative",
                      top: "70px",
                      right: "-162px",
                    }}
                  >
                    <BsTrash3
                      onClick={() => {
                        handleDeleteCartItem(item);
                      }}
                    />
                  </div>
                  <p className="mt-1 text-sm text-gray-500">
                    Qty:{item.item_qty}
                  </p>
                  <div className="inline-flex h-fit ">
                    <button
                      style={{
                        boxShadow: "0 2px 12px rgba(36, 36, 39, 0.12)",
                        borderBottomLeftRadius: "0.75rem",
                        borderTopLeftRadius: "0.75rem",
                      }}
                      className="bg-dark-purple text-xl hover:bg-gray-400 text-white font-bold py-1 px-3 rounded-l"
                      onClick={() => increment(item)}
                    >
                      +
                    </button>
                    <input
                      value={item.item_qty}
                      className="w-10 text-xl  text-center border-solid border-2 "
                    ></input>
                    <button
                      style={{
                        boxShadow: "0 2px 12px rgba(36, 36, 39, 0.12)",
                        borderBottomRightRadius: "0.75rem",
                        borderTopRightRadius: "0.75rem",
                      }}
                      className="bg-dark-purple text-xl hover:bg-gray-400 text-white font-bold py-1 px-3 rounded-r"
                      onClick={() => {
                        decrement(item);
                      }}
                    >
                      -
                    </button>
                  </div>
                </div>
                <h5
                  className="mb-2 text-xl font-medium tracking-tight text-gray-900 dark:text-black  pr-4"
                  style={{ position: "relative", right: "88px", top: "5px" }}
                >
                  &#8377;
                  {item?.item_newprice ? item?.item_newprice : item?.item_price}
                </h5>
              </div>
            ))} */}
        </Modal.Body>
        <Modal.Footer>
          <div className="flex justify-around w-100">
            <Button
              className="bg-secondary text-white"
              variant="secondary"
              onClick={handleClose}
            >
              Close
            </Button>
            <Button
              className="text-nowrap"
              type="button"
              style={{
                backgroundColor: "rgb(169, 10, 10)",
                border: "none",
                color: "white",
                fontWeight: "bold",
              }}
              // id="pop112"
              onClick={() => {
                localStorage.removeItem("my-cart");
                setCartData([]);
                handleClose();
              }}
            >
              Remove All
            </Button>
            {/* <Button
              className="bg-primary text-white  text-nowrap"
              variant="primary"
             
            >
              Confirm Order
            </Button> */}
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Cart;
