import React, { useState } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import { Container } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import { Grid, TextField, Button, Typography } from '@mui/material';
import { useDispatch } from 'react-redux';
import { linkloylity } from '../../redux/slices/selectOrdersSlice';

function LinkLoyaltyModal(props) {
    const dispatch = useDispatch();
    const { storeId, saasId } = JSON.parse(localStorage.getItem("STORE_DATA"));
  const [mobile, setMobile] = useState('');
  const [username, setUsername] = useState('');

  const handleCloseModal = () => {
    setUsername("")
    setMobile("")
    props.onHide();
  };

  const handleSave = () => {
    handleCloseModal();
  };

  const handleSearch = async (e) => {
    // const mobileNumber = e.target.value;
    // setMobile(mobileNumber);
    if (mobile) {
      try {
        const response = await axios.post('http://103.148.165.246:8094/test/v1/loyalty/customer-details', {
          base_currency: "INR",
          mobile_number: mobile,
          email_id: "",
          customer_id: "",
          client_id: saasId
        });
     
        if (response.status=="200") {
            dispatch(linkloylity(response.data));
            setUsername(response.data);
            console.log("nodatafirst", response)
        }
           
     
        // const data = response.data;
        // localStorage.setItem("Loyalty_data", JSON.stringify(data));
      
      } catch (error) {
        console.error('Error fetching customer details:', error);
        // Swal.fire({
        //   icon: 'error',
        //   title: 'Error',
        //   text: 'Failed to fetch customer details.',
        // });
      }
    }
  };

  return (
    <Modal
      {...props}
      onHide={handleCloseModal}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header className="container" closeButton>
        <Modal.Title>Link Loyalty Customer</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <TextField
                type="number"
                className="form-control"
                label="Mobile"
                value={mobile}
                onChange={(e) => setMobile(e.target.value)}
              />
            </Grid>
          </Grid>
          {username && (
            <Grid container spacing={2} className="mt-3">
              <Grid item xs={12} md={12}>
                <Typography variant="h6">Customer Name:</Typography>
                <Typography variant="body1">{username.customer_name}</Typography>
              </Grid>
              <Grid item xs={12} md={12}>
                <Typography variant="h6">Mobile Number:</Typography>
                <Typography variant="body1">{username.mobile_number}</Typography>
              </Grid>
              <Grid item xs={12} md={12}>
                <Typography variant="h6">Email ID:</Typography>
                <Typography variant="body1">{username.email_id}</Typography>
              </Grid>
            </Grid>
          )}
        </Container>
      </Modal.Body>
      <Modal.Footer>
       {!username &&<Button  className='mx-2' variant="contained" color="primary" onClick={handleSearch}>
          Save
        </Button>}
        <Button variant="contained" color="secondary" onClick={handleCloseModal}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default LinkLoyaltyModal;
