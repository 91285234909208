import React, { useState } from 'react'
import { TextField } from '@mui/material'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'

import { useDispatch } from 'react-redux';

const Customerfeedback = ({submitFeedback }) => {
  
  const [customerDetails, setCustomerDetails ] = useState({
    customer_name: "",
    customer_mobile: "",
    customer_email: ""
  });
  //on handle change call setFeedbackCustomerDetails function to set customer details in redux state
  const handleCustomerDetails = (e) => {
    setCustomerDetails({...customerDetails, [e.target.name]: e.target.value });
   
  };
 

  return (
    <div className="max-w-md mx-auto bg-white min-h-screen">
      <div className="p-6">
        <h2 className="font-medium mb-6">Customer Details</h2>
        <form className="space-y-6">
          <TextField
            fullWidth
            value={customerDetails.customer_name}
            onChange={handleCustomerDetails}
            name="customer_name"
            label="Full Name"
            placeholder="Test Patel"
            variant="outlined"
            size="small"
          />
          <TextField
            fullWidth
            value={customerDetails.customer_mobile}
            onChange={handleCustomerDetails}
            name="customer_mobile"
            label="Mobile"
            placeholder="+91 9876543210"
            variant="outlined"
            size="small"
          />
          <div className="flex items-center gap-4">
            <div className="h-px bg-gray-200 flex-1" />
            <span className="text-gray-500 text-sm">Or</span>
            <div className="h-px bg-gray-200 flex-1" />
          </div>
          <TextField
            fullWidth
            value={customerDetails.customer_email}
            onChange={handleCustomerDetails}
            label="Email"
            name="customer_email"
            placeholder="xyz123@gmail.com"
            variant="outlined"
            size="small"
          />
          <button
            type="submit"
            className="w-full flex items-center justify-center gap-2 bg-white border border-gray-300 rounded-md py-2 hover:bg-gray-50 transition-colors"
            onClick={(e) => {
              e.preventDefault();
              submitFeedback(customerDetails);
            }}
          >
            Proceed
            <ChevronRightIcon fontSize="small" />
          </button>
        </form>
      </div>
    </div>
  );
};

export default Customerfeedback;
