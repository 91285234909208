// MainLayout.js
import React from "react";
import Navbar from "./Navbar";

const MainLayout = ({ children }) => (
  <div className="App flex-row  flex">
    <div className="flex flex-col w-full">
      <Navbar />
      {children}
    </div>
  </div>
);

export default MainLayout;
