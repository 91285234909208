import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { useDispatch } from 'react-redux';
import { getUpdateRoleList } from '../redux/slices/userManagement';
import { Box, Button, FormControl, Grid, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material';
import { BASE_Url } from '../URL.js/URL';
const RoleMaster = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [roleData, setRoleData] = useState({
    role_name: "",
    function_name: "",
    allowed_or_not: "",
    security_id: "",
  });

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const roleId = queryParams.get('roleId');
  const saasId = queryParams.get('saasId');
  const storeId = queryParams.get('storeId');

  useEffect(() => {
    if (roleId && saasId && storeId) {
      const EditApi = `${BASE_Url}/staff-user/get-by-staffid/${saasId}/${storeId}/${roleId}`;
      axios.get(EditApi)
        .then((response) => {
          setRoleData({
            role_name: response.data.data.role_name,
            security_id: response.data.data.security_id,
            function_name: response.data.data.function_name,
            allowed_or_not: response.data.data.allowed_or_not,
          });
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [storeId, saasId, roleId]);

  const handleChange = (e) => {
    setRoleData({ ...roleData, [e.target.name]: e.target.value });
  };

  const handleSubmit = () => {
    // Check if all required fields are filled
    if (!roleData.role_name || !roleData.security_id || !roleData.function_name || !roleData.allowed_or_not) {
      Swal.fire({
        icon: 'error',
        title: 'Please Enter All Input Fields',
        showConfirmButton: false,
        timer: 2500
      });
      return; // Exit the function if fields are missing
    }
  
    // Extract store and saas IDs from local storage
    const { storeId, saasId } = JSON.parse(localStorage.getItem("STORE_DATA"));
    
    // Determine the API URL based on the presence of roleId
     const apiUrl = roleId 
      ? `${BASE_Url}/staff-user/update-role/${roleId}`
      : `${BASE_Url}/staff-user/add-role`;    
    // Prepare the data payload
    const dataPayload = {
      role_id: roleId,
      security_id: roleData.security_id,
      allowed_or_not: roleData.allowed_or_not,
      saas_id: saasId,
      store_id: storeId,
      role_name: roleData.role_name,
      function_name: roleData.function_name
    };
  
    // Select the appropriate axios method
    const axiosMethod = roleId ? axios.put : axios.post;
  
    // Make the API request
    axiosMethod(apiUrl, dataPayload)
      .then((response) => {
        if (response.status === 200) {
          // Show success message
          Swal.fire({
            icon: 'success',
            title: response.data.message,
            showConfirmButton: true
          });
          // Navigate to the StaffAndUsers page
          navigate("/StaffAndUsers");
        } else {
          console.log("Failed to fetch data from the API.");
        }
      })
      .catch((error) => {
        console.error("An error occurred:", error);
      });
  };

  return (
    <Box className="container mt-4 mb-3"
    
    style={{
      background: "white",
      maxWidth: "1000px",
      flexWrap: "wrap",
      height: "fit-content",
      overflowY: "auto",
      boxShadow: "0 2px 12px rgba(36, 36, 39, 0.12)",
      borderRadius: "0.75rem",
      overflow: "hidden",
      fontSize: "1rem",
      border: "0 2px 12px rgba(36, 36, 39, .12)",
      padding: "20px",
    }}>
      <Typography variant="h6" gutterBottom>
        {roleId ? 'Edit Role' : 'Add Role'}
      </Typography>
      <form className="w-full">
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label="Role Name"
              name="role_name"
              value={roleData.role_name}
              onChange={handleChange}
              required
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              fullWidth
              label="Security Id"
              name="security_id"
              value={roleData.security_id}
              onChange={handleChange}
              required
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              fullWidth
              label="Function Name"
              name="function_name"
              value={roleData.function_name}
              onChange={handleChange}
              required
            />
          </Grid>

          <Grid item xs={6}>
            <FormControl fullWidth required>
              <InputLabel id="allowed-or-not-label">Allowed/Not Allowed</InputLabel>
              <Select
                labelId="allowed-or-not-label"
                id="allowed-or-not"
                name="allowed_or_not"
                value={roleData.allowed_or_not}
                onChange={handleChange}
                label="Allowed/Not Allowed"
              >
                <MenuItem value="Allowed">Allowed</MenuItem>
                <MenuItem value="Not Allowed">Not Allowed</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>

        <Box display="flex" justifyContent="center" mt={4}>
          <Button className='mx-2' variant="contained" color="primary" onClick={handleSubmit}>
            {roleId ? 'Save' : 'Add Role'}
          </Button>
          <Button variant="contained" color="secondary" onClick={() => navigate("/StaffAndUsers")}>
            Cancel
          </Button>
        </Box>
      </form>
    </Box>
  );
};

export default RoleMaster;
