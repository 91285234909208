import React, { useEffect, useRef, useState } from "react";
import axios from 'axios';
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button } from '@mui/material';
import { useNavigate } from "react-router-dom";
import { BASE_Url } from "../../URL.js/URL";
// import ReceiptModal from "../ReceiptModal";
import Modal from 'react-bootstrap/Modal';
import { useReactToPrint } from "react-to-print";
import { AiOutlineClose } from "react-icons/ai";
import Invoicemodel from "../Invoicemodel";
import Receipteforsaasid12 from "../ReceiptFor12/Receipteforsaasid12";
import DownloadComponent from "../CSVDownload/DownloadComponent";
import DownloadPDFButton from "../CSVDownload/DownloadPDFButton";
import { NoPrint } from "react-easy-print";
import DataTable from "react-data-table-component";

const Allinvoiced = ({ formDate, toDate }) => {
  const [allData, setAllData] = useState([]);
  const [total_amount, setTotalAmount] = useState('');
  const [selectedInvoice, setSelectedInvoice] = useState(null); 
  const [viewPdf, setViewPdf] = useState(false);
  const navigate = useNavigate();
  const { storeId, saasId } = JSON.parse(localStorage.getItem('STORE_DATA'));
  const componentRef = useRef();
  const focusClose = useRef();
  
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    onBeforePrint: () => {
      console.log("printing Start");
    },
    onAfterPrint: () => {
      setViewPdf(false);
      navigate("/Reports");
      console.log("printing End");
      // focusClose.current.focus();
    },
  });
  
  const header = {
    container: {
      fontFamily: "Courier New, monospace",
      fontSize: "small",
    },
    text: {
      fontFamily: "Courier New, monospace",
      fontSize: "small",
    },
  };

  // Fetch sales report data
  useEffect(() => {
    axios.get(`${BASE_Url}/user-master/get-sales-report/${formDate}/${storeId}/${saasId}`)
      .then((res) => {
        setAllData(res.data.list_sales_report);
        setTotalAmount(res.data.total_net);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, [formDate]);

  // Function to fetch PDF data when "View" button is clicked
  const handleViewClick = (invoice_no) => {
    axios.get(`${BASE_Url}/user-master/get-pdfdata/${invoice_no}`)
      .then((res) => {
        const data = res.data.data;
        console.log(data)
        setSelectedInvoice({
          tableNo: data["Dine in"], 
          changedue:data.change_due,
          invoiceNo: data.invoice_detail[0].invoiceNo, 
          products: data.invoice_detail, 
          totalDiscount: data.invoice_detail.reduce((sum, item) => sum + item.itemDiscount, 0),
          customer: data.customer_detail, 
          pay_method: "Cash", 
          orderType: "Dine In", 
          optionTick: true,
          settelment: data.payment_detail 
        });
        setViewPdf(true); 
      })
      .catch((error) => {
        console.error(`Error fetching PDF data for invoice ${invoice_no}:`, error);
      });
  };

     const headers = [
        { label: "Invoice No", key: "invoice_no" },
        { label: "Invoice Total", key: "invoice_total" },
        { label: "Net Value", key: "netValue" },
        { label: "Total Tax", key: "tax_total" },
        { label: "Discount", key: "discount" },
      ];

      const columns = [
        {
          name: "Invoice Number",
          selector: (row) => row.invoice_no,
          sortable: true,
        },
        {
          name: "Invoice Total",
          selector: (row) => row.invoice_total,
          sortable: true,
        },
        {
          name: "Total Net",
          selector: (row) => row.netValue,
          sortable: true,
        },
        {
          name: "Tax Total",
          selector: (row) => row.tax_total,
          sortable: true,
        },
        {
          name: "Discount",
          selector: (row) => row.discount,
          sortable: true,
        },
        {
          name: "Action",
          cell: (row) => (
            <NoPrint>

            <Button
              variant="contained"
              color="primary"
              onClick={() => handleViewClick(row.invoice_no)}
              >
              View
            </Button>
              </NoPrint>
          ),
          ignoreRowClick: true,
          allowOverflow: true,
          button: true,
        },
      ];
      
    //  const data = [
    //     { firstname: "Ahmed", lastname: "Tomi", email: "ah@smthing.co.com" },
    //     { firstname: "Raed", lastname: "Labes", email: "rl@smthing.co.com" },
    //     { firstname: "Yezzi", lastname: "Min l3b", email: "ymin@cocococo.com" }
    //   ];



    //new update code
    const tableRef = useRef(null); // Ref for the table container

    const handleTablePrint = useReactToPrint({
      content: () => tableRef.current,
      documentTitle: 'Sales_Report',
      onBeforePrint: () => console.log("Printing Sales Table..."),
    });
  

  return (
    <>
      <div style={{ height: "100vh", overflowY: "auto" }}>
        <DownloadComponent data={allData} headers={headers} fileName="Invoice_Report"/>
        <Button
          variant="contained"
          className="mx-2"
          style={{background:"rgb(14, 41, 84)",color:"white"}}
          onClick={handleTablePrint}
        >
          PRINT 
        </Button>
           {/* Reusable PDF Download Button */}
           <DownloadPDFButton
          data={allData}
          headers={headers}
          fileName="Invoice_Report.pdf"
        />
        {total_amount > 0 &&<div>
          <p className="text-xl font-bold">Total Amount: {total_amount}</p>
        </div>}
        <div className="flex flex-wrap" style={{ marginBottom: "40%" }}>
          <Box ref={tableRef} className="h-100 w-100 rounded-lg">
          <DataTable
        // title="Invoice Report"
        columns={columns}
        data={allData}
        // pagination
        highlightOnHover
        pointerOnHover
        responsive
      />
            {/* <TableContainer component={Paper}>
              <Table className="text-nowrap" aria-label="simple table">
                <TableHead className="bg-dark-purple">
                  <TableRow>
                    <TableCell className=" text-white">Invoice Number</TableCell>
                    <TableCell className=" text-white">Invoice Total</TableCell>
                    <TableCell className=" text-white">Total net</TableCell>
                    <TableCell className=" text-white">Tax Total</TableCell>
                    <TableCell className=" text-white">Discount</TableCell>
                    <NoPrint>
                    <TableCell className=" text-white">Action</TableCell>
                    </NoPrint>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {allData?.map((ele) => (
                    <TableRow key={ele.invoice_no}>
                      <TableCell>{ele.invoice_no}</TableCell>
                      <TableCell>{ele.invoice_total}</TableCell>
                      <TableCell>{ele.netValue}</TableCell>
                      <TableCell>{ele.tax_total}</TableCell>
                      <TableCell>{ele.discount}</TableCell>
                      <NoPrint>
                      <TableCell>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => handleViewClick(ele.invoice_no)}
                        >
                          View
                        </Button>
                      </TableCell>
                      </NoPrint>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer> */}
          </Box>
        </div>

        {/* Modal to show PDF data */}
        <Modal
          show={viewPdf}
          onHide={() => setViewPdf(false)}
          className="p-0"
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box>
            <div
              className="-mx-2 mt-1"
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
                cursor: "pointer",
              }}
              onClick={() => setViewPdf(false)}
            >
              <AiOutlineClose size={20} />
            </div>
            <div
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Button
                variant="secondary"
                style={{ background: "gray" }}
                className="text-white mt-10 mx-2 p-2"
                onClick={() => setViewPdf(false)}
                ref={focusClose}
              >
                Close
              </Button>
              <Button
                variant="primary"
                style={{ background: "blue" }}
                autoFocus
                className="text-white mt-10 p-2"
                onClick={handlePrint}
              >
                PRINT
              </Button>
            </div>

            <div className="container" style={header.container} ref={componentRef}>
              {/* Render the ReceiptModal inside modal */}
              {selectedInvoice && (
               saasId == "12" ?<Receipteforsaasid12
               payment_detail={selectedInvoice.settelment}
               totalDiscount={selectedInvoice.totalDiscount}
               customer={selectedInvoice.customer}
               tableNo={selectedInvoice.tableNo}
               invoiceNo={selectedInvoice.invoiceNo}
               payment_mode={selectedInvoice.pay_method}
               orderType={selectedInvoice.orderType}
             //   optionTick={selectedInvoice.optionTick}
               products={selectedInvoice.products}
               />
                
                
                : <Invoicemodel
                  change_due={selectedInvoice.changedue}
                  payment_detail={selectedInvoice.settelment}
                  totalDiscount={selectedInvoice.totalDiscount}
                  customer={selectedInvoice.customer}
                  tableNo={selectedInvoice.tableNo}
                  invoiceNo={selectedInvoice.invoiceNo}
                  payment_mode={selectedInvoice.pay_method}
                  orderType={selectedInvoice.orderType}
                //   optionTick={selectedInvoice.optionTick}
                  products={selectedInvoice.products}
                />
              
              )}
            </div>
          </Box>
        </Modal>
      </div>
    </>
  );
};

export default Allinvoiced;
