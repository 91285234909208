import React, { useEffect, useRef, useState } from "react";
import Table from 'react-bootstrap/Table';
import axios from 'axios';
import { BASE_Url } from "../../URL.js/URL";
import DataTable from "react-data-table-component";
import DownloadComponent from "../CSVDownload/DownloadComponent";
import { useReactToPrint } from "react-to-print";
import { Button } from "@mui/material";
import DownloadPDFButton from "../CSVDownload/DownloadPDFButton";

const Inventory_reports = ({formDate,toDate}) => {
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage]= useState('1')
  const [totalRows, setTotalRows] = useState(0);
  const handlePageChange = (page) => {
    console.log("this pagination page",page)
    setCurrentPage(page);
  };
   
  const getAllData = (currentPage) => {
    const { storeId, saasId } = JSON.parse(localStorage.getItem("STORE_DATA"));

    axios
      .get(`${BASE_Url}/getall-inventory-bom/${saasId}/${storeId}/${currentPage}`)
      .then((res) => {
        if (res.status === 200) {
          console.log("TABLE", res);
          setData(res.data.data);
          setTotalRows(res.data.count);
        }
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    // Fetch initial data or perform any necessary setup
    const { storeId, saasId } = JSON.parse(localStorage.getItem("STORE_DATA")) || {};
    if (storeId && saasId) {
      getAllData(currentPage, storeId, saasId);
    }
  }, [currentPage]); 
  // ---------------------------
  const columns = [
    {
      name: "Raw Material name",
      center: true,
      selector: (row) => row.inventory_name,
      sortable:true,
    },
    {
      name: "Item Code",
      center: true,
      selector: (row) => row.id,
      sortable: true,
    },
    {
      name: "Opening Qty",
      center: true,
     selector: (row) => row.opening_qty,
     sortable: true,
    },

    {
      name: "Closing Qty",
      center: true,
      selector: (row) => row.closing_qty,
      sortable: true,
    },
    {
      name: "Total Cost",
      center: true,
      selector: (row) => row.total_cost,
      sortable: true,
    },
    {
      name: "Consumed Qty",
      center: true,
      selector: (row) => row.consumed,
      sortable: true,
    },
    {
      name: "Cost per Unit", 
      center: true,
      selector: (row) => row.cost,
      sortable: true,
    },
    {
      name: "Unit", 
      center: true,
      selector: (row) => row.unit,
      sortable: true,
    },
  ];

  const header= [
    {
      label: "Raw Material Name",
      key: "inventory_name",
    },
    {
      label: "Item Code",
      key: "id",
    },
    {
      label: "Opening Qty",
      key: "opening_qty",
    },
    {
      label: "Closing Qty",
      key: "closing_qty",
    },
    {
      label: "Total Cost",
      key: "total_cost",
    },
    {
      label: "Consumed Qty",
      key: "consumed",
    },
    {
      label: "Cost per Unit",
      key: "cost",
    },
    {
      label: "Unit",
      key: "unit",
    },
    
  ]

   //new update code
   const tableRef = useRef(null); // Ref for the table container

   const handleTablePrint = useReactToPrint({
     content: () => tableRef.current,
     documentTitle: 'Sales_Report',
     onBeforePrint: () => console.log("Printing Sales Table..."),
   });
  return (
    <div className="container" style={{ height: "100vh", overflowY: "auto"}}>
      <DownloadComponent headers={header} fileName="inventory_report" data={data} />
      <Button
              variant="contained"
              className="mx-2"
              style={{background:"rgb(14, 41, 84)",color:"white"}}
              onClick={handleTablePrint}
            >
              PRINT 
            </Button>
               {/* Reusable PDF Download Button */}
               <DownloadPDFButton
              data={data}
              headers={header}
              fileName="Inventory_reports.pdf"
            />
          <div 
          
  style={{ maxHeight:'calc(100vh - 200px)', overflowY:'auto'  }}
    >
           <div
           ref={tableRef}
           >

            <DataTable
            columns={columns}
            responsive={true}
            data={data}
            pagination
            paginationServer
            paginationTotalRows={totalRows}
            paginationPerPage={10}
            onChangePage={(page) => handlePageChange(page)}
            
            />
            </div>
       
    </div>
    </div>
  );
}

export default Inventory_reports;
