import React, { useState, useEffect } from "react";
import "./Addfooditem.css";
import axios from "axios";
import { BASE_Url } from "../../URL.js/URL";
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Swal from "sweetalert2";
import Modal from 'react-bootstrap/Modal';
import { Container } from "reactstrap";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import "swiper/css";
import 'swiper/css/scrollbar';
function Addfooditem(props) {
  const [itemData, setItemData] = useState([]);
  const [selectID, setselectID] = useState("");
  const [image, setImage] = useState("");
  const { storeId, saasId } = JSON.parse(localStorage.getItem("USER_DATA"));
  const [inputData, setInputData] = useState({
    item_name: "",
    price: "",
    discount: "0",
    tax: "0",
    status: "active",
    saas_id: saasId,
    store_id: storeId,
    promo_id: "Promo789",
    hsn_code: "HSN123",
    category_details: [{ category_id: "", category_name: "" }],
  });
  const { item_name, price, category_details } = inputData;

  const changeHandler = (e) => {
    console.log(e.target.name);
    if(e.target.name === "category_details") {
      const selectedCategoryId = e.target.value;
      const selectedCategoryName = e.target.options[e.target.selectedIndex].text;
      setselectID(selectedCategoryId); // Update selected ID
    setInputData({
      ...inputData,
      category_details: [{ category_id: selectedCategoryId, category_name: selectedCategoryName }],
    });
    console.log("Selected Category ID:", selectedCategoryId);
    console.log("Selected Category Name:", selectedCategoryName);
  } else {
    setInputData({
     ...inputData,
      [e.target.name]: e.target.value,
    });
  }
  
    
  };

  useEffect(() => {
    const apiUrl = `${BASE_Url}/item/category-list/${saasId}`;
    // const apiUrl = 'http://3.111.70.84:8093/api/v1/item/view-full-menu/8';

    axios
      .get(apiUrl)
      .then((response) => {
        if (response.status === 200) {
          console.log("new Api", response);
          const data = response.data.data;
          console.log(" Api", data);
          // Assuming you want to display the data for the fourth item (index 3)
          // setItemData(data.data.item_list[3]);
          setItemData(data);
        } else {
          console.log("Failed to fetch data from the API.");
        }
      })
      .catch((error) => {
        console.error("An error occurred:", error);
      });
  }, []);

  const subHandler = async (e) => {
    e.preventDefault();
    try {
      if(image){
      const response = await axios.post(`${BASE_Url}/item/add-item`, inputData);
      console.log("API response:", response.data.data.item_id);

      console.log(response.data.status);
      setselectID("");
      if(response.data.status){
        
          const formData = new FormData();
          formData.append("file", image);
          const data = await axios.post(`${BASE_Url}/item/save-image/${response.data.data.item_id}`,formData);
        

      }
      props.addFoodItemToMenu(response.data); // Assuming the response.data is the new food item
          props.getMenu()
      
      props.setTrigger(false);
    }else{
      Swal.fire({
        title: 'Please Select Image',
        icon:'error',
        timer: 1000
      })
    }
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
  });

  const [screenWidth, setScreenWidth] = useState(0);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };
  
    setScreenWidth(window.innerWidth); // Initialize screenWidth
  
    window.addEventListener("resize", handleResize);
  
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  
  return  (
    <Modal
    show={props.trigger}
    onHide={() =>   props.setTrigger(false)}
    // fullscreen={true}
    aria-labelledby="contained-modal-title-vcenter"
    centered
    >
        <Modal.Header className="container" closeButton>
                  <Modal.Title>Add Food Item</Modal.Title>
                </Modal.Header>
                <Container>
                  
        <form onSubmit={subHandler}>
        
          <label className="block text-gray-700  font-bold  ">
            Item Name{" "}
          </label>
          <input
          required
            className="  border-2 border-gray-300 py-2 px-2 rounded-lg w-full font-semibold  text-gray-700 leading-tight focus:outline-none focus:shadow-outline mt-2 "
            name="item_name"
            value={item_name}
            placeholder="  Enter Item Name"
            onChange={(e) => changeHandler(e)}
          ></input>

          <label className="block text-gray-700  font-bold mb-2 mt-6">
            Price
          </label>
          <input
          required
            className="  border-2 border-gray-300 py-2 px-2 rounded-lg w-full font-semibold leading-tight focus:outline-none focus:shadow-outline "
            name="price"
            value={price}
            placeholder="  Enter Item Price"
            onChange={(e) => changeHandler(e)}
          ></input>

          <label
            className="block text-gray-700 mt-4 font-bold "
            htmlFor="link-radio"
          >
            Category
          </label>
       
          <div
  style={{
    display: 'flex',
    flexDirection: 'column',
    borderRadius: '0.75rem',
    fontFamily: 'DM Sans, sans-serif',
  }}
  className="category-container mb-3 mt-2"
>
<select
  value={selectID}
  name="category_details"
  onChange={(e) => changeHandler(e)}
  style={{
    padding: '10px',
    borderRadius: '0.5rem',
    fontFamily: 'DM Sans, sans-serif',
    boxShadow: '0 2px 6px rgba(36, 36, 39, 0.1)',
    cursor: 'pointer',
    backgroundColor: 'white',
    border: '1px solid #ccc',
  }}
>
  <option value="" disabled>Select Category</option>
  {itemData?.map((data) => (
    <option key={data.category_id} value={data.category_id}>
      {data.category_name}
    </option>
  ))}
</select>
</div>

          <Button className="mb-2" component="label" variant="contained" >
      Upload Image
      <VisuallyHiddenInput type="file"
       onChange={(e) => {
                      console.log("evfdcvfvfvc", e.target.files[0].name);
                      setImage(e.target.files[0]);
                      // setImageName(e.target.files[0].name);
                    }}
                    />
    </Button>

        

          <div className="flex border-t-2 py-2 justify-center border-gray-300 mt-2">
            <Button
              type="submit"
              style={{background:"#0E2954"}}
              className=" text-white py-2 px-4 rounded md:mt-0 mx-2"
            >
              Done
            </Button>
            <Button
              type="submit"
              style={{background:"#0E2954"}}
              className=" text-white py-2 px-4 rounded md:mt-0"
            onClick={()=>props.setTrigger(false)}>
              Cancel
            </Button>
          </div>

         
       
        </form>
        </Container>
        {/* {props.children} */}
      </Modal>

 
    
  );
}

export default Addfooditem;
