import React, { useEffect, useState } from "react";
import MenuIcon from "@mui/icons-material/Menu";
import axios from "axios";
import { BASE_Url } from "../../URL.js/URL";
import { useNavigate, useParams } from "react-router-dom";

const Header = () => {
  const { saas_id, store_id, table_id } = useParams();
  const navigate = useNavigate();
  const [storeData, setStoreData] = useState({
    storeName: '',
    storeAddress1: ''
  });
  const [tableName, setTableName] = useState('');
  const [waiterCalled, setWaiterCalled] = useState(false);

  const handleCallWaiter = () => {
    setWaiterCalled(true);
    // Simulate calling a waiter
    setTimeout(() => setWaiterCalled(false), 3000); // Reset after 3 seconds
  };

  const fetchStoreData = async () => {
    try {
      const response = await axios.get(
        `${BASE_Url}/transaction/Store_name/${saas_id}/${store_id}`
      );
      const data = response?.data?.data;

      if (!data) {
        // If data is null or undefined, navigate to the error page
        navigate("/error");
      } else {
        // Save the fetched data into local storage and state
        localStorage.setItem("Store_data", JSON.stringify(data));
        setStoreData(data);
      }
    } catch (error) {
      console.error("Error fetching store data:", error);
      // In case of any error, also navigate to the error page
      navigate("/error");
    }
  };
  const getTablename = async ()=>{
    try {
      const response = await axios.get(
        `${BASE_Url}/tableview/get-tables-by-tableid/${table_id}`
      );
      const data = response?.data?.data;
      if(data){
        setTableName(data.tableName)
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    // Try to get the store data from localStorage first
    const storedData = localStorage.getItem("Store_data");
    if (storedData) {
      setStoreData(JSON.parse(storedData));
    } else {
      fetchStoreData(); // Fetch from API if not found in localStorage
    }
    getTablename()
  }, [saas_id, store_id]);

  return (
    <div
      className="max-w-md mx-auto bg-white  overflow-hidden"
      style={{
        borderBottom: "2px solid #B2B2B2",
      }}
    >
      <div className="p-4">
        <div className="flex justify-between items-center">
          <div>
            <h1 className="text-xl font-semibold text-gray-800 truncate">
              {storeData.storeName && storeData.storeName.length > 15
                ? `${storeData.storeName.slice(0, 15)}...`
                : storeData.storeName}
            </h1>
            <p className="text-sm text-gray-600 truncate">
              {storeData.storeAddress1 && storeData.storeAddress1.length > 25
                ? `${storeData.storeAddress1.slice(0, 25)}...`
                : storeData.storeAddress1}
            </p>
          </div>
          <div className="flex items-center space-x-2">
            <button className="bg-green-600 text-white font-semibold py-2 px-3 rounded">
              {tableName}
            </button>
            <button
              className={`${
                waiterCalled
                  ? "bg-green-600 text-white"
                  : "bg-white text-green-600 border border-green-600"
              } font-semibold py-2 px-3 rounded transition-colors duration-300`}
              onClick={handleCallWaiter}
              disabled={waiterCalled}
            >
              {waiterCalled ? "Waiter Called" : "Call Waiter"}
            </button>
            {/* <button className="text-gray-600">
              <MenuIcon className="h-6 w-6" />
            </button> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
