import React, { useEffect, useState } from "react";
import Table from 'react-bootstrap/Table';
import axios from 'axios';
import { BASE_Url } from "../../URL.js/URL";
import DataTable from "react-data-table-component";
import DownloadComponent from "../CSVDownload/DownloadComponent";

const Inventory_reports = ({formDate,toDate}) => {
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage]= useState('1')
  const [totalRows, setTotalRows] = useState(0);
  const handlePageChange = (page) => {
    console.log("this pagination page",page)
    setCurrentPage(page);
  };
   
  const getAllData = (currentPage) => {
    const { storeId, saasId } = JSON.parse(localStorage.getItem("STORE_DATA"));

    axios
      .get(`${BASE_Url}/getall-inventory-bom/${saasId}/${storeId}/${currentPage}`)
      .then((res) => {
        if (res.status === 200) {
          console.log("TABLE", res);
          setData(res.data.data);
          setTotalRows(res.data.count);
        }
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    // Fetch initial data or perform any necessary setup
    const { storeId, saasId } = JSON.parse(localStorage.getItem("STORE_DATA")) || {};
    if (storeId && saasId) {
      getAllData(currentPage, storeId, saasId);
    }
  }, [currentPage]); 
  // ---------------------------
  const columns = [
    {
      name: "Raw Material name",
      center: true,
      selector: (row) => row.inventory_name,
    },
    {
      name: "Item Code",
      center: true,
      selector: (row) => row.id,
    },
    {
      name: "Opening Qty",
      center: true,
     selector: (row) => row.opening_qty,
    },

    {
      name: "Closing Qty",
      center: true,
      selector: (row) => row.closing_qty,
    },
    {
      name: "Consumed Qty",
      center: true,
      selector: (row) => row.consumed,
    },
    {
      name: "Cost per Unit", 
      center: true,
      selector: (row) => row.cost,
    },
    {
      name: "Unit", 
      center: true,
      selector: (row) => row.unit,
    },
  ];

  const header= [
    {
      label: "Raw Material Name",
      key: "inventory_name",
    },
    {
      label: "Item Code",
      key: "id",
    },
    {
      label: "Opening Qty",
      key: "opening_qty",
    },
    {
      label: "Closing Qty",
      key: "closing_qty",
    },
    {
      label: "Consumed Qty",
      key: "consumed",
    },
    {
      label: "Cost per Unit",
      key: "cost",
    },
    {
      label: "Unit",
      key: "unit",
    },
    
  ]


  return (
    <div className="container" style={{ height: "100vh", overflowY: "auto"}}>
      <DownloadComponent headers={header} fileName="inventory_report" data={data} />
          <div 
  style={{ marginBottom: "40%" }}
    >
      
            <DataTable
            columns={columns}
            responsive={true}
            data={data}
            pagination
            paginationServer
            paginationTotalRows={totalRows}
            paginationPerPage={10}
            onChangePage={(page) => handlePageChange(page)}
          
            />
       
    </div></div>
  );
}

export default Inventory_reports;
