import React, { useEffect, useState } from "react";
import Table from 'react-bootstrap/Table';
import axios from 'axios';

const CRM_reports = ({formDate,toDate}) => {
  const [allData, setAllData] = useState([]);
  const {storeId, saasId}= JSON.parse(localStorage.getItem('STORE_DATA'));
  useEffect(() => {
    axios.get(`http://103.139.59.233:8092/prod/api/v1/user-master/overall_performance/${saasId}/${storeId}/${formDate}/${toDate}`)
      .then((res) => {
        setAllData(res.data.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, [formDate,toDate]);

  return (
    <div className="container">
      <div className="border-1 h-100 w-100 rounded-lg mt-4">
        <div className="table-responsive">
          <Table bordered={false}>
            <thead>
              <tr>
                <th>Customer Name</th>
                <th>Total Points Earned</th>
                <th>Total Points Redeemed</th>
                <th>No.Of Orders</th>
                <th>Total Ordered Value</th>
                <th>No.of Orders Cancelled</th>
            
              </tr>
            </thead>
            <tbody>
              {allData?.map((ele) => (
                <tr key={ele.date}>
                  <td style={{ borderRight: 'none' }}>{ele.date}</td>
                  <td style={{ borderRight: 'none' }}>$200</td>
                  <td style={{ borderRight: 'none' }}>{ele.most_ordered}</td>
                  <td style={{ borderRight: 'none' }}>{ele.net_profit}</td>
                  <td style={{ borderRight: 'none' }}>$200</td>
                  <td style={{ borderRight: 'none' }}>$200</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </div>
    </div>
  );
}

export default CRM_reports;
