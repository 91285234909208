import React, { useEffect, useRef, useState } from "react";
import Table from 'react-bootstrap/Table';
import axios from 'axios';
import { BASE_Url } from "../../URL.js/URL";
import DownloadComponent from "../CSVDownload/DownloadComponent";
import { Button } from "@mui/material";
import DownloadPDFButton from "../CSVDownload/DownloadPDFButton";
import { useReactToPrint } from "react-to-print";
const Staffperformance_reports = ({formDate,toDate}) => {
  const [allData, setAllData] = useState([]);
  const {storeId, saasId}= JSON.parse(localStorage.getItem('STORE_DATA'));
  useEffect(() => {
    axios.get(`${BASE_Url}/user-master/sales_person_performance/${saasId}/${storeId}/${formDate}/${toDate}`)
      .then((res) => {
        setAllData(res.data.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, [formDate,toDate]);




  const headers = [
    { label: "Average Customer", key: "avg_customer" },
    { label: "Total Sale", key: "sales" },

 
]

   //new update code
   const tableRef = useRef(null); // Ref for the table container

   const handleTablePrint = useReactToPrint({
     content: () => tableRef.current,
     documentTitle: 'Sales_Report',
     onBeforePrint: () => console.log("Printing Sales Table..."),
   });
  return (
    <div className="container">

<DownloadComponent headers={headers} data={allData} fileName="Staffperformance_reports" />
            
 
            <Button
              variant="contained"
              className="mx-2"
              style={{background:"rgb(14, 41, 84)",color:"white"}}
              onClick={handleTablePrint}
            >
              PRINT 
            </Button>
               {/* Reusable PDF Download Button */}
               <DownloadPDFButton
              data={allData}
              headers={headers}
              fileName="Staffperformance_reports.pdf"
            />

      <div className="border-1 h-100 w-100 rounded-lg mt-4">
        <div ref={tableRef}  className="table-responsive">
          <Table bordered={false}>
            <thead>
              <tr>
                {/* <th>Staff Name</th>
                <th>Role</th> */}
                <th>Average Customer</th>
                <th>Total Sale</th>
              </tr>
            </thead>
            <tbody>
              {allData?.map((ele) => (
                <tr key={ele.date}>
                
                  <td style={{ borderRight: 'none' }}>{ele.avg_customer}</td>
                  <td style={{ borderRight: 'none' }}>{ele.sales}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </div>
    </div>
  );
}

export default Staffperformance_reports;
