import React, { useState } from 'react';
import { Button, TextField, Box, Typography } from '@mui/material';
import axios from 'axios';
import { BASE_Url } from '../URL.js/URL';
import Swal from 'sweetalert2';
// import { deleteCustomerData } from '../utils/deleteCustomerData';

export function DeleteAccountForm({CustomerData}) {
  const [mobileNumber, setMobileNumber] = useState('');
  const [message, setMessage] = useState('');
  const [isDeleting, setIsDeleting] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    //check in customer data is customer mobileNumber and customer data mobileNumber is same then user allow
    if(mobileNumber !== CustomerData.mobile_number){
        return  Swal.fire({title:"Enter Correct Mobile Number",icon:"error",timer:2000})
    }
    setIsDeleting(true);
    setMessage('');
    try {
      const result = await axios.delete(`${BASE_Url}/customer/deleteByMobile/${mobileNumber}`);
      if (result.data.status) {
        setMessage(result.data.message);
        setMobileNumber('');
      } else {
        setMessage('Failed to delete account. Please try again.');
      }
    } catch (error) {
      setMessage('An error occurred. Please try again later.');
    } finally {
      setIsDeleting(false);
    }
  };

  return (
    <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
      <TextField
        margin="normal"
        required
        fullWidth
        id="mobileNumber"
        label="Mobile Number"
        name="mobileNumber"
        autoComplete="tel"
        autoFocus
        value={mobileNumber}
        onChange={(e) => setMobileNumber(e.target.value)}
        inputProps={{
          'aria-describedby': 'mobileNumberHint',
        }}
      />
      <Typography variant="caption" id="mobileNumberHint" sx={{ mt: 1, display: 'block' }}>
        Enter the mobile number associated with your account
      </Typography>
      <Button
        type="submit"
        fullWidth
        variant="contained"
        sx={{ mt: 3, mb: 2 }}
        disabled={isDeleting}
      >
        {isDeleting ? 'Deleting...' : 'Delete My Account'}
      </Button>
      {message && (
        <Typography 
          variant="body2" 
          sx={{ 
            mt: 2, 
            color: message.includes('successfully') ? 'success.main' : 'error.main' 
          }}
          role="status"
          aria-live="polite"
        >
          {message}
        </Typography>
      )}
    </Box>
  );
}

