import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import axios from 'axios';
import React from 'react'
import { BASE_Url } from '../../URL.js/URL';
import DownloadComponent from '../CSVDownload/DownloadComponent';
import { Bar } from 'react-chartjs-2'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js'

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
)
const PaymentReport = ({startdate,endDate}) => {

    const { storeId, saasId } = JSON.parse(localStorage.getItem('STORE_DATA'));
    // fetch data from API
    const [allData, setAllData] = React.useState([])
    const [total_amount, setTotal_amount] = React.useState(0)
    React.useEffect(() => {
        const fetchData = async () => {
            const response = await axios.get(`${BASE_Url}/user-master/get-paymentReport/${saasId}/${storeId}/${startdate}/${endDate}`)
            setAllData(response?.data?.data && response.data?.data?.length > 0? response.data.data : [])
            setTotal_amount(response?.data?.total_value)
        }
        fetchData()
    }, [startdate,endDate])

    const Header = [
        { label: "Business Date", key: "business_date" },
        { label: "Tender Name", key: "tender_name" },
        { label: "Total Amount", key: "total_sum" },
    ]
    const getColor = (tenderName) => {
      const colors = {
        'Cash': 'rgba(75, 192, 192, 0.6)',
        'Card': 'rgba(255, 99, 132, 0.6)',
        'PayTm': 'rgba(54, 162, 235, 0.6)',
        'Due Amount': 'rgba(255, 206, 86, 0.6)',
        'Other': 'rgba(153, 102, 255, 0.6)',
      }
      return colors[tenderName] || `rgba(${Math.floor(Math.random() * 255)}, ${Math.floor(Math.random() * 255)}, ${Math.floor(Math.random() * 255)}, 0.6)`
    }
    const chartData = {
      labels: allData.map(item => `${item.business_date} (${item.tender_name})`), // Combine date and tender name for labels
      datasets: [
        {
          label: 'Amount',
          data: allData.map(item => item.total_sum),
          backgroundColor: allData.map(item => getColor(item.tender_name)),
          borderColor: allData.map(item => getColor(item.tender_name).replace('0.6', '1')),
          borderWidth: 1,
        },
        {
          label: 'Total Invoice',
          data: allData.map(item => item.total_invoice),
          backgroundColor: allData.map(() => 'rgba(255, 159, 64, 0.6)'),
          borderColor: allData.map(() => 'rgba(255, 159, 64, 1)'),
          borderWidth: 1,
        },
      ],
    };
    const chartOptions = {
      responsive: true,
      plugins: {
        legend: {
          position: 'top',
        },
        title: {
          display: true,
          text: 'Payment Report',
        },
      },
      scales: {
        x: {
          stacked: true, // Enable stacking on the x-axis
        },
        y: {
          stacked: true, // Enable stacking on the y-axis
          beginAtZero: true,
        },
      },
    };
    
  return (
    <div>
        <DownloadComponent headers={Header} data={allData} fileName="PaymentReport"  />
        {total_amount >0 &&<div className="text-xl font-bold">Total Amount: {total_amount}</div>}
        
        <Box className="max-h-[calc(100vh-234px)] overflow-y-auto w-100 rounded-lg">
        <Box className="h-[400px] w-[900px] mb-8">
        <Bar data={chartData} options={chartOptions} />
      </Box>
            {/* <TableContainer component={Paper}>
              <Table className="text-nowrap" aria-label="simple table">
                <TableHead className="bg-dark-purple">
                  <TableRow>
                    <TableCell className=" text-white">Business Date</TableCell>
                    <TableCell className=" text-white">Tender Name</TableCell>
                    <TableCell className=" text-white">Total Amount</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {allData?.map((ele,index) => (
                    <TableRow key={index}>
                      <TableCell>{ele.business_date}</TableCell>
                      <TableCell>{ele.tender_name}</TableCell>
                      <TableCell>{ele.total_sum}</TableCell>
                     
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer> */}
          </Box>
    </div>
  )
}

export default PaymentReport