import moment from "moment";
import React from "react";
import { PrintProvider, Print } from "react-easy-print";

function KotPrintModal({orderData}) {

  const styles = {
    fontFamily: "Verdana",
    fontSize: "medium",
    fontWeight: "bold",
  };

  const customLineStyle = {
    border: "1px solid black",
    margin: "8px 0",
  };

  const receiptContent = (
    <div  style={styles}>
        <div   className="d-flex  justify-content-around fw-bold">
      <p
        style={{ fontSize: "1.6rem" }}
        className="d-flex  justify-content-around fw-bold"
      >
       KOT Print
      </p>
    
      <p
        style={{ fontSize: "1.1rem" }}
        className="d-flex mt-2 justify-content-around fw-bold"
      >
    Table No. {orderData.table_number}
      </p>
      </div>
      <div className="fw-bold " style={customLineStyle}></div>

      <table className="w-100  mx-md-auto" style={{ fontSize: "1.1rem" }}>
        <thead>
          <tr className="border-dark" style={{ borderBottomWidth: "2px" }}>
            <th>Order ID</th>
            <th className="px-3">Item Name</th>
            <th className="px-3">Qty</th>
          </tr>
        </thead>
        <tbody>
        {orderData.item_detail_list.map((el)=> {
              return (
                <>
                  {" "}
                  {el.item_qty > 0 && (
                    <>
                      <tr className="fw-bold">
                      <td style={{ fontSize: "1rem" }}>{orderData.order_id}</td>
                        <td style={{ fontSize: "1rem" }}>{el?.item_name}</td>
                        <td
                          className="text-center"
                          style={{ fontSize: "1rem" }}
                        >
                          {el.item_qty}
                        </td>
                  
                      </tr>
                  
                    </>
                  )}
                </>
              );
            })}
        </tbody>

      </table>
 <div className="fw-bold" style={customLineStyle}></div>
  {orderData.note && <span>Note: {orderData.note}</span>}
    
    </div>
  );

  return (
    <PrintProvider>
      <Print>
        <div style={styles}>{receiptContent}</div>
      </Print>
    </PrintProvider>
  );
}

export default KotPrintModal;
