import React, { useState, useEffect } from 'react';
import axios from 'axios';
import * as yup from 'yup';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { getUpdateUserList } from '../redux/slices/userManagement';
import Swal from 'sweetalert2';
import { BASE_Url } from '../URL.js/URL';
import { Box, Button, Grid, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material';

const AddStaff = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [addData, setAddData] = useState({
    staff_name: "",
    gender: "",
    date_of_joining: "",
    salary: "",
    incentive_percentage: "",
  });

  const schema = yup.object().shape({
    staff_name: yup.string().required(),
    gender: yup.string().required(),
    date_of_joining: yup.date().required(),
    salary: yup.string().required(),
    incentive_percentage: yup.number().required(),
  });

  const { storeId, saasId } = JSON.parse(localStorage.getItem("STORE_DATA"));

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const staffId = queryParams.get('staffId');

  useEffect(() => {
    if (staffId) {
      const EditApi = `${BASE_Url}/staff-user/get-staff/${staffId}`;
      axios.get(EditApi)
        .then((response) => {
          setAddData({
            staff_name: response.data.data.staff_name,
            gender: response.data.data.gender,
            date_of_joining: response.data.data.date_of_joining,
            salary: response.data.data.salary,
            incentive_percentage: response.data.data.incentive_percentage
          });
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [staffId]);

  const handleSubmit = () => {
    // Check if all required fields are filled
    if (!addData.staff_name || !addData.gender || !addData.date_of_joining || !addData.salary || !addData.incentive_percentage) {
      return Swal.fire({
        icon: 'error',
        title: 'Please Enter All Input Fields',
        showConfirmButton: false,
        timer: 2500
      });
    }
  
    // Define API URL based on whether staffId is present
    const apiUrl = staffId 
      ? `${BASE_Url}/staff-user/update-staff/${staffId}` 
      : `${BASE_Url}/staff-user/add-staff`;
  
    // Prepare data payload
    const dataPayload = {
      staff_id: staffId,
      staff_name: addData.staff_name,
      gender: addData.gender,
      saas_id: saasId,
      store_id: storeId,
      date_of_joining: addData.date_of_joining,
      salary: addData.salary,
      incentive_percentage: addData.incentive_percentage
    };
  
    // Select the appropriate axios method
    const axiosMethod = staffId ? axios.put : axios.post;
  
    // Make the API request
    axiosMethod(apiUrl, dataPayload)
      .then((response) => {
        if (response.status === 200) {
          Swal.fire({
            title: response.data.message,
            icon: 'success',
            timer: 1000,
            showConfirmButton: false,
          });
          navigate("/StaffAndUsers");
        } else {
          console.log("Failed to fetch data from the API.");
        }
      })
      .catch((error) => {
        console.error("An error occurred:", error);
      });
  };

  const handleChange = (e) => {
    setAddData({ ...addData, [e.target.name]: e.target.value });
  };

  return (
    <Box className="container mt-4 mb-3"
    
    style={{
      background: "white",
      maxWidth: "1000px",
      flexWrap: "wrap",
      height: "fit-content",
      overflowY: "auto",
      boxShadow: "0 2px 12px rgba(36, 36, 39, 0.12)",
      borderRadius: "0.75rem",
      overflow: "hidden",
      fontSize: "1rem",
      border: "0 2px 12px rgba(36, 36, 39, .12)",
      padding: "20px",
    }}>
      <Typography variant="h6" gutterBottom>
        {staffId ? 'Edit Staff' : 'Add Staff'}
      </Typography>
      <form className="w-full">
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Staff Name"
              name="staff_name"
              value={addData.staff_name}
              onChange={handleChange}
              required
            />
          </Grid>

          <Grid item xs={12} md={6}>
          <InputLabel id="Gender">Gender</InputLabel>

            <Select
            id="Gender"
              fullWidth
              label="Gender"
              name="gender"
              value={addData.gender}
              onChange={handleChange}
              required
            >
              <MenuItem value="Male">Male</MenuItem>
              <MenuItem value="Female">Female</MenuItem>
              <MenuItem value="Others">Others</MenuItem>
            </Select>
          </Grid>

          <Grid className='mt-4' item xs={12} md={6}>
            <TextField
              fullWidth
              type="date"
              label="Date Of Joining"
              name="date_of_joining"
              value={addData.date_of_joining}
              onChange={handleChange}
              InputLabelProps={{
                shrink: true,
              }}
              required
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              type="number"
              label="Monthly Salary"
              name="salary"
              value={addData.salary}
              onChange={handleChange}
              required
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              type="number"
              label="Incentive Percentage"
              name="incentive_percentage"
              value={addData.incentive_percentage}
              onChange={handleChange}
              required
            />
          </Grid>
        </Grid>

        <Box display="flex" justifyContent="center" mt={4}>
          <Button className='mx-2' variant="contained" color="primary" onClick={handleSubmit}>
            {staffId ? 'Save' : 'Add Staff'}
          </Button>
          <Button variant="contained" color="secondary" onClick={() => navigate("/StaffAndUsers")}>
            Cancel
          </Button>
        </Box>
      </form>
    </Box>
  );
};

export default AddStaff;
