
import { useEffect, useState } from "react"
import { Button, Card, CardContent, CardActions, CardHeader, Typography } from "@mui/material"
import ArrowForwardIcon from "@mui/icons-material/ArrowForward"
import {setFeedbackRatingLevel} from "../../redux/slices/tableSlice"
import { useDispatch, useSelector } from "react-redux";
export default function FeedbackComponent({handlenext}) {
  const {feedback} = useSelector((state) => {
    return state.tableData.createCustomer;
  });
  const {ratingLevel} = feedback;
  const dispatch = useDispatch();
  // const [ratingLevel, setSelectedScore] = useState(null)
  const scores = Array.from({ length: 11 }, (_, i) => i)

  const getScoreColor = (score) => {
    if (score <= 6) return "border-red-500 hover:bg-red-50 data-[selected=true]:bg-red-500 data-[selected=true]:text-white"
    if (score <= 8) return "border-yellow hover:bg-[#ffc86b] data-[selected=true]:bg-yellow data-[selected=true]:text-white"
    return "border-green-500 hover:bg-green-50 data-[selected=true]:bg-green-500 data-[selected=true]:text-white"
  }
  const SetratingLevel = (level) => {
    console.log(level);
     dispatch(setFeedbackRatingLevel(level));
   }
  const handleScoreClick =async (score) => {
    // console.log(score);
    // setSelectedScore(score);
     SetratingLevel(score);
  };

  return (
    <Card className="w-full max-w-md mx-auto">
      <CardHeader
        title={
          <Typography variant="h6" className="text-center text-muted-foreground">
            Feedback
          </Typography>
        }
      />
      <CardContent className="space-y-4">
        <div className="text-center space-y-4">
          <Typography variant="body2">
            Based on your shopping experience, how likely are you to recommend to a friend or colleague?
          </Typography>
          <div className="text-xs text-muted-foreground">
            <span>0 (least likely)</span>
            <span className="float-right">10 (most likely)</span>
          </div>
        </div>
        <div className="grid grid-cols-6 gap-1 ">
          {scores.map((score) => (
            <button
              key={score}
              onClick={() => handleScoreClick(score)}
              data-selected={ratingLevel === score}
              className={`w-10 h-10 aspect-square rounded-full border-2 text-sm font-medium transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 ${getScoreColor(score)}`}
              aria-label={`Rate ${score} out of 10`}
            >
              {score}
            </button>
          ))}
        </div>
      </CardContent>
      <CardActions>
        <Button
          fullWidth
          variant="contained"
          color="primary"
          disabled={ratingLevel === null}
          endIcon={<ArrowForwardIcon />}
          onClick={handlenext}
        >
          Next
        </Button>
      </CardActions>
    </Card>
  )
}
