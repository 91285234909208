import { Button } from '@mui/material';
import React from 'react'
import { CSVLink } from 'react-csv';

const DownloadComponent = ({headers, data, fileName}) => {
  return (
    <CSVLink data={data} headers={headers} filename={fileName} style={{ textDecoration: 'none' }}>
      <Button variant="contained" color="primary" className='my-2'>
        Download CSV
      </Button>
    </CSVLink>
  )
}

export default DownloadComponent