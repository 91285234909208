import React, { useEffect, useState } from 'react';
import { Card, CardContent, CardHeader, Typography, Grid, IconButton } from '@mui/material';
import { FiArrowUp, FiArrowDown, FiBox, FiDollarSign } from 'react-icons/fi';
import { MdCurrencyRupee } from "react-icons/md";

import axios from 'axios';
import { BASE_Url } from '../../URL.js/URL';

// Importing and registering required Chart.js components
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, ArcElement, BarElement, Tooltip as ChartTooltip, Legend } from 'chart.js';
import { Doughnut, Line ,Bar} from 'react-chartjs-2';
import { AttachMoney, DeliveryDining, LocalDining, Restaurant } from '@mui/icons-material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useNavigate } from 'react-router-dom';
// Register the components with Chart.js
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  ArcElement,
  BarElement, // Registering BarElement
  ChartTooltip,
  Legend
);




const data = [
  { name: "Khichdi", value: 1024374 },
  { name: "Rose Chai", value: 246981 },
  { name: "Masala Chai", value: 1024374 },
  { name: "Samosa", value: 785632 },
  { name: "Vada Pav", value: 543210 },
  { name: "Pani Puri", value: 678901 },
];
const colors = ["indigo", "cyan", "teal", "amber", "rose", "violet"];
const Dashboard = () => {
  const total = data.reduce((sum, item) => sum + item.value, 0);
    const [dashboardData, setDashboardData] = useState({
        todaysSales: 0,
        salesInAMonth: 0,
        taxInAMonth: 0,
        discountInAMonth: 0,
        onlineSalesInAMonth: 0,
        dineInSalesInAMonth: 0,
        takeOutSalesInAMonth: 0,
      });
 const bankAccount = JSON.parse(localStorage.getItem('STORE_DATA'))?.bankAccount;
  useEffect(() => {
    const fetchData = async () => {
      const { storeId, saasId } = JSON.parse(localStorage.getItem("STORE_DATA"));

      try {
        const response = await axios.get(
          `${BASE_Url}/dashboard/get-dashboard-in1/${saasId}/${storeId}`
        );
        if (response.data.status) {
          setDashboardData(response.data.data);
        }
      } catch (error) {
        console.error('Error fetching dashboard data:', error);
      }
    };
    fetchData();
  }, []);

  const [doughnutData, setDoughnutData] = useState({
    labels: ['No Item'],
    datasets: [
      {
        data: [20],
        backgroundColor: ['#c3c3c3', '#F4A79D', '#344BFD', '#FF9F40', '#F68D2B'],
      },
    ],
  });
  const [topItems, setTopItems] = useState([]);
  
  const fetchChartData = async () => {
    try {
      const { storeId, saasId } = JSON.parse(localStorage.getItem("STORE_DATA"));
      const response = await axios.get(`${BASE_Url}/dashboard/get-dashboard-item-data/${saasId}/${storeId}`); // Replace with your actual API endpoint
      const chartData = response.data.data; // Access the 'data' field from the response
  
      // Check if chartData is not empty
      if (chartData.amount.length > 0) {
        // Combine product names and amounts into an array of objects
        const items = chartData.productName.map((name, index) => ({
          name,
          amount: chartData.amount[index],
          qty: chartData.qty[index], // Include quantity
        }));
  
        // Sort the items by amount in descending order
        const sortedItems = items.sort((a, b) => b.amount - a.amount);
  
        // Get the top 5 items
        const top5Items = sortedItems.slice(0, 5);
  
        // Update doughnutData with the top 5 items
        setDoughnutData({
          labels: top5Items.map(item => item.name), // Use the names of the top 5 items as labels
          datasets: [
            {
              label: 'Amount',
              data: top5Items.map(item => item.amount), // Use the amounts of the top 5 items
              backgroundColor: ['#0E2954', '#F4A79D', '#344BFD', '#FF9F40', '#F68D2B'], // Use different colors for the top 5
            },
            {
              label: 'Quantity',
              data: top5Items.map(item => item.qty), // Quantity data for the second dataset
              backgroundColor: ['#0E2954', '#F4A79D', '#344BFD', '#FF9F40', '#F68D2B'], // Different colors for the quantity dataset
            },
          ],
        });
  
        // Update the topItems state to display below the chart
        setTopItems(top5Items);
      }
    } catch (error) {
      console.error('Error fetching chart data:', error);
    }
  };
  
  // Call fetchChartData when the component mounts
  useEffect(() => {
    fetchChartData();
  }, []);

  const stats = [
    // { title: 'This Month Sales', value: dashboardData.salesInAMonth, icon: FiBox, changeType: 'increase' },
    // { title: 'Total Tax', value: `₹${dashboardData.taxInAMonth}`, icon: bankAccount == "CAD"?FiDollarSign:MdCurrencyRupee, changeType: 'decrease' },
    // { title: 'Total Discount', value: `₹${dashboardData.discountInAMonth}`, icon: bankAccount == "CAD"?FiDollarSign:MdCurrencyRupee, changeType: 'decrease' },
    {
      title: 'Today Sales',
      value: `₹ ${dashboardData.todaysSales || 0}`, // Default to 0 if null or undefined
      orders: `Total Sales`,
      icon: AttachMoney,
      bg: 'bg-green-100',
      iconColor: 'text-green-600'
    },
    {
      title: 'Today Dine In sales',
      value: `₹ ${dashboardData.dineInSalesInADay || 0}`, // Default to 0 if null or undefined
      orders: `4 Orders`,
      icon: LocalDining,
      bg: 'bg-red-100',
      iconColor: 'text-red-600'
    },
    {
      title: 'Today PickUp Sales',
      value: `₹ ${dashboardData.takeOutSalesInADay || 0}`, // Default to 0 if null or undefined
      orders: `3 Orders`,
      icon: Restaurant,
      bg: 'bg-blue-100',
      iconColor: 'text-blue-600'
    },
    {
      title: 'Today Delivery sales',
      value: `₹ ${dashboardData.onlineSalesInADay || 0}`, // Default to 0 if null or undefined
      orders: `5 Orders`,
      icon: DeliveryDining,
      bg: 'bg-purple-100',
      iconColor: 'text-purple-600'
    },
    {
      title: 'Monthly Sales',
      value: `₹ ${dashboardData.salesInAMonth || 0}`, // Default to 0 if null or undefined
      orders: `Total Sales`,
      icon: AttachMoney,
      bg: 'bg-green-100',
      iconColor: 'text-green-600'
    },
    {
      title: 'Monthly (DineIn)',
      value: `₹ ${dashboardData.dineInSalesInAMonth || 0}`, // Default to 0 if null or undefined
      orders: `4 Orders`,
      icon: LocalDining,
      bg: 'bg-red-100',
      iconColor: 'text-red-600'
    },
    {
      title: 'Monthly PickUp Sales',
      value: `₹ ${dashboardData.takeOutSalesInAMonth || 0}`, // Default to 0 if null or undefined
      orders: `3 Orders`,
      icon: Restaurant,
      bg: 'bg-blue-100',
      iconColor: 'text-blue-600'
    },
    {
      title: 'Monthly Delivery',
      value: `₹ ${dashboardData.onlineSalesInAMonth || 0}`, // Default to 0 if null or undefined
      orders: `5 Orders`,
      icon: DeliveryDining,
      bg: 'bg-purple-100',
      iconColor: 'text-purple-600'
    },
    {
      title: 'Yearly Sales',
      value: `₹ ${dashboardData.salesInAYear || 0}`, // Default to 0 if null or undefined
      orders: `Total Sales`,
      icon: AttachMoney,
      bg: 'bg-green-100',
      iconColor: 'text-green-600'
    },
    {
      title: 'Yearly (DineIn)',
      value: `₹ ${dashboardData.dineInSalesInAYear || 0}`, // Default to 0 if null or undefined
      orders: `4 Orders`,
      icon: LocalDining,
      bg: 'bg-red-100',
      iconColor: 'text-red-600'
    },
    {
      title: 'Yearly PickUp Sales',
      value: `₹ ${dashboardData.takeOutSalesInAYear || 0}`, // Default to 0 if null or undefined
      orders: `3 Orders`,
      icon: Restaurant,
      bg: 'bg-blue-100',
      iconColor: 'text-blue-600'
    },
    {
      title: 'Yearly Delivery',
      value: `₹ ${dashboardData.onlineSalesInAYear || 0}`, // Default to 0 if null or undefined
      orders: `5 Orders`,
      icon: DeliveryDining,
      bg: 'bg-purple-100',
      iconColor: 'text-purple-600'
    }



    
    // { title: 'Last Month Sales', value: dashboardData.total_sales_last_month, icon: FiBox,Y changeType: 'increase' },
    // { title: 'Last Year Sales', value: dashboardData.total_sales_last_year, icon: FiBox, changeType: 'increase' },

  ];
  // const doughnutData = {
  //   labels: ['Plain Tea', 'Butter Scotch Cake', 'Choco Lava Cake', 'Dark Forest Cake', 'Masala Tea'],
  //   datasets: [
  //     {
  //       data: [10, 20, 30, 25, 15],
  //       backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56', '#FF9F40', '#FF6384'],
  //     },
  //   ],
  // };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { month: 'short', day: 'numeric' }; // Customize to "MMM DD"
    return date.toLocaleDateString('en-US', options);
  };

  const [barData, setBarData] = useState({  
    labels: [],  
    datasets: [  
      {  
        label: 'Sales',  
        data: [],  
        backgroundColor: '#42A5F5', // Change color to blue  
      },  
    ],  
  });  

    // Fetching the API data and updating state
    const fetchSalesData = async () => {  
      try {  
        const { storeId, saasId } = JSON.parse(localStorage.getItem("STORE_DATA"));  
        const response = await axios.get(`${BASE_Url}/dashboard/get-graph-data/${saasId}/${storeId}`);  
        const salesData = response.data.data;  
  
        // Convert dates to "MMM" format  
        const formattedDates = salesData.dates.map(date => formatDate(date));  
  
        // Handle null values by replacing them with 0  
        const salesAmounts = salesData.salesAmounts.map(amount => amount !== null ? amount : 0);  
  
        // Update barData state with the fetched data  
        setBarData({  
          labels: formattedDates,  
          datasets: [  
            {  
              label: 'Sales',  
              data: salesAmounts,  
              backgroundColor: '#42A5F5', // Set bar color  
            },  
          ],  
        });  
      } catch (error) {  
        console.error('Error fetching sales data:', error);  
      }  
    };  
  
    // Call fetchSalesData when the component mounts
    useEffect(() => {
      fetchSalesData();
    }, []);
    const navigate = useNavigate();
    const handleNavigation = () => {
      navigate('/Reports'); // Navigate to /Reports route when clicked
    };
  return (
    <div className="p-4 space-y-4 max-h-[calc(100vh-130px)] overflow-y-auto">
 <Grid container spacing={2}>
  {stats.map((stat, index) => (
    <Grid item xs={12} sm={6} md={3} key={index}>
      <Card className="rounded-lg shadow-md">
        <CardContent className="flex items-center justify-between px-4 py-3">
          {/* Icon with background */}
          <div className={`rounded-full p-3 ${stat.bg}`}>
            <stat.icon className={`w-8 h-8 ${stat.iconColor}`} />
          </div>
          {/* Content */}
          <div className="ml-4 flex-grow">
            <Typography variant="subtitle2" color="textSecondary" noWrap>
              {stat.title}
            </Typography>
            <Typography variant="h6" className="font-bold" noWrap>
              {stat.value}
            </Typography>
            {/* <Typography variant="caption" color="textSecondary" noWrap>
              {stat.orders}
            </Typography> */}
          </div>
          {/* More button */}
          <IconButton onClick={handleNavigation}>
  <ArrowForwardIcon />
</IconButton>
        </CardContent>
      </Card>
    </Grid>
  ))}
</Grid>

      {/* Scrollable Chart Section */}
      <div className="max-h-[400px] overflow-y-auto mb-10">
        <Grid container spacing={2}>
          {/* Income Chart */}
          <Grid item xs={12} sm={6} md={4}>
    <Card>
      <CardHeader title="Today Product Performance" />
      <CardContent>
        <div style={{ width: '100%', height: '300px' }}>
          <Doughnut data={doughnutData} options={{ maintainAspectRatio: false }} />
        </div>

        {/* Display the top 5 items below the chart */}
        {/* <div style={{ marginTop: '20px' }}>
          <h4>Top 5 Items</h4>
          <ul>
            {topItems.map((item, index) => (
              <li key={index}>
                {item.name}: {item.amount}
              </li>
            ))}
          </ul>
        </div> */}
      </CardContent>
    </Card>
  </Grid>

          {/* Sales Overview Chart */}
          <Grid item xs={12} sm={6} md={8}>  
      <Card>  
        <CardHeader title="Monthly Sales Report" />  
        <CardContent>  
          <div style={{ width: '100%', height: '300px' }}>  
            <Bar data={barData} options={{ maintainAspectRatio: false }} />  
          </div>  
        </CardContent>  
      </Card>  
    </Grid>  
        </Grid>
      </div>
    </div>
  );
};

export default Dashboard;
