import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import { BASE_Url } from "../../URL.js/URL";
import DownloadComponent from "../CSVDownload/DownloadComponent";
function Reports({ formDate,toDate }) {
  const [allData, setAllData] = useState([]);
  const {storeId, saasId}= JSON.parse(localStorage.getItem('STORE_DATA'));
  useEffect(() => {
    axios
      .get(
        `${BASE_Url}/user-master/table_performance/${saasId}/${storeId}/${formDate}/${toDate}`
      )
      .then((res) => {
        setAllData(res?.data?.data && res?.data?.data.length > 0? res?.data?.data : []);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, [formDate,toDate]);

  const header = [
    { label: "Table Number", key: "table_no" },
    { label: "Table Category", key: "table_category" },
    { label: "Net Profit", key: "net_profit" },
    { label: "Date", key: "date" },
  ];

  return (
    <div  style={{ height: "100vh", overflowY: "auto"}}>
      <DownloadComponent headers={header} data={allData &&allData} fileName="TablePerformanceReport"/>
       {/* <h5 className="mt-3 mb-2 mx-2 text-xl font-bold">
              Table Performance Report
            </h5> */}
    <div className="flex flex-wrap"
  style={{ marginBottom: "40%" }}
    >
      {allData?.map((ele) => (
        <div key={ele.table_no} className="m-2">
          <div>
            {/* <h2 className="mt-3 mb-2 text-xl font-bold">
              Category {ele.table_category}
            </h2> */}
            <div className="flex flex-col md:flex-row gap-1 rounded-lg">
              <div className="border-2 rounded-lg">
                <p className="p-2 bg-indigo-300  font-medium rounded-t-lg">
                  Table Number {ele.table_no}
                </p>

                <p className="p-2  font-medium">
                  Table Transaction- {ele.table_category}
                </p>
                <p className="p-2 font-medium">
                  Table Sales- {ele.net_profit}
                </p>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div> </div>
  );
}

export default Reports;
