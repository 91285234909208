import React, { useEffect, useRef } from 'react'
import { Box, Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import { BASE_Url } from '../../URL.js/URL';
import axios from 'axios';
import { useReactToPrint } from 'react-to-print';
import DownloadComponent from '../CSVDownload/DownloadComponent';
import DownloadPDFButton from '../CSVDownload/DownloadPDFButton';
const ExpeseReport = ({formDate,toDate}) => {

    const [allData, setAllData] = React.useState([]);
    const { storeId, saasId } = JSON.parse(localStorage.getItem('STORE_DATA'));
    const fetchData = async () => {
        try {
            const response = await axios.get(`${BASE_Url}/expense/view-expenses/${saasId}/${formDate}/${toDate}`);
            setAllData(response.data.data);
        } catch (error) {
            console.error(error);
        }
    }
    useEffect(() => {
        fetchData();
    }, [formDate, toDate]);

       //new update code
       const header= [
        {
          label: "Category Name",
          key: "category_name",
        },
        {
          label: "Expense Date",
          key: "expense_date",
        },
    
        {
          label: "Total Amount",
          key: "total_amount",
        },
      ]

   const tableRef = useRef(null); // Ref for the table container

   const handleTablePrint = useReactToPrint({
     content: () => tableRef.current,
     documentTitle: 'Sales_Report',
     onBeforePrint: () => console.log("Printing Sales Table..."),
   });
  return (
    <div>
    <DownloadComponent headers={header} fileName="ExpeseReport" data={allData} />
      <Button
              variant="contained"
              className="mx-2"
              style={{background:"rgb(14, 41, 84)",color:"white"}}
              onClick={handleTablePrint}
            >
              PRINT 
            </Button>
               {/* Reusable PDF Download Button */}
               <DownloadPDFButton
              data={allData}
              headers={header}
              fileName="ExpeseReport.pdf"
            />

        <TableContainer  ref={tableRef} component={Paper}>
              <Table className="text-nowrap" aria-label="simple table">
                <TableHead className="bg-dark-purple">
                  <TableRow>
                    <TableCell className=" text-white">Category Name</TableCell>
                    <TableCell className=" text-white">Expense Date</TableCell>
                    <TableCell className=" text-white">Total Amount</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {allData&&allData?.map((ele,index) => (
                    <TableRow key={index}>
                      <TableCell>{ele.category_name}</TableCell>
                      <TableCell>{ele.expense_date}</TableCell>
                      <TableCell>{ele.total_amount}</TableCell>
                     
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
    </div>
  )
}

export default ExpeseReport