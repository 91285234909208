import React from 'react'
import { useNavigate } from 'react-router-dom';
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { BASE_Url } from '../../URL.js/URL';
import Swal from 'sweetalert2';
import { BsArrowLeft } from 'react-icons/bs';
function AddSupplier({ open, onHide ,getPartyOptions}) {
    const { storeId, saasId } = JSON.parse(localStorage.getItem('STORE_DATA'));
    const [supplierdata, searchSuppliers] = React.useState(
        {
        party_name: "",
          gstin: "",
          phone_number: "",
          gst_type: "",
          state: "",
          email: "",
          billing_address: "",
          opening_balance: "0",
          credit_limit_flag: false,
          creditLimitAmount: "0"
      }
      );

    const navigate = useNavigate()
    const handleInputChange = (e) => {
        const { name, value } = e.target
        searchSuppliers(prevState => ({
          ...prevState,
          [name]: value
        }))
      }
    const addsuplire = async (e) => {
      e.preventDefault();
      try {
       supplierdata['saas_id'] = saasId;
       supplierdata['store_id'] = storeId;
        const response = await fetch(`${BASE_Url}/supplier/create`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(supplierdata)
        });
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        console.log(data);
        Swal.fire({
          title: 'Supplier Added Successfully',
          icon:'success',
          timer:2000
        })
        getPartyOptions(1)
        onHide();
      } catch (error) {
        console.error('Error:', error);
      }
    }
  return (
    <div>
             <Modal isOpen={open} toggle={onHide} size="xl" className="modal-fullscreen">
        <ModalHeader toggle={onHide}>
          <div className="d-flex align-items-center gap-4">
            <BsArrowLeft onClick={onHide} className="mouse-pointer" />
            <p className="fw-bold mt-2">Create Supplier</p>
          </div>
        </ModalHeader>
        <ModalBody>
          <div className="max-w-md max-h-[calc(100vh-170px)] overflow-y-auto mx-auto bg-white p-6 rounded-lg shadow-md">
      <div className="flex items-center mb-6">
  
        <h2 className="text-xl font-semibold">Add Supplier</h2>
      </div>
      <form onSubmit={addsuplire}>
        <div className="space-y-4">
          <input
            type="text"
            name="party_name"
            placeholder="Supplier Name"
            value={supplierdata.party_name}
            onChange={handleInputChange}
            className="w-full p-2 border border-gray-300 rounded-md"
          />
          <div className="flex space-x-4">
            <input
              type="text"
              name="gstin"
              placeholder="GSTIN"
              value={supplierdata.gstin}
              onChange={handleInputChange}
              className="w-1/2 p-2 border border-gray-300 rounded-md"
            />
            <input
              type="text"
              name="gst_type"
              placeholder="GST Type"
              value={supplierdata.gst_type}
              onChange={handleInputChange}
              className="w-1/2 p-2 border border-gray-300 rounded-md"
            />
          </div>
          <input
            type="tel"
            name="phone_number"
            placeholder="Phone Number"
            value={supplierdata.phone_number}
            onChange={handleInputChange}
            className="w-full p-2 border border-gray-300 rounded-md"
          />
          <input
            type="email"
            name="email"
            placeholder="Email"
            value={supplierdata.email}
            onChange={handleInputChange}
            className="w-full p-2 border border-gray-300 rounded-md"
          />
          <input
            type="text"
            name="billing_address"
            placeholder="Billing Address"
            value={supplierdata.billing_address}
            onChange={handleInputChange}
            className="w-full p-2 border border-gray-300 rounded-md"
          />
          <input
            type="text"
            name="state"
            placeholder="State"
            value={supplierdata.state}
            onChange={handleInputChange}
            className="w-full p-2 border border-gray-300 rounded-md"
          />
          <div className="flex space-x-4">
            <input
              type="text"
              name="opening_balance"
              placeholder="Opening Amount"
              value={supplierdata.opening_balance}
              onChange={handleInputChange}
              className="w-1/2 p-2 border border-gray-300 rounded-md"
            />
            <input
              type="text"
              name="creditLimitAmount"
              placeholder="Credit Limit Amount"
              value={supplierdata.creditLimitAmount}
              onChange={handleInputChange}
              className="w-1/2 p-2 border border-gray-300 rounded-md"
            />
          </div>
        </div>
        <div className="flex justify-center space-x-4 mt-6">
          <button
            type="submit"
            className="px-4 py-2 bg-green-500 text-white rounded-md hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-opacity-50"
          >
            Save
          </button>
          <button
            type="button"
            className="px-4 py-2 bg-gray-500 text-white rounded-md hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-opacity-50"
          >
            Close
          </button>
        </div>
      </form>
    </div>
    </ModalBody>
    </Modal>
    </div>
  )
}

export default AddSupplier
