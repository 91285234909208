import React, { useEffect, useState } from "react";
import { Modal, Button as BootstrapButton } from "react-bootstrap";
import {
  TextField,
  Button as MUIButton,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import axios from "axios";
import { BASE_Url } from "../../URL.js/URL";
import Swal from "sweetalert2";

const UpdateInventoryModal = ({ show, onHide, getAllData, selectedRow }) => {
  const [name, setName] = useState("");
  const [quantity, setQuantity] = useState("");
  const [Receivedqty, setReceivedqty] = useState("");
  const [cost, setCost] = useState("");
  const [unit, setUnit] = useState("");

  useEffect(() => {
    // Autofill the fields when the modal is shown and selectedRow is available
    if (selectedRow) {
      setName(selectedRow.inventory_name);
      setQuantity(selectedRow.opening_qty);
      setReceivedqty(selectedRow.received_qty);
      setCost(selectedRow.cost);
      setUnit(selectedRow.unit);
    }
  }, [selectedRow, show]);

  const handleUpdateMaterialDetail = () => {
    const { storeId, saasId } = JSON.parse(localStorage.getItem("STORE_DATA"));

    if (name && cost && quantity) {
      // Make API request to update the inventory item
      const apiUrl = `${BASE_Url}/update-inventory-bom/${selectedRow.id}`;

      const requestBody = {
        saas_id: saasId,
        store_id: storeId,
        inventory_name: name,
        opening_qty:quantity || "0", 
        cost,
        unit,
        received_quantity: Receivedqty || "0", // Use existing or default
        sold_quantity: selectedRow.sold_quantity || "0",         // Use existing or default
      };

      const headers = {
        "Content-Type": "application/json",
      };

      axios
        .put(apiUrl, requestBody, { headers })
        .then((res) => {
          Swal.fire({
            title: "Item Update Successful",
            icon: "success",
            timer: 1000,
          });
          getAllData(1);
          onHide();
          // Clear the fields after submission
          setName("");
          setQuantity("");
          setCost("");
          setUnit("");
        })
        .catch((err) => {
          console.error("API Error:", err);
        });
    } else {
      Swal.fire({
        title: "Please fill all fields",
        icon: "warning",
      });
    }
  };

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Update Raw Material Detail</Modal.Title>
      </Modal.Header>
      <Modal.Body>
      <InputLabel id="unit-select-label">Raw Material name</InputLabel>
        <TextField
          type="text"
          value={name}
          placeholder="Item Name"
          onChange={(e) => setName(e.target.value)}
          className="form-control mb-3"
          fullWidth
        />
        <InputLabel id="unit-select-label">Opening Quantity</InputLabel>
        <TextField
          type="text"
          value={quantity}
          placeholder="Opening Quantity"
          onChange={(e) => setQuantity(e.target.value)}
          className="form-control mb-3"
          fullWidth
        />
        <InputLabel id="unit-select-label">Received Quantity </InputLabel>
        <TextField
          type="text"
          value={Receivedqty}
          placeholder="Received Quantity"
          onChange={(e) => setReceivedqty(e.target.value)}
          className="form-control mb-3"
          fullWidth
        />
        <InputLabel id="unit-select-label">Cost per Unit</InputLabel>
        <TextField
          type="text"
          value={cost}
          placeholder="Item Cost"
          onChange={(e) => setCost(e.target.value)}
          className="form-control mb-3"
          fullWidth
        />
          <InputLabel id="unit-select-label">Unit</InputLabel>
        <FormControl fullWidth className="form-control mb-3">
          <Select
            labelId="unit-select-label"
            value={unit}
            onChange={(e) => setUnit(e.target.value)}
          >
            <MenuItem value="Kg">KG</MenuItem>
            <MenuItem value="Gm">Gm</MenuItem>
            <MenuItem value="Ml">Ml</MenuItem>
            <MenuItem value="Lt">Lt</MenuItem>
          </Select>
        </FormControl>
      </Modal.Body>
      <Modal.Footer>
        <MUIButton className="mb-1" fullWidth variant="contained"  onClick={onHide}>
          Cancel
        </MUIButton>
        <MUIButton fullWidth variant="contained" onClick={handleUpdateMaterialDetail}>
          Update
        </MUIButton>
      </Modal.Footer>
    </Modal>
  );
};

export default UpdateInventoryModal;
