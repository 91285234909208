import React, { useEffect, useRef } from 'react'
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
// import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Container, ModalFooter } from "reactstrap";
import Modal from 'react-bootstrap/Modal';
import axios from 'axios';
import { BASE_Url } from '../URL.js/URL';
import { useState } from 'react';
import { Viewer, Worker } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import { AiOutlineClose } from 'react-icons/ai';
import KotPrintModal from './KotPrintModal';
import { useReactToPrint } from 'react-to-print';
import { useNavigate } from 'react-router-dom';
const style = {
    position: 'absolute',
    overflow:"scroll",
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    height:"100%",
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };
const Pdfstyle = {
    position: 'absolute',
    overflow:"scroll",
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    height:"100%",
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };
const TableToPrint = ({setOpen,open,orderData,printRef}) => {
  console.log("table data",orderData,open)
  const [viewPdf, setViewPdf] = useState(false);
  const [PdfFile, setPDFFile] = useState("");
  const defaultLayoutPluginInstance = defaultLayoutPlugin();
    // console.log("this order id",OderID)
  const handleClose = () => setOpen(false);
  // orderData.item_detail_list.map((el)=>console.log("this order data",el.item_name))
  console.log("orderData", orderData);
  const body ={
    order_id: orderData.order_id,
    table_number: orderData.table_number,
    order_type: "Dine-In",
    cart_list: orderData.item_detail_list }
  // const handlePrint=async()=>{
  //   try {
  //     setViewPdf(true)
  //     const Response= await axios.post(`${BASE_Url}/transaction/print_recept`,body)
  //     console.log("this pdf file",Response.data.data)
  //     setPDFFile(Response.data.data)
  //   } catch (error) {
  //     console.log(error)
  //   }

  // }

  const navigate = useNavigate();
  const componentRef = useRef();
  // const focusClose = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    onBeforePrint: () => {
      // Add any logic you need before printing
      console.log("printing Start");
    },
    onAfterPrint: () => {
      // Add any logic you need after printing
      setOpen(false);
      if(orderData?.order_type === "Delivery"){
        navigate("/online")
      }else{
      navigate("/Tables")
      }
      console.log("printing End");
      // focusClose.current.focus();
    },
  });
  //filter from orderData those are Printed false that item get only
  
 

  const header = {
    container: {
      fontFamily: "Courier New, monospace",
      fontSize: "small",
    },
    text: {
      fontFamily: "Courier New, monospace",
      fontSize: "small",
    },
  };
  return (
    <div>
   
    <Modal
      show={open}
      onHide={handleClose}
       className="p-0"
       aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"

    
    >
           <Box >
               <div
               className="-mx-2 mt-1"
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
                cursor: "pointer",
              }}
              onClick={() => {
                setOpen((state) => !state);
                // dispatch(RemoveCart());
                // navigate("/");
              }}
            > <AiOutlineClose size={20} />
            </div>
            <div
              className="container"
              style={header.container}
              ref={componentRef}
            >
      <KotPrintModal orderData={orderData}/>
      </div>
      <ModalFooter>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Button
                variant="secondary"
                style={{background:"gray"}}
                  className=" text-white mt-10 mx-2 p-2"
                  autoFocus ={true}
                  onClick={() => {
                    setOpen(false);
                    // dispatch(RemoveCart());
                    // dispatch(HandelLinkCustomer([]));
                    // dispatch(RemoveInovice());
                    if(orderData?.order_type === "Delivery"){
                      navigate("/online");
                    }else{
                    navigate("/Tables");
                    }
                  }}
                  // ref={focusClose}
                >
                  Close 
                </Button>
                <Button
                variant="primary"
                style={{background:"blue"}}

                  className=" text-white mt-10 p-2"
                  onClick={handlePrint}
                >
                  PRINT 
                </Button>
                {/* <button className="bg-dark-subtle mt-10 p-2" onClick={openCashDrawer}>
                 Open CashDrawer
                </button> */}
              </div>
            </ModalFooter>
      </Box>

       {/* <Fade in={open}>
        <Box sx={style}>
        <table ref={printRef} className="table">
  <thead>
    <tr>
        
      <th scope="col">Order ID</th>
      <th scope="col">Item Name</th>
      <th scope="col">Qty</th>
    </tr>
  </thead>
  <tbody>
    
    {orderData.item_detail_list.map((el)=>
  <tr>
    <td>{orderData.order_id}</td>
    <td>{el.item_name}</td>
    <td>{el.item_qty}</td>
  </tr>
    )}
  </tbody>
</table>
<Button className="mt-8 mb-2 items-center px-1 ml-3 fw-bold"
 onClick={()=>
  {handlePrint();
 handleClose();}}>Print</Button>
<Button className="mt-8 mb-2 items-center px-1 ml-3 fw-bold" onClick={handleClose}>Close</Button>

        </Box>
      </Fade> */}
    </Modal>
    
  </div>
  )
}

export default TableToPrint