import moment from "moment";
import React, { useEffect } from "react";
import { PrintProvider, Print } from "react-easy-print";

function Invoicemodel({payment_detail,totalDiscount,customer,products,optionTick, orderType, payment_mode,invoiceNo,tableNo}) {
  // Static data to replace Redux store data

  useEffect(() => {
  console.log("optionTick",optionTick)
  }, [optionTick])
  
  const { storeName,userId,userName } = JSON.parse(localStorage.getItem("USER_DATA"));
  
  console.log("invoice product",products)
  const totalamount = products.reduce((sum, item) => 
    sum + (Number(item.itemPrice) * Number(item.itemQty)), 0);
  const totalitemDis = products.reduce(
    (sum, item) => sum + item.discount * item.itemQty,
    0
  );
  const TotalGross = parseInt(totalamount) + parseInt(totalitemDis);
  const totalProductQty = products.reduce(
    (total, item) => total + parseInt(item.itemQty),
    0
  );
  const {
    saasId,
    haderLine1,
    haderLine2,
    haderLine3,
    haderLine4,
    futterLine1,
    futterLine2,
    futterLine3,
    futterLine4,
    futterLine5,
    futterLine6,
    futterLine7,
    futterLine8,
    futterLine9,
    futterLine10,
  } = JSON.parse(localStorage.getItem("STORE_DATA"));
  

  const styles = {
    fontFamily: "Verdana",
    fontSize: "medium",
    fontWeight: "bold",
  };

  const customLineStyle = {
    border: "1px solid black",
    margin: "8px 0",
  };
  const table = {
    border: "1px solid black",
    margin: "8px 0",
  };
  const receiptContent = (
    <div style={styles}>
      <p
        style={{ fontSize: "1.6rem" }}
        className="d-flex  justify-content-around fw-bold"
      >
        {storeName}
      </p>
      <p
        style={{ fontSize: "1.4rem" }}
        className="d-flex  justify-content-around fw-bold"
      >
        {haderLine1}
      </p>
      <p
        style={{ fontSize: "1.25rem" }}
        className="d-flex  justify-content-around fw-bold"
      >
        {" "}
        {haderLine2}
      </p>
      <p
        style={{ fontSize: "1.25rem" }}
        className="d-flex  justify-content-around fw-bold"
      >
        {haderLine3}
      </p>
      <p
        style={{ fontSize: "1.25rem" }}
        className="d-flex  justify-content-around fw-bold"
      >
        {haderLine4}
      </p>
      {customer?.mobile_number && (
        <>
        <div className="fw-bold mt-5" style={customLineStyle}></div>
        <div className="d-flex justify-content-between fw-bold text-nowrap">
          {/* <p> Name: {customer?.name}</p> */}
          <p> Mobile Number: {customer?.mobile_number}</p>

        </div>
        </>
      )}
      <div className="fw-bold mt-2" style={customLineStyle}></div>
      <div className="d-flex justify-content-between fw-bold text-nowrap">
          <p>Date: {payment_detail&&payment_detail[0]?.businessDate}</p>
          <p>{orderType}: {tableNo}</p>

        </div>
      <div className={`d-flex justify-content-between fw-bold text-nowrap`}>
       <p>Cashier: {userName}</p>
      <p  className="d-flex justify-center">Bill No: {invoiceNo}</p>

        </div>
      <div className="fw-bold mt-2" style={customLineStyle}></div>

      <table className="w-100  mx-md-auto" style={{ fontSize: "1.2rem" }}>
        <thead>
          <tr className="border-dark" style={{ borderBottomWidth: "2px" }}>
            <th>Item</th>
            <th className="px-3">Qty</th>
            <th className="px-3">Rate</th>
            <th>Value</th>
          </tr>
        </thead>
        <tbody>
          {products &&
            products.map((el) => {
              console.log("invoice jk product",el)
              return (
                <>
                  {" "}
                  {el.itemQty > 0 && (
                    <>
                      <tr className="fw-bold">
                        <td style={{ fontSize: "1rem" }}>{el?.itemName}</td>
                        <td
                          className="text-center"
                          style={{ fontSize: "1rem" }}
                        >
                          {el.itemQty}
                        </td>
                        <td style={{ fontSize: "1rem" }}>{el.itemPrice}</td>
                        <td style={{ fontSize: "1rem" }}>
                          {el.itemPrice * el.itemQty}
                        </td>
                      </tr>
                    </>
                  )}
                </>
              );
            })}
        </tbody>
      </table>

      <div className="fw-bold" style={customLineStyle}></div>

      <div className="container" style={{ fontSize: "1.25rem" }}>
        <div className="d-flex justify-content-between fw-bold text-nowrap">
          <p>Qty</p>
          <p>{totalProductQty}</p>
   
          <p>Gross</p>
     
          <p>{totalamount}</p>

        </div>
        {/* {totalDiscount && <div className="d-flex justify-content-between fw-bold text-nowrap">
          <p></p>
          <p></p>
          <p>Discount</p>
          <p>{totalDiscount}</p>
        </div>} */}
      </div>
      <div className="fw-bold mt-2" style={customLineStyle}></div>
      <div className="container mb-1 mt-1" style={{ fontSize: "1.2rem" }}>
        <p style={{ fontSize: "1.2rem" }}>Settlement</p>
        {payment_detail &&
          payment_detail.map((item) => {
            return (
              <div className="row fw-bold" key={item.option}>
                {/* {item.option === "Cash" ? ( */}
                  <div className="col text-nowrap">
                    <p>
                      {item.tenderName}
                    </p>
                  </div>
                {/* ) : ( */}
                  {/* <div className="col text-nowrap">
                    <p>{item.tenderName}</p>
                  </div> */}
                {/* )} */}
                <div className="col">
                  <p className="float-end">  {item.amount}</p>
                </div>
              </div>
            );
          })}
      </div>
      
      {orderType=="Online" &&
         <div className="container" style={{ fontSize: "1.25rem" }}>
      <div className="d-flex justify-content-between fw-bold text-nowrap">
          <p>Payment Method</p>
          <p>{payment_mode}</p>
        </div>
        </div>}

      <div className="fw-bold" style={customLineStyle}></div>
      <div className="container py-2 fw-bold" style={{ fontSize: "1.1rem" }}>
        <div className="row">
          <p
            style={{ fontSize: "1rem" }}
            className="d-flex  justify-content-center fw-bold m-0"
          >
            {futterLine1}{" "}
          </p>
          <p
            style={{ fontSize: "1rem" }}
            className="d-flex  justify-content-center fw-bold m-0"
          >
            {" "}
            {futterLine2}
          </p>
          <p
            style={{ fontSize: "1rem" }}
            className="d-flex  justify-content-center fw-bold m-0"
          >
            {futterLine3}
          </p>
          <p
            style={{ fontSize: "1rem" }}
            className="d-flex  justify-content-center fw-bold m-0"
          >
            {" "}
            {futterLine4}{" "}
          </p>
          <p
            style={{ fontSize: "1rem" }}
            className="d-flex  justify-content-center fw-bold m-0"
          >
            {futterLine5}
          </p>
          <p
            style={{ fontSize: "1rem" }}
            className="d-flex  justify-content-center fw-bold m-0"
          >
            {" "}
            {futterLine6}
          </p>
          <p
            style={{ fontSize: "1rem" }}
            className="d-flex  justify-content-center fw-bold m-0"
          >
            {" "}
            {futterLine7}
          </p>
          <p
            style={{ fontSize: "1rem" }}
            className="d-flex  justify-content-center fw-bold m-0"
          >
            {" "}
            {futterLine8}
          </p>
          <p
            style={{ fontSize: "1rem" }}
            className="d-flex  justify-content-center fw-bold m-0"
          >
            {" "}
            {futterLine9}
          </p>
          <p
            style={{ fontSize: "1rem" }}
            className="d-flex  justify-content-center fw-bold m-0"
          >
            {" "}
            {futterLine10}
          </p>
        </div>
      </div>
      {/* <hr className="mb-5" style={{ color: "black" }} /> */}
    </div>
  );

  return (
    <PrintProvider>
      <Print>
        <div style={styles}>{receiptContent}</div>
      </Print>
    </PrintProvider>
  );
}

export default Invoicemodel;
