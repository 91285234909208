import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import "./Sidebar.css";
import SpaceDashboardIcon from "@mui/icons-material/SpaceDashboard";
import LooksTwoIcon from "@mui/icons-material/LooksTwo";
import ArticleIcon from "@mui/icons-material/Article";
import DescriptionIcon from "@mui/icons-material/Description";
import CardGiftcardIcon from "@mui/icons-material/CardGiftcard";
import PeopleIcon from "@mui/icons-material/People";
import { LuWarehouse } from "react-icons/lu";
import { BiLogoDigitalocean } from "react-icons/bi";
import { MdDeliveryDining } from "react-icons/md";
import { BiSolidPurchaseTag } from "react-icons/bi";
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import classNames from 'classnames';
import OutdoorGrillTwoToneIcon from '@mui/icons-material/OutdoorGrillTwoTone';
import PublicOutlinedIcon from '@mui/icons-material/PublicOutlined';
const Sidebar = ({ toggle, setToggle }) => {
  const isMobile = useRef(false);
  const location = useLocation();
  const [open, setOpen] = useState(true);
  const [overlay, showOverlay] = useState(false);

  const openNav = () => {
    document.getElementById("mySidebar").style.width = '250px';
    isMobile.current && showOverlay(true);
  };

  const closeNav = () => {
    // Your existing closeNav logic
  };

  useEffect(() => {
    const handleResize = () => {
      setToggle(window.innerWidth > 768);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [window.innerWidth]);

  //make a condition to check user_type is Admin then return true else false
  const jsonString = localStorage.getItem("USER_DATA");
  const parsedData = JSON.parse(jsonString || '{}'); // Fallback to an empty object if null
  const { userType } = parsedData;
  const isAdmin =  userType =="ADMIN";
  //Check Store Type 
  const jsonStoreString = localStorage.getItem("STORE_DATA");
  const parsedstoreData = JSON.parse(jsonStoreString || '{}'); // Fallback to an empty object if null
  const { qsr } = parsedstoreData;
  const RType =  !qsr;   

  
  const Menus = [
    { title: "Dashboard", link: "/", icon: <SpaceDashboardIcon /> , isActive:isAdmin },
    { title: "Orders/Billing", icon: <ArticleIcon />, link: "/Orders", isActive:true },
    { title: "Tables", icon: <LooksTwoIcon />, link: "/Tables" , isActive:RType},
    { title: "Online Orders", icon: <PublicOutlinedIcon />, link: "/online", isActive:true },
    { title: "Menu", icon: <ArticleIcon />, link: "/Menu", isActive:isAdmin },
    { title: "Recipe", icon: <OutdoorGrillTwoToneIcon />, link: "/Recipe", isActive:isAdmin },
    { title: "Inventory", icon: <LuWarehouse />, link: "/inventory", isActive:isAdmin },
    { title: "Analytics", icon: <DescriptionIcon />, link: "/Reports", isActive:true },
    { title: "Loyalty", icon: <CardGiftcardIcon />, link: "/CRM", isActive:true },
    { title: "Staff & Users", icon: <PeopleIcon />, link: "/StaffAndUsers", isActive:isAdmin },
    { title: "Digital Marketing", icon: <BiLogoDigitalocean />, link: "/digitalMarketing" , isActive:isAdmin},
    { title: "Purchase", icon: <BiSolidPurchaseTag />, link: "/purchase", isActive:isAdmin },
    { title: "Financial", icon: <AccountBalanceIcon />, link: "/financial", isActive:isAdmin },
  ];

  return (
    <>
      {toggle && (
        <>
          <div className="flex" id="mySidebar">
            {location.pathname !== "/NewOrders" ? (
              <div className={` bg-dark-purple h-screen p-0 pt-6 pb-3 duration-300 relative `} style={{ width: open ? '200px' : '60px' , overflowY:"auto"}}>
                <div className="text-white d-flex justify-center font-serif mt-4 text-xl font-medium">Food4You</div>
                <div className="m-auto mb-20">
                  {Menus.filter((item)=> item.isActive ==true).map((menu, index) => (
                    <div key={index}>
                      <Link
                        to={menu.link}
                        className={classNames("text-decoration-none text-lg flex items-center gap-x-2 p-2 hover:text-yellow mt-1", {
                          "bg-white text-dark-purple": location.pathname === menu.link,
                          "text-white": location.pathname !== menu.link,
                        })}
                        onClick={() => {
                          if (window.innerWidth < 768) {
                            closeNav();
                            setToggle(false);
                          }
                        }}
                      >
                        <span>{menu.icon}</span>
                        <span className={`text-base flex-1 font-medium ${!open && "hidden"}`}>
                          {menu.title}
                        </span>
                      </Link>
                    </div>
                  ))}
                </div>
              </div>
            ) : ""}
          </div>
          {Boolean(overlay) && (
            <div id="overlay" onClick={() => { closeNav(); setToggle(!toggle); }}></div>
          )}
        </>
      )}
    </>
  );
};

export default Sidebar;
