'use client'

import { useEffect, useState } from 'react'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Radio,
  RadioGroup,
  FormControlLabel,
} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'

export default function FeedBackQuetion({setQuestionAndSubQuestion,handlenext,expanded, setExpanded,questions}) {
  const {feedback} = useSelector((state) => {
    return state.tableData.createCustomer;
  });
  
  const params = useParams();
  const {saas_id } = params;
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false)
  }



  const {subQuestion} =feedback
 
  

  const handleonChange = (event, question) => {
    console.log("Selected product: ", event.target.value,question)
    setQuestionAndSubQuestion(question,event.target.value )
  }

  return (
    <div className="max-w-md mx-auto p-4 bg-white" style={{maxHeight:"calc(100vh - 150px)" , overflowY: "auto" }}>
      <div className="text-center border-b pb-2 mb-4">Feedback</div>
      
      <div className="text-sm mb-6">
        What is one thing we could have done differently to improve your experience?
      </div>

      <div className="space-y-2">
        {questions && questions.map((el)=>{return(<Accordion
          key={el?.question?.questionId}
          expanded={expanded === el?.question?.question}
          onChange={handleChange(el?.question?.question)}
          sx={{
            boxShadow: 'none',
            '&:before': {
              display: 'none',
            },
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            sx={{
              borderRadius: '8px',
              border: '1px solid #e5e7eb',
              '&.Mui-expanded': {
                borderBottomLeftRadius: 0,
                borderBottomRightRadius: 0,
              },
            }}
          >
            {el?.question?.question}
          </AccordionSummary>
          <AccordionDetails
            sx={{
              border: '1px solid #e5e7eb',
              borderTop: 0,
              borderBottomLeftRadius: '8px',
              borderBottomRightRadius: '8px',
            }}
          >
            <RadioGroup
              value={subQuestion}
              onChange={(event)=>handleonChange(event,el?.question?.question)}
            >
              {el.subQuestion?.map((option) => (
                <FormControlLabel
                  key={option?.subQuestionId}
                  value={option?.subQuestion}
                  control={<Radio />}
                  label={option?.subQuestion}
                  sx={{
                    marginY: '4px',
                  }}
                />
              ))}
            </RadioGroup>
          </AccordionDetails>
        </Accordion>)})}

        
      </div>

      <div className="mt-8 flex justify-end">
       { subQuestion &&<Button
          fullWidth
          variant="contained"
          sx={{
            textTransform: 'none',
            backgroundColor: '#000',
            '&:hover': {
              backgroundColor: '#333',
            },
          }}
          onClick={handlenext}
        >
          {expanded === 'product' ? 'Proceed' : 'Next'}
        </Button>}
      </div>
    </div>
  )
}
