import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { ResponsiveContainer, BarChart, Bar, XAxis, YAxis, CartesianGrid, Legend, Tooltip, LabelList, Label } from 'recharts';
import { Box } from '@mui/material';
import { BASE_Url } from '../../URL.js/URL';
import DownloadComponent from '../CSVDownload/DownloadComponent';

function ItemReport({ formDate, toDate }) {
  const [allData, setAllData] = useState([]);
  const {storeId, saasId}= JSON.parse(localStorage.getItem('STORE_DATA'));
  useEffect(() => {
    axios.get(`${BASE_Url}/user-master/item_performance/${saasId}/${storeId}/${formDate}/${toDate}`)
      .then((res) => {
        setAllData(res.data.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, [formDate, toDate]);

  const formattedData = allData.map(item => ({
    name: item.item_name,
    sales: parseFloat(item.sales),
    order_count: parseInt(item.order_count)
  }));

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      const data = payload[0].payload;
      return (
        <div style={{
          background: '#fff',
          border: '1px solid #ccc',
          borderRadius: '10px',
          padding: '10px',
          boxShadow: '0 2px 12px rgba(0, 0, 0, 0.1)',
        }}>
          <p style={{ margin: 0, }}>{` ${label}`}</p>
          <p style={{ margin: 0 }}>{`Order Count: ${data.order_count}`}</p>
          <p style={{ margin: 0, color:"green" }}>{`Sales: ${data.sales}`}</p>
        </div>
      );
    }
    return null;
  };

  const header =[
    { label: 'Item Name', key: 'item_name', width: 500 }, 
    { label: 'Sales', key: 'sales', width: 150 }, 
    { label: 'Order Count', key: 'order_count', width: 150 }, 
  ]

  return (
    <Box className='rounded-lg' p={2}>
      <DownloadComponent headers={header} data={allData} fileName="ItemsReport"/>
      <ResponsiveContainer width="100%" height={300}>
        <BarChart data={formattedData} margin={{ top: 15, right: 30, left: 20, bottom: 5 }}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis className='text-xs' dataKey="name">
            <Label value="Item Name" offset={-5} position="insideBottom" className='font-bold text-xs text-black' />
          </XAxis>
          <YAxis label={{ value: 'Sales', angle: -90, position: 'insideLeft' }} className='font-bold text-xs text-black' />
          <Tooltip content={<CustomTooltip />} />
          <Legend />
          <Bar dataKey="sales" fill="#60a5fa">
            <LabelList dataKey="sales" position="insideTop" className='font-bold text-xs text-black' />
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </Box>
  );
}

export default ItemReport;
