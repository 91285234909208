import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { DeleteAccountForm } from './DeleteAccountForm';
import axios from 'axios';
import { BASE_Url } from '../URL.js/URL';
export function DeleteAccountPage() {
  const { customer_id } = useParams();
  const [customerExists, setCustomerExists] = useState(false);
  const [CustomerData, setCustomerData] = useState()

  useEffect(() => {
    // Call API to check if customer exists
    const checkCustomerExists = async () => {
      try {
        const response = await axios.get(`${BASE_Url}/customer/getById/${customer_id}`);
        if (response.data.status) {
          setCustomerExists(true);
          setCustomerData(response.data.data)
        } else {
          setCustomerExists(false);
        }
      } catch (error) {
        console.error('Error checking customer:', error);
        setCustomerExists(false);
      }
    };

    checkCustomerExists();
  }, [customer_id]);

  return (
    <div className="min-h-screen bg-gray-100 flex flex-col items-center justify-center p-4">
      <div className="max-w-md w-full bg-white rounded-lg shadow-md p-8">
        <h1 className="text-2xl font-bold mb-6 text-center">Delete Your Account</h1>
        <p className="mb-6 text-gray-600 text-center">
          Enter your mobile number to delete your account and all associated data. This action cannot be undone.
        </p>
        {customerExists ? <DeleteAccountForm CustomerData={CustomerData} /> : <p className="text-red-500 text-center">Customer not found.</p>}
      </div>
    </div>
  );
}


