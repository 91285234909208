import React, { useEffect, useState } from 'react';
import { 
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableHead, 
  TableRow, 
  Paper, 
  Typography, 
  Container, 
  Box,
  Button,
  ButtonGroup,
  Chip,
  CircularProgress
} from '@mui/material';
import axios from 'axios';
import { BASE_Url } from '../../URL.js/URL';

export default function CustomerList() {
  const [filter, setFilter] = useState('all');
  const [data, setCustomerData] = useState({ active: [], inActive: [] });
  const [loading, setLoading] = useState(true);
  const jsonStoreString = localStorage.getItem("STORE_DATA");
   const parsedstoreData = JSON.parse(jsonStoreString || '{}'); // Fallback to an empty object if null
   const { saasId,storeId } = parsedstoreData;
  // Get the customer data from the API
  const GetData = async () => {
    try {
      setLoading(true);
      console.log("API Function called");
      const response = await axios.get(`${BASE_Url}/dashboard/get-customer-status/${saasId}/${storeId}`);
      setCustomerData(response.data.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  }

  // Fetch customer data on component mount
  useEffect(() => {
    GetData();
  }, []);

  const renderTable = (customers) => {
    if (customers.length === 0) {
      return (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '268px' }}>
          <Typography variant="h6" color="text.secondary">
            No customers found
          </Typography>
        </Box>
      );
    }

    return (
      <TableContainer style={{height:'calc(100vh - 350px)' , overflowY:"auto"}} component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="customers table">
          <TableHead className="bg-dark-purple">
            <TableRow>
              <TableCell className=" text-white">Customer ID</TableCell>
              <TableCell className=" text-white">Name</TableCell>
              <TableCell className=" text-white">Mobile Number</TableCell>
              <TableCell className=" text-white">Email</TableCell>
              {filter === 'all' && <TableCell className=" text-white">Status</TableCell>}
            </TableRow>
          </TableHead>
          <TableBody>
            {customers.map((customer) => (
              <TableRow key={customer.customer_id}>
                <TableCell>{customer.customer_id}</TableCell>
                <TableCell>{customer.customer_name || 'N/A'}</TableCell>
                <TableCell>{customer.mobile_number}</TableCell>
                <TableCell>{customer.email}</TableCell>
                {filter === 'all' && (
                  <TableCell>
                    <Chip 
                      label={customer.status} 
                      color={customer.status === 'Active' ? 'success' : 'error'}
                      size="small"
                    />
                  </TableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };


  const getFilteredCustomers = () => {
    switch (filter) {
      case 'active':
        return data.active?.map(customer => ({ ...customer, status: 'Active' }));
      case 'inactive':
        return data.inActive?.map(customer => ({ ...customer, status: 'Inactive' }));
      default:
        return [
          ...data.active?.map(customer => ({ ...customer, status: 'Active' })),
          ...data.inActive?.map(customer => ({ ...customer, status: 'Inactive' }))
        ];
    }
  };

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Box sx={{ mb: 4 }}>
        <Typography variant="h4" component="h1" gutterBottom>
          Customer List
        </Typography>
        <ButtonGroup variant="contained" aria-label="filter button group" sx={{ mb: 2 }}>
          <Button 
            onClick={() => setFilter('all')}
            color={filter === 'all' ? 'primary' : 'inherit'}
          >
            All
          </Button>
          <Button 
            onClick={() => setFilter('active')}
            color={filter === 'active' ? 'primary' : 'inherit'}
          >
            Active
          </Button>
          <Button 
            onClick={() => setFilter('inactive')}
            color={filter === 'inactive' ? 'primary' : 'inherit'}
          >
            Inactive
          </Button>
        </ButtonGroup>
        
        <Box sx={{ display: 'flex', justifyContent: 'space-around' }}>
          <Typography variant="h6" color="text.secondary">
            Total Active Customers: {data.active?.length || 0}
          </Typography>
          <Typography variant="h6" color="text.secondary">
            Total Inactive Customers: {data.inActive?.length || 0}
          </Typography>
        </Box>
      

      </Box>
      {loading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <CircularProgress />
        </Box>
      ) : (
        renderTable(getFilteredCustomers())
      )}
    </Container>
  );
}